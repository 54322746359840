import React,{ useState } from 'react'
import './bookEvent.css'
import Avatar from 'react-avatar';


const PointsTable = (props) => {
  const [group, setGroup] = useState("Group A");
    const matchInfo = props?.matchInfo?.metaData;
 //   console.log(matchInfo)
  return (
    <>
    <div style={{display: "flex", flexDirection: "row", justifyContent: "center", gap: "1rem", paddingTop: "3rem", flexWrap: "wrap"}}>
          {
            matchInfo?.pointsTable?.map((item, key) => {
              return(<>
                    <div className='category_chips2' style={{backgroundColor:group === item?.group ? "white": "", border: group === item?.group ? "none": "", color: group === item?.group ? "#141414": "white"}} onClick={() => setGroup(item?.group)}>
                  <p style={{marginTop: '0.8rem', fontFamily: "Poppins-Medium"}}>{item?.group}</p>
                </div>
              </>)
            })
          }
        
    </div>
    {
      matchInfo?.pointsTable.filter((val) => {   
         if (val?.group?.toLowerCase()?.includes(group?.toLowerCase())) {
            return val
        }
    }).map((item, key) => {
      return(<>
          <div style={{padding: "6% 3%"}} className="stats__table">
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", }} className='team__stats_table'>
                <p style={{minWidth: "10%", textAlign: "left"}}>RANK</p>
                <p style={{minWidth: "30%", textAlign: "left"}}>TEAM</p>
                <p style={{minWidth: "10%", textAlign: "center"}}>M</p>
                <p style={{minWidth: "10%", textAlign: "center"}}>W</p>
                <p style={{minWidth: "10%", textAlign: "center"}}>L</p>
                <p style={{minWidth: "10%", textAlign: "center"}}>NRR</p>
                <p style={{minWidth: "10%", textAlign: "center"}}>PTS</p>
                <p style={{minWidth: "10%", textAlign: "center"}}>LAST</p>
            </div>
              <hr className='hr_table'/>
              {
                item?.teams?.map((item, index) => {
                    return(<>
                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", }} className='team__stats_table'>
                            <p style={{minWidth: "10%", textAlign: "left", paddingLeft: "1rem"}}>{item?.teamRank}</p>
                            <p style={{minWidth: "30%", textAlign: "left",display: "flex", borderRadius: "50%", alignItems: "center", paddingLeft: "0rem", paddingLeft: item?.teamLogo === "" ? "0.5rem" : ""}}>{item?.teamLogo === "" ? <><Avatar name={item?.teamName} round={true} size="50" textSizeRatio={0.1}  style={{height:"4rem", width: "4rem", marginRight: "0.6rem"}}/></> : <><img src={item?.teamLogo} style={{height:"auto", width: "4rem", marginRight: "1rem",borderRadius: "50%", objectFit: "fill", aspectRatio: "1"}}/></>}{item?.teamName}</p>
                            <p style={{minWidth: "10%", textAlign: "center"}}>{item?.matchesPlayed}</p>
                            <p style={{minWidth: "10%", textAlign: "center"}}>{item?.matchesWon}</p>
                            <p style={{minWidth: "10%", textAlign: "center"}}>{item?.matchesLoss}</p>
                            <p style={{minWidth: "10%", textAlign: "center"}}>{item?.nrr}</p>
                            <p style={{minWidth: "10%", textAlign: "center"}}>{item?.teamPoints}</p>
                            <p style={{minWidth: "10%", textAlign: "center"}}>{item?.lastMatchStatus === "notPlayed" ? "-": ""}</p>
                        </div>
                    </>)
                })
              }
          </div>  
      </>)
    })
    }
    </>
  )
}

export default PointsTable