import React, {useState , useEffect} from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom'
import {FaSearch} from 'react-icons/fa'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { APIURL } from '../../constants';
import course1 from '../../images/course1.svg'
import course2 from '../../images/course2.svg'
// Default theme
import '@splidejs/react-splide/css';
import './Courses.css'

const Courses = () => {

    const [allCourses, setAllCourses] = useState();

    const getAllCourses = () => {
        axios.get(`${APIURL}/course/getAll`, {
            headers: {
              authtoken: localStorage.getItem('userToken')
            },
            params: {
              status: "active"
            }
          })
          . then(function (response) {
            setAllCourses(response.data.data);
    
            return response.data.data;
      
          })
          .catch(function (error) {
            
          });  
    }


    useEffect(() => {
        getAllCourses()
    }, []);

    console.log(allCourses)
    
  return (
    <div className='app__courses'>
        <div className='course_hero'>
            <div className='course_hero_block1'>
                <div className='course_hero_title'>
                    <h2>Watch.</h2>
                    <h2>Learn.</h2>
                    <h2>Grow.</h2>                
                </div>
                <div className='course_search'>
                    <div>
                        <input placeholder='Search Courses'/>
                    </div>
                    <div>
                        <h3>Search</h3>
                    </div>
                </div>
            </div>

            <div className='course_hero_block2'>
                <div className='Scrollable_View'>
                    {
                        allCourses?.map((item, index) => {
                            return(<>
                                <div className='course_card'>
                                    <div>
                                            <img src={item?.courseThumbnail?.imageUrl}/>
                                    </div>
                                    <div className='course_card_block2'>
                                        <div>
                                            <h2>{item.courseName}</h2>
                                            <h7>{item.certificationProvider}</h7>
                                        </div>
                                        <div className='course_desc'>
                                            <p>{item?.courseDesc}</p>
                                        </div>
                                        <div className='course_apply'>
                                            <h6>Rs-{item?.price} </h6>   
                                            <Link to={`/course/${item?.courseId}/${item?.identifier}`}><button>Details</button></Link>                  
                                        </div>

                                    </div>
                                </div>        
                            </>)
                        })
                    }
                </div>
            </div>
        </div>
            {/* <div style={{marginTop: "-4rem"}}>
                 <h1>Unlimited access to 100+ instuctors</h1>
            </div> */}

        <div className='courses_container'>
            <div className='container_bg'>
                <h2>Coding</h2>
            </div>
            <div className='courses_category'>
                <h2>Coding Courses</h2>

                <Splide aria-label="My Favorite Images"
                    options={ {
                        rewind: true,
                        width : 800,
                        gap   : '3rem',
                        type   : '',
                        perPage: 3,
                        autoplay : true
                    } }
                    style={{maxWidth: "100%"}}
                    
                  >

                    {
                        allCourses?.filter((val) => {
                            if (val.category.toLowerCase().includes("coding")) {
                                console.log(val)
                                return val
                            }
                        })?.map((item, key) => {
                            return(<SplideSlide key={key}>
                                    <Link to={`/course/${item?.courseId}/${item?.identifier}`}>
                                        <div className='course_card2'>
                                                <img src={item?.courseThumbnail?.imageUrl} />
                                                <h2>{item?.courseName}</h2>
                                                <h4>{item?.certificationProvider}</h4>
                                                <h5>Rs- {item?.price}</h5>
                                        </div>
                                    </Link>
                                </SplideSlide>)
                        })
                    }
                </Splide>
                //slider

            </div>
        </div>

        {/* <div className='courses_hero'>
            <div className='hero_block1'>
                <h2>Advance Your Career in a Digitalized World</h2>
                <p>We Provide you with unrestricted access to the gretest courses form the top specialist, allowing you to learn countless fo practical lessons in a range of topics.</p>
                <div className='course_searchBlock'>
                    <input placeholder='Search courses' />
                    <FaSearch style={{color: "black", height:"1.5rem"}}/>
                </div>
            </div>

        </div>
        <div className='course_section1'>
            <div className='section1_block1'>
                <div>
       
                    <h4>Popular Courses</h4>                
                </div>
            </div>
            <div className='section1_block2'>
                <div className='course_card'>
                    <div>
                        
                    </div>
                    <div className='course_card_block2'>
                        <div>
                           <h2>Course Title</h2>
                            <h7>Course Title</h7>
                            <p>This will be the description of the course for more info click on it</p>

                        </div>

                        <div>
                            <h6>Rs-499</h6>   
                            <Link to={"/course/democourseIdetifier"}><button>Details</button></Link>                  
                        </div>

                    </div>
                </div>
                <div className='course_card'>
                    <div>
                        
                    </div>
                    <div className='course_card_block2'>
                        <div>
                           <h2>Course Title</h2>
                            <h7>Course Title</h7>
                            <p>This will be the description of the course for more info click on it</p>

                        </div>
                        <div>
                            <h6>Rs-499</h6>   
                            <Link to={"/course/democourseIdetifier"}><button>Details</button></Link>                   
                        </div>

                    </div>
                </div>
            </div>

        </div> */}
    </div>
  )
}

export default Courses