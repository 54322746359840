import React,{useEffect, useState, useRef} from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Slider from 'infinite-react-carousel';
import {APIURL} from '../../constants/index.js'
import InfiniteScroll from 'react-infinite-scroll-component';
import './bookEvent.css'
import useDocumentTitle from '../useDocumentTitle.js';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

const CategorySelector = () => {
    useDocumentTitle("Explore")
    const [categorySelected, setCategorySelected] = useState(false)
    const [categories, setCategories] = useState([])
    const [background, setBackground] = useState("")
    const [activeCategory, setActiveCategory] = useState("sports");
    const [activeIndex, setActiveIndex] = useState(0)
    let index;
    const handleSlideActive = (slide) => {
     //   console.log(slide)
     //   console.log(`Slide ${slide?.index} is now active`);
        index = slide?.index
     //   console.log(slide.index);
        setActiveIndex(index);
     //   console.log(index)

        inView(slide.index);
      };

    const containerRef = useRef(null);

    const [isMobile, setIsMobile] = useState(false);

 
    //choose the screen size 
    const handleResize = () => {
      if (window.innerWidth < 720) {
          setIsMobile(true)
       //   console.log("less than 720")
       //   console.log(isMobile)
      } else {
          setIsMobile(false)
       //   console.log("more than 720")
       //   console.log(isMobile)
      }
    }
    var splide = new Splide( '.splide' );




    // create an event listener
    useEffect(() => {
      window.addEventListener("resize", handleResize)
      
    })



    var Categories;
    const getCategories = async () => {
          await axios.get(`${APIURL}/category/getAll?status=active`, {
            // params: {
            //   eventId: `${Event._id}`
            // }
          })
          . then(function (response) {
         //   console.log(response);
            Categories = response.data.data;
            setCategories(response.data.data);
         //   console.log(categories);
         //   console.log(Categories);
            setBackground(Categories[0]?.bannerImage?.imageUrl);
          })
          .catch(function (error) {
         
          }); 
    }

    const handleOnCategoryClick = (category) => {
            setActiveCategory(category);
            
    }
    const handleHover = (item) =>{
        setBackground(item?.bannerImage?.imageUrl)
     //   console.log("background updated")
     //   console.log(background)
        setActiveCategory(item.identifier)
     //   console.log("activeCategory")
     //   console.log(activeCategory)
    }

    const inView  = (index) => {
        setBackground(categories[index]?.bannerImage?.imageUrl)
     //   console.log("background updated")
     //   console.log(background)
        setActiveCategory(categories[index]?.identifier)
     //   console.log("activeCategory")
     //   console.log(activeCategory)
    }
    
    function handleOnScroll(event) {
        // const container = containerRef.current;
        // const containerRect = container.getBoundingClientRect();
        // const center = containerRect.top + containerRect.height / 2;
        // const elements = container.querySelectorAll(".list-item");
        // let activeIndex = -1;
        // elements.forEach((element, index) => {
        //   const elementRect = element.getBoundingClientRect();
        //   if (elementRect.top <= center && elementRect.bottom >= center) {
        //     activeIndex = index;
        //   }
        // });
        // setActiveIndex(activeIndex);
        // console.log(activeIndex)
      }
            
    const mediaQueries =  "@media screen and (max-width: 670px";
  
    const styles = {
        bg__image: {
            backgroundImage:  `linear-gradient(to left, transparent, #141414), url(${background})`,  
        },
        bg__image2: {
            backgroundImage:  `linear-gradient(to bottom, transparent, #141414), url(${background})`,
        }
        
    }
    useEffect(() => {
        getCategories();
        setCategories(Categories);
     //   console.log(categories)

        // var splide = new Splide( '.splide' ).mount();

        //     splide.on( 'mounted', function () {
        //     // This won't be executed.
        //  //   console.log("splide")
        //     } );
        
    }, [])

    useEffect( () => {
        setActiveIndex(index);
       
      }, [index] );
   //   console.log("activeIndex");
   //   console.log(activeIndex);
  return (
    <>
    <div className='app__CategorySelector'>   
     <div style={isMobile ? styles.bg__image2 : styles.bg__image} className="category__bg_img"></div>
    
            {/* <InfiniteScroll
                dataLength={categories?.length}
                next={categories}
                inverse={true} 
                hasMore={categories?.length !== 90000}
                
                scrollableTarget="text_scroller"
            >
        </InfiniteScroll> */}
        <div className='Scrollable_View' onScroll={handleOnScroll}>

                {/* <Flicking circular={true}> */}
                {
                    !isMobile ? <>
                        {
                            categories?.map((item, key) => {
                                return(
                                    <Link to= {`/explore/${item.identifier}`} onMouseEnter={() => {handleHover(item)}} >
                                        <div key={key} className="category_item" onClick={() => {handleOnCategoryClick(item.name)}} >
                                            <p className='' style={{color: activeCategory === item.identifier ? "#cfcfcf" : "" }} >{item.name}</p>
                                        </div>
                                    </Link>
                                
                                )
                            })
                        }
                    </> : <>
                    
                    <Splide
                        // onUpdated={inView}
                        onActive={handleSlideActive}
                        options={ {

                            type : 'loop',
                            focus: 'center',
                            perPage: 3,
                            arrows: false,
                            pagination: true,
                            direction: 'ttb',
                            heightRatio: 1,
                            // onActive: {handleSlideActive}
                            
                        } } >
                         
                            {
                            categories?.map((item, key) => {
                                return(
                                    <SplideSlide slideId="idd">
                                        <Link to= {`/explore/${item.identifier}`} onMouseEnter={() => {handleHover(item)}} >
                                            <div key={key} className="category_item" onClick={() => {handleOnCategoryClick(item.name)}} >
                                                <p className='' style={{color: activeIndex === key ? "#cfcfcf" : "" }} >{item.name}</p>
                                            </div>
                                        </Link>
                                    </SplideSlide>
                                )
                            })
                        }
                    </Splide>
                    </>
                }
                    
                    
                  

                {/* </Flicking> */}
                
                                    
        </div>
        <div>
            
        </div>

    </div>
        



    </>
  )
}

export default CategorySelector