import React,{useState, useEffect} from 'react'
import socketIO from 'socket.io-client';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios'
import { APIURL } from '../../constants';
import play from '../../images/play2.png'
import useDocumentTitle from '../useDocumentTitle.js';
import Avatar from 'react-avatar';
import { SOCKET_URL } from '../../constants';


const LiveStream = () => {

    const socket = socketIO.connect(SOCKET_URL) ;
    const [eventData, setEventData] = useState();
    const [liveMatch, setLiveMatch] = useState();
    const [teamStats, setTeamStats] = useState(0);

    const {eventId, eventname, match, group} = useParams();
    console.log(match)
    console.log(group)
    
    useDocumentTitle(`Watch Now ${liveMatch?.title}`)

    const getSocketDetails =  () => {
        socket.on("message", data => {
            console.log(data.data.metaData);
            data.data.metaData.leagueMatches.forEach(item => {
                if(item.groupIdentifier === group){
                    console.log(item)
                    item.matches.forEach(item => {
    
                        if(item.identifier === match){
                            console.log(item)
                            setLiveMatch(item);
                        }
                    })
                }
              });
        });
    }
    useEffect(() => {
        
        getSocketDetails();
        
    }, [socket])
  
    const getEventData = async () =>{
  
        axios.get(`${APIURL}/event/${eventId}/getOne`, {
         // params: {
         //   eventId: `${Event._id}`
         // }
       })
       . then(function (response) {
          setEventData(response.data.data);
         
          response.data.data.eventSchedule.metaData.leagueMatches.forEach(item => {
            if(item.groupIdentifier === group){
                console.log(item)
                item.matches.forEach(item => {

                    if(item.identifier === match){
                        console.log(item)
                        setLiveMatch(item);
                    }
                })
            }
          });

       })
       .catch(function (error) {
      
       });  
 
     }
     useEffect(() => {
        getEventData();
    }, [])

    console.log(liveMatch);
    const matchInfo = eventData?.eventSchedule?.metaData;

     
  return (
    <div className='app__watch'>
        <div  style={{width: "100%", height: "auto"}} >
            {
                liveMatch?.watchUrl ? <>
               <iframe allow="fullscreen" src={liveMatch?.watchUrl} title="" style={{width: "100%", height: "50rem"}} className="live__stream"></iframe>   
                </> : <div className='live__stream'>
                <div className='header_banner'>
                    <img src={liveMatch?.bannerImage}/>
                            <div className='watch__now'>
                                <div style={{display:"flex", gap: "0.8em", alignItems: "center", justifyContent : "center"}}>
                                    <img src={play} className="play__icon"/>
                                    <p style={{marginTop: "14px"}}>Match will start at {liveMatch?.matchDate}, {liveMatch?.matchSlot}</p>
                                </div>
                            </div>
                    </div>
                </div>
            }
          
        </div>
        <div style={{padding : "0 3%"}}>

    
                            <div style={{marginTop: "2rem", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%", color: "white", padding: "0 5%"}}>
                                <div style={{display: "flex", gap: "0.5rem", alignItems: "center" }} className='header__container'>
                                        <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "-50px"}}>
                                            {
                                                liveMatch?.teams?.map((item2, index) => {
                                                    return(<>
                                                        <div style={{display: "flex", flexDirection: "column",width: "4rem", height: "4rem", alignItems: "center", justifyContent: "space-between"}}>
                                                            <div>
                                                                {
                                                                item2.teamLogo === "" ? <><Avatar name={item2?.teamName} round={true} size="50" style={{height:"4rem", width: "3rem"}}/></> : <><img src={item2?.teamLogo} style={{height:"4rem", width: "4rem", borderRadius: "50%"}}/></>
                                                                }                                                                                                                  
                                                            </div>
                                                            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                                                {
                                                                    liveMatch?.matchStats[index]?.oversTaken === "0" ? <>
                                                                        {/* <p style={{fontSize: "0.8rem"}}>TBP</p> */}
                                                                    </> : <>
                                                                        <p style={{fontSize: "0.8rem"}}>{liveMatch?.matchStats[index]?.totalScore}/{liveMatch?.matchStats[index]?.wicketsTaken}</p>
                                                                        <p style={{fontSize: "0.8rem", marginTop: "-1rem"}}>({liveMatch?.matchStats[index]?.oversTaken})</p>
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </>)
                                                })
                                            }
                                        </div>
                                    <div style={{justifyContent: "left", width: "100%", alignItems: "center", paddingLeft: "0.5rem"}}>
                                        <p style={{fontSize: "1.2rem"}}>{liveMatch?.title}</p>
                                        <p style={{color: "#757575", marginTop: "-5px", fontSize: "0.8rem"}}>{liveMatch?.matchDate}, {liveMatch?.matchSlot}</p>
                                        <p style={{color: "#757575", textTransform : "uppercase", marginTop: "-10px", fontSize: "0.8rem"}}>{liveMatch?.eventVenue.name}</p>
                                    </div>
                                </div>
                                <div style={{display: "flex", flexDirection: "column", justifyContent: "right", textAlign:"right"}}>
                                    {liveMatch?.matchResult ? <>
                                        <p style={{color: "#ddab6c", marginTop: "-5px", fontSize: "1.2rem", marginBottom: "-0.6rem", fontWeight: "600", textTransform: "uppercase"}}>{liveMatch.winningCriteria}</p>
                                    </> : <>
                                        <div style={{height:"1.5rem", width: "3rem", backgroundColor: "red", alignItems: "center", textAlign: "center", display: "flex", justifyContent: "center"}}>
                                            <p style={{marginTop: "15px", textTransform: "uppercase", letterSpacing:"1.2px", fontWeight: 600}}>Live</p>
                                        </div>
                                        <p style={{marginTop: "0.5rem", fontSize: "1rem", whiteSpace: "nowrap"}}><span style={{fontWeight: "bold"}}>{liveMatch?.playingTeam} </span> {liveMatch?.totalScore}/{liveMatch?.wicketsTaken}</p>
                                        <p style={{marginTop: "-0.5rem", fontSize: "0.8rem"}}>({liveMatch?.oversTaken})</p>
                                    </>}
                                </div>
                            </div>
                                <div style={{textAlign: "left", color: "#3f3f3f", width: "100%", padding: "5% 5%"}}>
                                    {liveMatch?.description.map((item, index) =>{
                                        return(
                                            <p>{item}</p>
                                        )
                                    })}
                                </div>
                            {
                                liveMatch?.matchResult  && <>
                            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", color: "white"}}>
                                        <div style={{width: "100%", padding: "5%"}}>
                                            <p style={{fontSize: "1.5rem", textAlign: "center"}}>Match <span style={{color: "#ddab6c"}}>Summary</span></p>
                                            <div style={{width: "100%", display:"flex", flexDirection:"row", flexWrap: "wrap", justifyContent: "space-between", alignItems:"center"}}>
                                                <div style={{width:" 40%", textAlign: "left"}}>
                                                    <p style={{fontSize: "1.3rem", textAlign: "center", color:"#ddab6c"}}>{liveMatch?.teams[0]?.teamName}</p>
                                                    <p style={{fontSize: "1rem",textAlign: "center", color: "#ddab6c"}}>{liveMatch?.matchStats[0]?.totalScore}/{liveMatch?.matchStats[0]?.wicketsTaken}</p>
                                                    <p style={{fontSize: "0.8rem",textAlign: "center", marginTop: "-1rem"}}>({liveMatch?.matchStats[0]?.oversTaken})</p>
                                                    <p style={{fontSize:"1.1rem", color:"#4f4f4f"}}>Batsman</p>
                                                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap", fontSize: "0.8rem"}}>
                                                        <p>{liveMatch?.matchStats[0]?.topPerformers[0]?.name}</p>  
                                                        <p>{liveMatch?.matchStats[0]?.topPerformers[0]?.runs}{liveMatch?.matchStats[0]?.topPerformers[0]?.playerStatus === "notOut" ? <sup>*</sup>: ""}({liveMatch?.matchStats[0]?.topPerformers[0]?.bowlsPlayed})</p>
                                                    </div>
                                                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap", fontSize: "0.8rem"}}>
                                                        <p>{liveMatch?.matchStats[0]?.topPerformers[1]?.name}</p>  
                                                        <p>{liveMatch?.matchStats[0]?.topPerformers[1]?.runs}{liveMatch?.matchStats[1]?.topPerformers[1]?.playerStatus === "notOut" ? <sup>*</sup>: ""}({liveMatch?.matchStats[1]?.topPerformers[1]?.bowlsPlayed})</p>
                                                    </div>
                                                    <hr/>
                                                    <p style={{fontSize:"1.1rem", color:"#4f4f4f"}}>Bowlers</p>
                                                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap", fontSize: "0.8rem"}}>
                                                        <p>{liveMatch?.matchStats[0]?.topPerformers[2]?.name}</p>  
                                                        <p>{liveMatch?.matchStats[0]?.topPerformers[2]?.wickets}</p>
                                                    </div>
                                                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap", fontSize: "0.8rem"}}>
                                                        <p>{liveMatch?.matchStats[0]?.topPerformers[3]?.name}</p>
                                                        <p>{liveMatch?.matchStats[0]?.topPerformers[3]?.wickets}</p>  
                                                    </div>
                                                </div>
                                                <div style={{width:" 40%", textAlign: "right"}}>
                                                    <p style={{fontSize: "1.3rem", textAlign: "center", color:"#ddab6c"}}>{liveMatch?.teams[1]?.teamName}</p>
                                                    <p style={{fontSize: "1rem",textAlign: "center", color: "#ddab6c"}}>{liveMatch?.matchStats[1]?.totalScore}/{liveMatch?.matchStats[1]?.wicketsTaken}</p>
                                                    <p style={{fontSize: "0.8rem",textAlign: "center", marginTop: "-1rem"}}>({liveMatch?.matchStats[1]?.oversTaken})</p>
                                                    <p style={{fontSize:"1.1rem", color:"#4f4f4f"}}>Batsman</p>
                                                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap", fontSize: "0.8rem"}}>
                                                        <p>{liveMatch?.matchStats[1]?.topPerformers[0]?.name}</p>  
                                                        <p>{liveMatch?.matchStats[1]?.topPerformers[0]?.runs}{liveMatch?.matchStats[0]?.topPerformers[0]?.playerStatus === "notOut" ? <sup>*</sup>: ""}({liveMatch?.matchStats[0]?.topPerformers[0]?.bowlsPlayed})</p>
                                                    </div>
                                                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap", fontSize: "0.8rem"}}>
                                                        <p>{liveMatch?.matchStats[1]?.topPerformers[1]?.name}</p>
                                                        <p>{liveMatch?.matchStats[1]?.topPerformers[1]?.runs}{liveMatch?.matchStats[1]?.topPerformers[1]?.playerStatus === "notOut" ? <sup>*</sup>: ""}({liveMatch?.matchStats[1]?.topPerformers[1]?.bowlsPlayed})</p>  
                                                    </div>
                                                    <hr/>
                                                    <p style={{fontSize:"1.1rem", color:"#4f4f4f"}}>Bowlers</p>
                                                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap", fontSize: "0.8rem"}}>
                                                        <p>{liveMatch?.matchStats[1]?.topPerformers[2]?.name}</p>  
                                                        <p>{liveMatch?.matchStats[1]?.topPerformers[2]?.wickets}</p>
                                                    </div>
                                                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap", fontSize: "0.8rem"}}>
                                                        <p>{liveMatch?.matchStats[1]?.topPerformers[3]?.name}</p>
                                                        <p>{liveMatch?.matchStats[1]?.topPerformers[3]?.wickets}</p>  
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{display: "flex", flexDirection: "row", gap: "2rem", marginBottom: "1rem", marginTop: "2rem"}}>
                                            <button className={`teamBtn${teamStats === 0 ? "-active" : ""}`} onClick={()=>setTeamStats(0)} >{liveMatch.teams[0].teamName}</button>
                                            <button className={`teamBtn${teamStats === 1 ? "-active" : ""}`} onClick={()=>setTeamStats(1)} >{liveMatch.teams[1].teamName}</button>
                                        </div>
                                        <div style={{width: "100%", padding: "2% 5%"}} className="stats__table">
                                            <div style={{display: "flex",width: "100%", flexDirection: "row", justifyContent: "space-between"}}>
                                                <p style={{minWidth: "44%", textAlign: "left"}}>Player</p>
                                                <div className='team__stats_table'>
                                                    <p style={{minWidth: "8%"}}>SR</p>
                                                    <p style={{minWidth: "8%"}}>AVG</p>
                                                    <p style={{minWidth: "8%"}}>W</p>
                                                    <p style={{minWidth: "8%"}}>R</p>
                                                    <p style={{minWidth: "8%"}}>B</p>
                                                    <p style={{minWidth: "8%"}}>4s</p>
                                                    <p style={{minWidth: "8%"}}>6s</p>
                                                </div>
                                            </div>
                                            <hr className='hr_table'/>
                                            {
                                                liveMatch.matchStats[teamStats]?.stats.map((item, key) => {
                                                    return(<>
                                                        <div  style={{display: "flex",width: "100%", flexDirection: "row", justifyContent: "space-between"}}>
                                                            <div className="team__stats_name" >
                                                                <p style={{minWidth: "22%", textAlign: "left", fontSize: "0.8rem"}}>{item.playerName}</p>
                                                                <p style={{minWidth: "22%", textAlign: "left", fontSize: "0.8rem"}}>{item.outBy}</p>
                                                            </div>
                                                            <div className='team__stats_table'>
                                                                <p style={{minWidth: "8%", fontSize: "0.8rem"}}>{item.strikeRate}</p>
                                                                <p style={{minWidth: "8%", fontSize: "0.8rem"}}>{item.average}</p>
                                                                <p style={{minWidth: "8%", fontSize: "0.8rem"}}>{item.wicketsTaken}</p>
                                                                <p style={{minWidth: "8%", fontSize: "0.8rem"}}>{item.runsScored}</p>
                                                                <p style={{minWidth: "8%", fontSize: "0.8rem"}}>{item.bowlsPlayed}</p>
                                                                <p style={{minWidth: "8%", fontSize: "0.8rem"}}>{item.fours}</p>
                                                                <p style={{minWidth: "8%", fontSize: "0.8rem"}}>{item.sixes}</p>
                                                            </div>
                                                        </div>
                                                    </>)
                                                })
                                            }
                                        </div>
                                    </div>
                                </>

                            }
        </div>
    </div>
  )
}

export default LiveStream