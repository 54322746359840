import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import React, {useState} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import './navbar.css'

import {HiMenuAlt4, HiX} from 'react-icons/hi';
import {motion} from 'framer-motion';
import {CgMenuGridO} from 'react-icons/cg';

function Header(){
  const [isActive, setIsActive] = useState('/');
  const [toggle, setToggle] = useState(false);
  const [down, setDown] = useState('#fffff')

  const location = useLocation();
  var location__obj = location;
  console.log(location__obj)
  console.log(location__obj.pathname.split('/')[1].includes('resource'));
  console.log(location__obj.pathname.split('/'));
  // setIsActive(location.pathname)


  const CheckLocation = () => {
  if (location__obj.pathname === '/') {
    setToggle(false);
    setIsActive('/')
  } else if (location__obj.pathname === '/about') {
    setToggle(false);
    setIsActive('/about')
  } else if (location__obj.pathname === '/our-services'){
    setToggle(false);
    setIsActive('/our-services')
  } else if (location__obj.pathname === '/explore'){
    setToggle(false);
    setIsActive('/explore')
  } else if (location__obj.pathname === '/contact-us'){
    setToggle(false);
    setIsActive('/contact-us')
  } else {
    setToggle(false);
    setIsActive('')
  }
  }

  const ClickOnHome = () => { 
    window.scrollTo(0, 0);
    setToggle(false);
  setIsActive('/');
  };
  const ClickOnAbout = () =>{
    window.scrollTo(0, 0);
    setToggle(false);
    setIsActive('/about')
  }
  const ClickOnServices = () =>{
    window.scrollTo(0, 0);
    setToggle(false);
    setIsActive('/our-services')
  }
  const ClickOnlivevents = () =>{
    window.scrollTo(0, 0);
    setToggle(false);
    setIsActive('/explore')
  }
  const ClickOnContactUs = () =>{
    window.scrollTo(0, 0);
    setToggle(false);
    setIsActive('/contact-us')
  }
  const ClickOnBlogs = () =>{
    window.scrollTo(0, 0);
    setToggle(false);
    setIsActive('/resources')
  }
  const ClickOnCourses = () =>{
    window.scrollTo(0, 0);
    setToggle(false);
    setIsActive('/courses')
  }

  var prevScrollpos = window.pageYOffset;
  window.onscroll = function() {
  var currentScrollPos = window.pageYOffset;
  if (prevScrollpos > currentScrollPos) {
    document.getElementById("app__navbar").style.top = "0";
  } else {
    // document.getElementById("navbar").style.position = "fixed"
    document.getElementById("app__navbar").style.top = "-150px";
  }
  prevScrollpos = currentScrollPos;
}




// window.addEventListener('scroll', function(event) { // To listen for event
//     event.preventDefault();

//     if (window.scrollY <= 150) { // Just an example
//         setDown = '#00000' // or default color
//     } else {
//         setDown = '#fffff'
//     }
// });

const tabsArray = ['privacy-policy', 'terms&conditions', 'cancellation&refund-policy','contact-us', 'resources', "courses", "course"]

 //   console.log(location__obj.pathname.split('/'))

   return (

    <>
    {
      !window.location.pathname.includes("/watch/") && 
  <>
    <nav className='app__navbar' id='app__navbar'>
        {/* <Navbar expand="lg" fixed="top">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                {location.pathname==='/servicepage'?
                <Nav.Link href="" className="homewhite" onClick={()=>{gotoHome()}}><div className="headerline2" ></div>home</Nav.Link>:
                <Nav.Link href="" className="nav-item" onClick={()=>{gotoHome()}}><div className="headerline1" ></div>home</Nav.Link>}
                <Nav.Link href="" className="nav-item gap" onClick={()=>{gotoAbout()}}><div className="headerline1"></div>about</Nav.Link>
                {location.pathname==='/' || location.pathname==='/seminarandconferences'?<Nav.Link href="" className="nav-item gap2" onClick={()=>{gotoService()}}><div className="headerline2"></div>services</Nav.Link>:
                            <Nav.Link href="" className="nav-item gap21" onClick={()=>{gotoService()}}><div className="headerline21"></div>services</Nav.Link>}
              </Nav>
            </Navbar.Collapse>
        </Navbar> */}

      
      <div style={{flex:1, flexDirection: 'row'}}>
      <ul class="app__navbar-links" >
        <li className={`app_navbar-link${`${location__obj.pathname}` === '/' || tabsArray.includes(`${location__obj.pathname}`) ? '_active' : '' }`} >
          {/* <a class="/" href="/">Home</a> */}
         
          <Link className='linksss' to='/' style={{color: `${`${location__obj.pathname}` === '/about' || `${location__obj.pathname}` === '/our-services' || `${location__obj.pathname}` === '/resources'|| tabsArray.includes(`${location__obj.pathname.split('/')[1]}`) ? 'black' : 'white'}`, fontFamily: `${`${location__obj.pathname}` === '/' ? 'Poppins-Medium' : ' '}`}}  onClick={ClickOnHome} >Home</Link>
          {`${location__obj.pathname}` === '/' || tabsArray.includes(`${location__obj.pathname}`) ? <div className="underline_nav flex flex-end col row"></div> : ''}
          
        </li>
        <li  className={`app_navbar-link${`${location__obj.pathname}` === '/explore' ? '_active' : '' }`}>
          {/* <a class="/our-services" style={{color: `${isActive == '/our-services' ? 'black' : ''}`}} href="/our-services">Services</a> */}
          <Link className='linksss' to="/explore" style={{color: `${`${location__obj.pathname}` === '/our-services' || `${location__obj.pathname}` === '/about'|| `${location__obj.pathname}` === '/resources' || tabsArray.includes(`${location__obj.pathname.split('/')[1]}`) ? 'black' : ''}`, fontFamily: `${`${location__obj.pathname}` === '/explore' ? 'Poppins-Medium' : ' '}`}}  onClick={ClickOnlivevents} >Explore</Link>
          {`${location__obj.pathname}` === '/explore' ? <div className="underline_nav flex flex-end col row"></div> : ''}
        </li>
        <li className={`app_navbar-link${`${location__obj.pathname}` === '/about' ? '_active' : '' }`} >
          {/* <a  style={{color: `${isActive == '/about' ? 'black' : ''}`}}  href="/about" >AboutUs</a> */}
          {/* <h3 style={{color: 'white'}}>AboutUs</h3> */}
          <Link className='linksss' to="/about" style={{color: `${`${location__obj.pathname}` === '/about' || `${location__obj.pathname}` === '/our-services' || `${location__obj.pathname}` === '/resources' || tabsArray.includes(`${location__obj.pathname.split('/')[1]}`) ? 'black' : ''}`, fontFamily: `${`${location__obj.pathname}` === '/about' ? 'Poppins-Medium' : ' '}`}}  onClick={ClickOnAbout} >About Us</Link>
          {`${location__obj.pathname}` === '/about' ? <div className="underline_nav flex flex-end col row"></div> : ''}
        </li>
        <li  className={`app_navbar-link${`${location__obj.pathname}` === '/our-services' ? '_active' : '' }`} >
          {/* <a class="/our-services" style={{color: `${isActive == '/our-services' ? 'black' : ''}`}} href="/our-services">Services</a> */}
          <Link className='linksss' to="/our-services" style={{color: `${`${location__obj.pathname}` === '/our-services' || `${location__obj.pathname}` === '/about' || `${location__obj.pathname}` === '/resources' ||  tabsArray.includes(`${location__obj.pathname.split('/')[1]}`) ? 'black' : ''}`, fontFamily: `${`${location__obj.pathname}` === '/our-services' ? 'Poppins-Medium' : ' '}`}}  onClick={ClickOnServices} >Services</Link>
          {`${location__obj.pathname}` === '/our-services' ? <div className="underline_nav flex flex-end col row"></div> : ''}
        </li>
        <li  className={`app_navbar-link${location__obj.pathname.split('/')[1].includes('resource') ? '_active' : '' }`} >
          {/* <a class="/our-services" style={{color: `${isActive == '/our-services' ? 'black' : ''}`}} href="/our-services">Services</a> */}
          <Link className='linksss' to="/resources?category=All" style={{color: `${`${location__obj.pathname}` === '/resources' || `${location__obj.pathname}` === '/about' || `${location__obj.pathname}` === '/our-services'  || tabsArray.includes(`${location__obj.pathname.split('/')[1]}`) ? 'black' : ''}`, fontFamily: `${`${location__obj.pathname}` === '/resources' ? 'Poppins-Medium' : ' '}`}}  onClick={ClickOnBlogs} >Resources</Link>
          {location__obj.pathname.split('/')[1].includes('resource') ? <div className="underline_nav flex flex-end col row"></div> : ''}
        </li>
       

      </ul> 
      </div>

    </nav> 
    <div  className='app__navbar-menu'>
                <CgMenuGridO onClick={() => setToggle(true)}/>

                {
                    toggle && (
                        <motion.div
                            whileInView={{x:[300,0]}}
                            transition={{duration:0.85,ease: 'easeInOut'}}
                        >
                            <HiX onClick={() => setToggle(false)} />
                            <ul className='app__menu_link'>
                            {/* {['/', 'About Us','/our-services'].map((item)=> (
                            <li  key={item}>
                               <a href={``} onClick={() => setToggle(false)}>{item} </a>
                            </li>
                         ))} */}
                              <li><Link className='linksss' to='/' style={{color: `${`${location__obj.pathname}` === '/about' || `${location__obj.pathname}` === '/our-services' || tabsArray.includes(`${location__obj.pathname}`) ? 'black' : 'black'}`, fontFamily: `${`${location__obj.pathname}` === '/' ? 'Poppins-Bold' : 'Poppins-Medium'}`}}   onClick={ClickOnHome}>Home</Link></li>
                              <li> <Link className='linksss' to="/explore" style={{color: `${`${location__obj.pathname}` === '/our-services' || `${location__obj.pathname}` === '/about' || tabsArray.includes(`${location__obj.pathname}`) ? 'black' : 'black'}`, fontFamily: `${`${location__obj.pathname}` === '/explore' ? 'Poppins-Bold' : 'Poppins-Medium'}`}}onClick={ClickOnlivevents}>Explore</Link></li>
                              <li> <Link className='linksss' to="/booking/tool" style={{color: `${`${location__obj.pathname}` === '/our-services' || `${location__obj.pathname}` === '/about' || tabsArray.includes(`${location__obj.pathname}`) ? 'black' : 'black'}`, fontFamily: `${`${location__obj.pathname}` === '/booking/tool' ? 'Poppins-Bold' : 'Poppins-Medium'}`}}onClick={ClickOnlivevents}>Booking Tool</Link></li>
                              <li> <Link className='linksss' to="/resources?category=All" style={{color: `${`${location__obj.pathname}` === '/contact-us' || `${location__obj.pathname}` === '/about' || tabsArray.includes(`${location__obj.pathname}`) ? 'black' : 'black'}`, fontFamily: `${`${location__obj.pathname}` === '/resources' ? 'Poppins-Bold' : 'Poppins-Medium'}`}} onClick={ClickOnContactUs}>Resources</Link></li>
                              <li> <Link className='linksss' to="/about" style={{color: `${`${location__obj.pathname}` === '/about' || `${location__obj.pathname}` === '/our-services' || tabsArray.includes(`${location__obj.pathname}`) ? 'black' : 'black'}`, fontFamily: `${`${location__obj.pathname}` === '/about' ? 'Poppins-Bold' : 'Poppins-Medium'}`}} onClick={ClickOnAbout} >About Us</Link></li>
                              <li> <Link className='linksss' to="/our-services" style={{color: `${`${location__obj.pathname}` === '/our-services' || `${location__obj.pathname}` === '/about' || tabsArray.includes(`${location__obj.pathname}`) ? 'black' : 'black'}`, fontFamily: `${`${location__obj.pathname}` === '/our-services' ? 'Poppins-Bold' : 'Poppins-Medium'}`}} onClick={ClickOnServices}>Services</Link></li>
                              <li> <Link className='linksss' to="/contact-us" style={{color: `${`${location__obj.pathname}` === '/contact-us' || `${location__obj.pathname}` === '/about' || tabsArray.includes(`${location__obj.pathname}`) ? 'black' : 'black'}`, fontFamily: `${`${location__obj.pathname}` === '/contact-us' ? 'Poppins-Bold' : 'Poppins-Medium'}`}} onClick={ClickOnContactUs}>Contact Us</Link></li>

                         </ul>
                        </motion.div>
                    )
                } 
         </div>
         </>
      }
      
    </>
    
   );
}

export default Header;