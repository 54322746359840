import React from 'react'
import { Link } from 'react-router-dom';

import Name from '../images/Name.png'
import Frame101 from '../images/Frame101.svg'
import logo from '../images/logo.svg'
import {AiFillFacebook} from 'react-icons/ai';
import {AiFillInstagram} from 'react-icons/ai';
import {AiFillYoutube} from 'react-icons/ai';
import {AiFillTwitterCircle} from 'react-icons/ai'
import {AiFillLinkedin} from 'react-icons/ai'

function Footer() {
  return (
    <footer>
    <div className="app__footer">
        <div className="footer_block1">
            <img src={logo}/>
            <img src={Name}/>
        </div>
        <div className="footer_block2">
            <h2>Sudarshan Chakra Pratispardha (OPC) Private Limited</h2>
            <p className='footer__policy'><span><Link to='/privacy-policy'>Privacy Policy</Link></span>&nbsp;&nbsp;&nbsp;<span><Link to='/terms&conditions'>Terms & Conditions</Link></span>&nbsp;&nbsp;&nbsp;<span><Link to='/cancellation&refund-policy'>Cancellation & Refund</Link></span>&nbsp;&nbsp;&nbsp;<span><Link to='/contact-us'>Contact Us</Link></span></p>
        </div>
        <div className="footer_block3">
            {/* <div className='footer__policy'>
                <p> |</p>
                <p>   |</p>
                <p>Help & Support</p>
            </div> */}
           <p>customer care <a href='tel:+91 (9818523999)' className='p-text'>&nbsp;+91-9818523999</a></p>
            <div className="app__social">
                <a href="https://www.facebook.com/pratichakrasocial" target="_blank"><AiFillFacebook className="social"/></a>
                <a href="https://www.instagram.com/pratichakra_think_and_do_it/" target="_blank"><AiFillInstagram className="social"/></a>
                <a href="https://www.linkedin.com/in/pratichakra-social-54620b235/" target="_blank"><AiFillLinkedin className="social"/></a>
                <a href="https://twitter.com/pratichakra" target="_blank"><AiFillTwitterCircle className="social"/></a>
                <a href="https://www.youtube.com/channel/UCMveNVFxfNbnBj1awQaVUtg" target="_blank"><AiFillYoutube className="social"/></a>
            </div>
            <h4>All Rights Reserved © 2023</h4>
        </div>
    </div>
</footer>
  )
}

export default Footer
