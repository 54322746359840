import React, {useState, useEffect} from 'react'
import socketIO from 'socket.io-client';
import Accordion from 'react-bootstrap/Accordion';
import axios from 'axios';
import matchPoster from '../../images/matchPoster.jpg';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import TbLivePhoto from 'react-icons/tb'
import live from '../../images/live.png'
import BiPlay from 'react-icons/bi'
import play from '../../images/play2.png';
import Avatar from 'react-avatar';
import {Toaster, Toast, toast} from 'react-hot-toast'
import { SOCKET_URL } from '../../constants';
import { APIURL } from '../../constants';

const Statistics = (props) => {
    
    const socket = socketIO.connect(SOCKET_URL);
    const [matchInfo, setMatchInfo] = useState(props?.matchInfo?.metaData)
    const [groupDetails, setGroupDetails] = useState(props?.matchInfo?.metaData?.pointsTable);
    const [user, setUser] = useState();
    const [group, setGroup] = useState("Group A")
    const [groupIdentifier, setGroupIdentifier] = useState("group-a");
    const [matchDetails, setMatchDetails] = useState();
    const [teamStats, setTeamStats] = useState(0);
    
    const {eventId} = useParams();
    console.log(eventId);
    const getSocketDetails =  () => {
        socket.on("message", data => {
            console.log(data.data.metaData);
            setMatchInfo(data.data.metaData);
            return(data.data.metaData);
        });
    }
    useEffect(() => {
        // setMatchInfo(getSocketDetails());
        getSocketDetails();
        // if(matchInfo === undefined) {
        //     console.log("ifffff")
        //     setMatchInfo(props?.matchInfo?.metaData)
        // }
        changeMatchDetails();
        console.log(matchInfo)
    }, [socket])
    

    function handleOnTabClick(identifier) {
        // const urlParams = new URLSearchParams(window.location.search);
        // urlParams.set("category", category);
        const newUrl = window.location.pathname + `/watch/${identifier}`;
        window.history.pushState({ path: newUrl }, "", newUrl);

        // console.log(newUrl)
      }
    const changeMatchDetails = () => {
        console.log("change match details outer", matchInfo?.leagueMatches)
        matchInfo?.leagueMatches?.filter((val) => {
            console.log("change match details inner", group)
            if(val.group === group){
                setMatchDetails(val);
            }
        })
    }
    const handleGroupChange = (item) => {
        setGroup(item.group);
        setGroupIdentifier(item.groupIdentifier);
    }
    const getUser = async () => {
        await axios.get(`${APIURL}/user/${localStorage.getItem("user_id")}/getOne`,{
            headers: {
                authtoken: localStorage.getItem("userToken")
            }
          })
          .then(function (response) {
            console.log(response);
            setUser(response.data.data)
          })
          .catch(function (error) {
            toast.error("Something went Wrong")
          });
        }
      
        useEffect(() => {
          if(localStorage.getItem("user_id")){
            getUser()
          }
        }, [])



    useEffect(() => {
        changeMatchDetails();
    }, [group])
    console.log(matchInfo);
    
  return (
        <div className='app__stats'>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "center", gap: "1rem", paddingTop: "3rem", flexWrap: "wrap"}}>
            {
                groupDetails?.map((item, key) => {
                return(<>
                    <div className='category_chips2' style={{backgroundColor:group === item?.group ? "white": "", border: group === item?.group ? "none": "", color: group === item?.group ? "#141414": "white"}} onClick={() => handleGroupChange(item)}>
                    <p style={{marginTop: '0.8rem', fontFamily: "Poppins-Medium"}}>{item?.group}</p>
                    </div>
                </>)
                })
            }
            
            </div>
            {
                matchDetails?.matches?.length === 0 ? <>
                     <div style={{display: "flex", flexDirection: "Column", justifyContent: "center", gap: "2rem", paddingTop: "3rem"}}>
                        <p>Fixtures Will be Announce Soon</p>
                     </div>
                </> : <>
                    {/* LIVE MATCHES */}
                    {
                        matchInfo?.liveMatchHappening ? <>
                        <Accordion defaultActiveKey="-1" style={{margin: '0 3%', paddingBottom: '5%'}}>
                            <div style={{display: 'flex', flexDirection: 'row', width: '100%'}} className="ranking__header">
                            <p style={{marginTop: '3rem', fontSize:'1.4rem', textAlign: 'left', justifyContent: 'left', width: '2%', marginLeft: '6%', fontFamily: 'Poppins-SemiBold'}}>Live <span style={{fontFamily:"Poppins-Bold", color: '#ddab6c', fontSize: '2rem', lineHeight: '1px'}}>Match</span></p>
                            </div>
                            {
                                matchDetails?.matches?.filter((val) => {
                                    if (val.liveMatch) {
                                        return val
                                    } 
                                }).map((item, key) => {
                                    return(<>
                                        <Accordion.Item eventKey={key}>
                                            <Accordion.Header>
                                                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "auto"}}>
                                                    {
                                                        item?.bannerImage === "" ? <></> : <>
                                                            <div className='header_banner'>
                                                                <img src={item?.bannerImage}/>
                                                                <Link to={`${window.location.pathname}/watch/${groupIdentifier}/${item.identifier}`} target="_blank" rel='noopener noreferrer'>
                                                                    <div className='watch__now'>
                                                                        <div style={{display:"flex", gap: "0.8em", alignItems: "center", justifyContent : "center"}}>
                                                                            <img src={play} className="play__icon"/>
                                                                            <p style={{marginTop: "14px"}}>WATCH NOW</p>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </>
                                                    }
                                                
                                                {/* <p style={{color: "#ddab6c", fontSize: "1.4rem"}}>{item.title}</p>
                                                    <div style={{display: 'flex', flexDirection: 'row',gap: "1rem", width: '100%', justifyContent:"center", alignItems:"center", height: "4rem"}}>
                                                        <div style={{display: "flex", gap: "1rem", alignItems: "center", justifyContent: "center"}}>
                                                            <div>
                                                            <p>{item.teams[0].teamName}</p>
                                                            <p style={{fontSize: "1.5rem", marginTop: "-13px"}}>196/4</p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <hr/>
                                                        </div>
                                                        <div style={{display: "flex", gap: "1rem", alignItems: "center", justifyContent: "center"}}>
                                                            <div>
                                                            <p>{item.teams[1].teamName}</p>
                                                            <p style={{fontSize: "1.5rem", marginTop: "-13px"}}>186/6</p>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    <div style={{marginTop: "2rem", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%"}} className='liveScore'>
                                                         <div style={{display: "flex", gap: "0.5rem", alignItems: "center" }} className='header__container'>
                                                                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                                                    {
                                                                        item?.teams?.map((item2, index) => {
                                                                            return(<>
                                                                                <div style={{display: "flex", flexDirection: "column",width: "4rem", height: "4rem", alignItems: "center", justifyContent: "space-between"}}>
                                                                                    <div>
                                                                                        {
                                                                                        item2.teamLogo === "" ? <><Avatar name={item2?.teamName} round={true} size="50" style={{height:"4rem", width: "3rem"}}/></> : <><img src={item2?.teamLogo} style={{height:"4rem", width: "4rem", borderRadius: "50%"}}/></>
                                                                                        }                                                                                                                  
                                                                                    </div>
                                                                                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                                                                        {
                                                                                            item?.matchStats[index]?.oversTaken === "0" ? <>
                                                                                                {/* <p style={{fontSize: "0.8rem"}}>TBP</p> */}
                                                                                            </> : <>
                                                                                                <p style={{fontSize: "0.8rem"}}>{item?.matchStats[index]?.totalScore}/{item?.matchStats[index]?.wicketsTaken}</p>
                                                                                                <p style={{fontSize: "0.8rem", marginTop: "-1rem"}}>({item?.matchStats[index]?.oversTaken})</p>
                                                                                            </>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </>)
                                                                        })
                                                                    }
                                                                </div>
                                                            <div style={{justifyContent: "left", width: "100%", alignItems: "center", paddingTop: "1rem"}}>
                                                                <p style={{fontSize: "1.2rem"}}>{item.title}</p>
                                                                <p style={{color: "#757575", marginTop: "-5px", fontSize: "0.8rem"}}>{item.matchDate}, {item.matchSlot}</p>
                                                                <p style={{color: "#757575", textTransform : "uppercase", marginTop: "-10px", fontSize: "0.8rem"}}>{item.eventVenue.name}</p>
                                                            </div>
                                                        </div>
                                                        <div style={{display: "flex", flexDirection: "column", justifyContent: "right", textAlign:"right", alignItems: "flex-end"}} className='liveScore2'>
                                                            <div style={{height:"1.5rem", width: "3rem", backgroundColor: "red", alignItems: "center", textAlign: "center", display: "flex", justifyContent: "center"}}>
                                                                <p style={{marginTop: "15px", textTransform: "uppercase", letterSpacing:"1.2px", fontWeight: 600}}>Live</p>
                                                            </div>
                                                            <div>
                                                                <p style={{marginTop: "0.5rem", fontSize: "1rem", minWidth: "5rem", whiteSpace: "nowrap"}}><span style={{fontWeight: "bold"}}>{item?.playingTeam} </span>  {item?.totalScore}/{item?.wicketsTaken}</p>
                                                                <p style={{marginTop: "-0.5rem", fontSize: "0.8rem"}}>({item?.oversTaken})</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", color: "white"}}>
                                                {
                                                    item?.matchStats[0]?.stats ? <>
                                                    
                                                    <div style={{width: "100%", padding: "5%"}}>
                                                        <div style={{textAlign: "left", color: "#3f3f3f", width: "100%"}}>
                                                            {item.description.map((item, index) =>{
                                                                return(
                                                                    <p>{item}</p>
                                                                )
                                                            })}
                                                        </div>
                                                        <p style={{fontSize: "1.5rem", textAlign: "center"}}>Match <span style={{color: "#ddab6c"}}>Summary</span></p>

                                                        {
                                                            item?.matchStats[0]?.topPerformers ? <>
                                                                <div style={{width: "100%", display:"flex", flexDirection:"row", flexWrap: "wrap", justifyContent: "space-between", alignItems:"center"}}>
                                                                    <div style={{width:" 40%", textAlign: "left"}}>
                                                                        <p style={{fontSize: "1.3rem", textAlign: "center", color:"#ddab6c"}}>{item?.teams[0]?.teamName}</p>
                                                                        <p style={{fontSize: "1rem", textAlign: "center"}}>{item?.matchStats[0]?.totalScore}/{item.matchStats[0]?.wicketsTaken}</p>
                                                                        <p style={{fontSize: "0.8rem", textAlign: "center", marginTop: "-0.7rem"}}>({item?.matchStats[0]?.oversTaken})</p>
                                                                        <p style={{fontSize:"1.1rem", color:"#4f4f4f"}}>Batsman</p>
                                                                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap", fontSize: "0.8rem"}}>
                                                                            <p>{item?.matchStats[0]?.topPerformers[0]?.name}</p>  
                                                                            <p>{item?.matchStats[0]?.topPerformers[0]?.runs}{item?.matchStats[0]?.topPerformers[0]?.playerStatus === "notOut" ? <sup>*</sup>: ""}({item?.matchStats[0]?.topPerformers[0]?.bowlsPlayed})</p>
                                                                        </div>
                                                                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap", fontSize: "0.8rem"}}>
                                                                            <p>{item?.matchStats[0]?.topPerformers[1]?.name}</p>  
                                                                            <p>{item?.matchStats[0]?.topPerformers[1]?.runs}{item?.matchStats[1]?.topPerformers[1]?.playerStatus === "notOut" ? <sup>*</sup>: ""}({item?.matchStats[1]?.topPerformers[1]?.bowlsPlayed})</p>
                                                                        </div>
                                                                        <hr/>
                                                                        <p style={{fontSize:"1.1rem", color:"#4f4f4f"}}>Bowlers</p>
                                                                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap", fontSize: "0.8rem"}}>
                                                                            <p>{item?.matchStats[0]?.topPerformers[2]?.name}</p>  
                                                                            <p>{item?.matchStats[0]?.topPerformers[2]?.wickets}</p>
                                                                        </div>
                                                                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap", fontSize: "0.8rem"}}>
                                                                            <p>{item?.matchStats[0]?.topPerformers[3]?.name}</p>
                                                                            <p>{item?.matchStats[0]?.topPerformers[3]?.wickets}</p>  
                                                                        </div>
                                                                    </div>
                                                                    <div style={{width:" 40%", textAlign: "right"}}>
                                                                        <p style={{fontSize: "1.3rem", textAlign: "center", color:"#ddab6c"}}>{item?.teams[1]?.teamName}</p>
                                                                        <p style={{fontSize: "1rem", textAlign: "center"}}>{item?.matchStats[1]?.totalScore}/{item.matchStats[1]?.wicketsTaken}</p>
                                                                        <p style={{fontSize: "0.8rem", textAlign: "center", marginTop: "-0.7rem"}}>({item?.matchStats[1]?.oversTaken})</p>
                                                                        <p style={{fontSize:"1.1rem", color:"#4f4f4f"}}>Batsman</p>
                                                                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap", fontSize: "0.8rem"}}>
                                                                            <p>{item?.matchStats[1]?.topPerformers[0]?.name}</p>  
                                                                            <p>{item?.matchStats[1]?.topPerformers[0]?.runs}{item?.matchStats[0]?.topPerformers[0]?.playerStatus === "notOut" ? <sup>*</sup>: ""}({item?.matchStats[0]?.topPerformers[0]?.bowlsPlayed})</p>
                                                                        </div>
                                                                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap", fontSize: "0.8rem"}}>
                                                                            <p>{item?.matchStats[1]?.topPerformers[1]?.name}</p>
                                                                            <p>{item?.matchStats[1]?.topPerformers[1]?.runs}{item?.matchStats[1]?.topPerformers[1]?.playerStatus === "notOut" ? <sup>*</sup>: ""}({item?.matchStats[1]?.topPerformers[1]?.bowlsPlayed})</p>  
                                                                        </div>
                                                                        <hr/>
                                                                        <p style={{fontSize:"1.1rem", color:"#4f4f4f"}}>Bowlers</p>
                                                                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap", fontSize: "0.8rem"}}>
                                                                            <p>{item?.matchStats[1]?.topPerformers[2]?.name}</p>  
                                                                            <p>{item?.matchStats[1]?.topPerformers[2]?.wickets}</p>
                                                                        </div>
                                                                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap", fontSize: "0.8rem"}}>
                                                                            <p>{item?.matchStats[1]?.topPerformers[3]?.name}</p>
                                                                            <p>{item?.matchStats[1]?.topPerformers[3]?.wickets}</p>  
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </> : <></>
                                                        }
                                                    </div>
                                                    <div style={{display: "flex", flexDirection: "row", gap: "2rem", marginBottom: "1rem", marginTop: "2rem"}}>
                                                        <button className={`teamBtn${teamStats === 0 ? "-active" : ""}`} onClick={()=>setTeamStats(0)} >{item.teams[0].teamName}</button>
                                                        <button className={`teamBtn${teamStats === 1 ? "-active" : ""}`} onClick={()=>setTeamStats(1)} >{item.teams[1].teamName}</button>
                                                    </div>
                                                    <div style={{width: "100%", padding: "2% 5%"}} className="stats__table">
                                                        <div style={{display: "flex",width: "100%", flexDirection: "row", justifyContent: "space-between"}}>
                                                            <p style={{minWidth: "44%", textAlign: "left"}}>Player</p>
                                                            <div className='team__stats_table'>
                                                                <p style={{minWidth: "8%"}}>SR</p>
                                                                <p style={{minWidth: "8%"}}>AVG</p>
                                                                <p style={{minWidth: "8%"}}>W</p>
                                                                <p style={{minWidth: "8%"}}>R</p>
                                                                <p style={{minWidth: "8%"}}>B</p>
                                                                <p style={{minWidth: "8%"}}>4s</p>
                                                                <p style={{minWidth: "8%"}}>6s</p>
                                                            </div>
                                                        </div>
                                                        <hr className='hr_table'/>
                                                        {
                                                            item.matchStats[teamStats]?.stats.map((item, key) => {
                                                                return(<>
                                                                    <div  style={{display: "flex",width: "100%", flexDirection: "row", justifyContent: "space-between"}}>
                                                                        <div className="team__stats_name" >
                                                                            <p style={{minWidth: "22%", textAlign: "left", fontSize: "0.8rem"}}>{item?.playerName}</p>
                                                                            <p style={{minWidth: "22%", textAlign: "left", fontSize: "0.8rem"}}>{item?.outBy}</p>
                                                                        </div>
                                                                        <div className='team__stats_table'>
                                                                            <p style={{minWidth: "8%", fontSize: "0.8rem"}}>{item?.strikeRate}</p>
                                                                            <p style={{minWidth: "8%", fontSize: "0.8rem"}}>{item?.average}</p>
                                                                            <p style={{minWidth: "8%", fontSize: "0.8rem"}}>{item?.wicketsTaken}</p>
                                                                            <p style={{minWidth: "8%", fontSize: "0.8rem"}}>{item?.runsScored}</p>
                                                                            <p style={{minWidth: "8%", fontSize: "0.8rem"}}>{item?.bowlsPlayed}</p>
                                                                            <p style={{minWidth: "8%", fontSize: "0.8rem"}}>{item?.fours}</p>
                                                                            <p style={{minWidth: "8%", fontSize: "0.8rem"}}>{item?.sixes}</p>
                                                                        </div>
                                                                    </div>
                                                                </>)
                                                            })
                                                        }
                                                    </div>
                                                    </> : <>
                                                        <p>Match Stats Will Be Display Soon</p>
                                                    </>
                                                }
                                                </div>
                                                </Accordion.Body>
                                        </Accordion.Item>
                                    </>)
                                })
                            }

                        </Accordion>
                        </>: <></>
                    }
            
                    {/* UPCOMING MATCHES */}
                    <Accordion defaultActiveKey="-1" style={{margin: '0 3%', paddingBottom: '5%'}}>
                        <div style={{display: 'flex', flexDirection: 'row', width: '100%'}} className="ranking__header">
                        <p style={{marginTop: '3rem', fontSize:'1.4rem', textAlign: 'left', justifyContent: 'left', width: '2%', marginLeft: '6%', fontFamily: 'Poppins-SemiBold'}}>Upcoming <span style={{fontFamily:"Poppins-Bold", color: '#ddab6c', fontSize: '2rem', lineHeight: '1px'}}>Match</span></p>
                        </div>
                        {
                            matchDetails?.matches?.filter((val) => {
                                if (val.upcomingMatch) {
                                    return val
                                } 
                            }).map((item, key) => {
                                return(<>
                                    <Accordion.Item eventKey={key}>
                                        <Accordion.Header>
                                            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "auto"}}>
                                                {
                                                    item.bannerImage === "" ? <></> : <>
                                                        <div className='header_banner'>
                                                            <img src={item.bannerImage}/>
                                                        </div>
                                                    </>
                                                }
                            
                                            {/* <p style={{color: "#ddab6c", fontSize: "1.4rem"}}>{item.title}</p>
                                                <div style={{display: 'flex', flexDirection: 'row',gap: "1rem", width: '100%', justifyContent:"center", alignItems:"center", height: "4rem"}}>
                                                    <div style={{display: "flex", gap: "1rem", alignItems: "center", justifyContent: "center"}}>
                                                        <div>
                                                        <p>{item.teams[0].teamName}</p>
                                                        <p style={{fontSize: "1.5rem", marginTop: "-13px"}}>196/4</p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <hr/>
                                                    </div>
                                                    <div style={{display: "flex", gap: "1rem", alignItems: "center", justifyContent: "center"}}>
                                                        <div>
                                                        <p>{item.teams[1].teamName}</p>
                                                        <p style={{fontSize: "1.5rem", marginTop: "-13px"}}>186/6</p>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div style={{marginTop: "2rem", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%"}} className='liveScore'>
                                                    <div style={{display: "flex", gap: "0.5rem", alignItems: "center" }} className='header__container'>
                                                            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                                                {
                                                                    item?.teams?.map((item2, index) => {
                                                                        return(<>
                                                                            <div style={{display: "flex", flexDirection: "column",width: "4rem", height: "4rem", alignItems: "center", justifyContent: "space-between"}}>
                                                                                <div>
                                                                                    {
                                                                                    item2.teamLogo === "" ? <><Avatar name={item2?.teamName} round={true} size="50" style={{height:"4rem", width: "3rem"}}/></> : <><img src={item2?.teamLogo} style={{height:"4rem", width: "4rem", borderRadius: "50%"}}/></>
                                                                                    }                                                                                                                  
                                                                                </div>
                                                                                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                                                                    {
                                                                                        item?.matchStats[index]?.oversTaken === "0" ? <>
                                                                                            {/* <p style={{fontSize: "0.8rem"}}>TBP</p> */}
                                                                                        </> : <>
                                                                                            <p style={{fontSize: "0.8rem"}}>{item?.matchStats[index]?.totalScore}/{item?.matchStats[index]?.wicketsTaken}</p>
                                                                                            <p style={{fontSize: "0.8rem", marginTop: "-1rem"}}>({item?.matchStats[index]?.oversTaken})</p>
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </>)
                                                                    })
                                                                }
                                                            </div>
                                                        <div style={{justifyContent: "left", width: "100%", alignItems: "center", paddingTop: "1rem"}}>
                                                            <p style={{fontSize: "1.2rem"}}>{item.title}</p>
                                                            <p style={{color: "#757575", marginTop: "-5px", fontSize: "0.8rem"}}>{item.matchDate}, {item.matchSlot}</p>
                                                            <p style={{color: "#757575", textTransform : "uppercase", marginTop: "-10px", fontSize: "0.8rem"}}>{item.eventVenue.name}</p>
                                                            {
                                                            user?.userType === "umpire" ? <>                                                            
                                                                <Link to={`/console/${item.identifier}`} style={{textDecoration: "none"}} className='start_match_btn'>
                                                                    <div style={{height:"1.5rem", width: "max-content", padding: "0 8%", backgroundColor: "white", alignItems: "center", textAlign: "center", display: "flex", justifyContent: "center"}}>
                                                                        <p style={{marginTop: "15px", textTransform: "uppercase", letterSpacing:"1.2px", fontWeight: 600}}>Start Match</p>
                                                                    </div>
                                                                </Link>
                                                            </> : <>
                                                            
                                                            </>
                                                        }
                                                        </div>
                                                    </div>
                                                    <div style={{display: "flex", flexDirection: "column", justifyContent: "right", textAlign:"right", alignItems: "flex-end"}} className='liveScore2'>
                                                        
                                                            <div>
                                                                <p style={{marginTop: "0.5rem", fontSize: "1rem", minWidth: "5rem"}}><span style={{fontWeight: "800"}}>{item?.playingTeam} </span> {item?.totalScore}/{item?.wicketsTaken}</p>
                                                                <p style={{marginTop: "-0.5rem", fontSize: "0.8rem"}}>({item?.oversTaken})</p>
                                                            </div>
                                                        </div>
                                                </div>
                                                
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", color: "white"}}>
                                                <div style={{width: "100%", padding: "5%"}}>
                                                    <div style={{textAlign: "left", color: "#3f3f3f", width: "100%"}}>
                                                        {item.description.map((item, index) =>{
                                                            return(
                                                                <p>{item}</p>
                                                            )
                                                        })}
                                                    </div>
                                                    {
                                                        item.matchResult ? <>
                                                    <p style={{fontSize: "1.5rem", textAlign: "center"}}>Match <span style={{color: "#ddab6c"}}>Summary</span></p>
                                                    <div style={{width: "100%", display:"flex", flexDirection:"row", flexWrap: "wrap", justifyContent: "space-between", alignItems:"center"}}>
                                                        <div style={{width:" 40%", textAlign: "left"}}>
                                                            <p style={{fontSize: "1.3rem", textAlign: "center", color:"#ddab6c"}}>{item?.teams[0]?.teamName}</p>
                                                            <p style={{fontSize: "1rem", textAlign: "center", color:"#ddab6c"}}>{item?.matchStats[0]?.totalScore}/{item.matchStats[0]?.wicketsTaken}</p>
                                                            <p style={{fontSize: "0.8rem", textAlign: "center", color:"white", marginTop: "-1rem"}}>{item?.matchStats[0]?.oversTaken}</p>
                                                            <p style={{fontSize:"1.1rem", color:"#4f4f4f"}}>Batsman</p>
                                                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap"}}>
                                                                <p>{item?.matchStats[0]?.topPerformers[0]?.name}</p>  
                                                                <p>{item?.matchStats[0]?.topPerformers[0]?.runs}{item?.matchStats[0]?.topPerformers[0]?.playerStatus === "notOut" ? <sup>*</sup>: ""}({item?.matchStats[0]?.topPerformers[0]?.bowlsPlayed})</p>
                                                            </div>
                                                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap"}}>
                                                                <p>{item?.matchStats[0]?.topPerformers[1]?.name}</p>  
                                                                <p>{item?.matchStats[0]?.topPerformers[1]?.runs}{item?.matchStats[1]?.topPerformers[1]?.playerStatus === "notOut" ? <sup>*</sup>: ""}({item?.matchStats[1]?.topPerformers[1]?.bowlsPlayed})</p>
                                                            </div>
                                                            <hr/>
                                                            <p style={{fontSize:"1.1rem", color:"#4f4f4f"}}>Bowlers</p>
                                                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap"}}>
                                                                <p>{item?.matchStats[0]?.topPerformers[2]?.name}</p>  
                                                                <p>{item?.matchStats[0]?.topPerformers[2]?.wickets}</p>
                                                            </div>
                                                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap"}}>
                                                                <p>{item?.matchStats[0]?.topPerformers[3]?.name}</p>
                                                                <p>{item?.matchStats[0]?.topPerformers[3]?.wickets}</p>  
                                                            </div>
                                                        </div>
                                                        <div style={{width:" 40%", textAlign: "right"}}>
                                                            <p style={{fontSize: "1.3rem", textAlign: "center", color:"#ddab6c"}}>{item?.teams[1]?.teamName}</p>
                                                            <p style={{fontSize: "1rem", textAlign: "center", color:"#ddab6c"}}>{item?.matchStats[1]?.totalScore}/{item.matchStats[1]?.wicketsTaken}</p>
                                                            <p style={{fontSize: "0.8rem", textAlign: "center", color:"white", marginTop: "-1rem"}}>{item?.matchStats[1]?.oversTaken}</p>
                                                            <p style={{fontSize:"1.1rem", color:"#4f4f4f"}}>Batsman</p>
                                                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap"}}>
                                                                <p>{item?.matchStats[1]?.topPerformers[0]?.name}</p>  
                                                                <p>{item?.matchStats[1]?.topPerformers[0]?.runs}{item?.matchStats[0]?.topPerformers[0]?.playerStatus === "notOut" ? <sup>*</sup>: ""}({item?.matchStats[0]?.topPerformers[0]?.bowlsPlayed})</p>
                                                            </div>
                                                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap"}}>
                                                                <p>{item?.matchStats[1]?.topPerformers[1]?.name}</p>
                                                                <p>{item?.matchStats[1]?.topPerformers[1]?.runs}{item?.matchStats[1]?.topPerformers[1]?.playerStatus === "notOut" ? <sup>*</sup>: ""}({item?.matchStats[1]?.topPerformers[1]?.bowlsPlayed})</p>  
                                                            </div>
                                                            <hr/>
                                                            <p style={{fontSize:"1.1rem", color:"#4f4f4f"}}>Bowlers</p>
                                                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap"}}>
                                                                <p>{item?.matchStats[1]?.topPerformers[2]?.name}</p>  
                                                                <p>{item?.matchStats[1]?.topPerformers[2]?.wickets}</p>
                                                            </div>
                                                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap"}}>
                                                                <p>{item?.matchStats[1]?.topPerformers[3]?.name}</p>
                                                                <p>{item?.matchStats[1]?.topPerformers[3]?.wickets}</p>  
                                                            </div>
                                                        </div>
                                                    </div>   


                                                        </> : <>
                                                        <div style={{width: "100%", padding: "2% 5%"}}>
                                                            <p style={{fontSize: "1.5rem"}}>Match will Start at <span style={{color: "#ddab6c"}}>{item.matchDate}, {item.matchSlot}</span></p>
                                                        </div>
                                                        </>
                                                    } 
                                                    
                                                </div>
                                                {
                                                    
                                                    item.matchResult ? <>
                                                    
                                                    <div style={{display: "flex", flexDirection: "row", gap: "2rem", marginBottom: "1rem", marginTop: "2rem"}}>
                                                    <button className={`teamBtn${teamStats === 0 ? "-active" : ""}`} onClick={()=>setTeamStats(0)} >{item.teams[0].teamName}</button>
                                                    <button className={`teamBtn${teamStats === 1 ? "-active" : ""}`} onClick={()=>setTeamStats(1)} >{item.teams[1].teamName}</button>
                                                </div>
                                                <div style={{width: "100%", padding: "2% 5%"}} className="stats__table">
                                                    <div style={{display: "flex",width: "100%", flexDirection: "row", justifyContent: "space-between"}}>
                                                        <p style={{minWidth: "44%", textAlign: "left"}}>Player</p>
                                                        <div className='team__stats_table'>
                                                            <p style={{minWidth: "8%"}}>SR</p>
                                                            <p style={{minWidth: "8%"}}>AVG</p>
                                                            <p style={{minWidth: "8%"}}>W</p>
                                                            <p style={{minWidth: "8%"}}>R</p>
                                                            <p style={{minWidth: "8%"}}>B</p>
                                                            <p style={{minWidth: "8%"}}>4s</p>
                                                            <p style={{minWidth: "8%"}}>6s</p>
                                                        </div>
                                                    </div>
                                                    <hr className='hr_table'/>
                                                    {
                                                        item.matchStats[teamStats]?.stats.map((item, key) => {
                                                            return(<>
                                                                <div  style={{display: "flex",width: "100%", flexDirection: "row", justifyContent: "space-between"}}>
                                                                    <div className="team__stats_name" >
                                                                        <p style={{minWidth: "22%", textAlign: "left"}}>{item?.playerName}</p>
                                                                        <p style={{minWidth: "22%", textAlign: "left"}}>{item?.outBy}</p>
                                                                    </div>
                                                                    <div className='team__stats_table'>
                                                                        <p style={{minWidth: "8%"}}>{item?.strikeRate}</p>
                                                                        <p style={{minWidth: "8%"}}>{item?.average}</p>
                                                                        <p style={{minWidth: "8%"}}>{item?.wicketsTaken}</p>
                                                                        <p style={{minWidth: "8%"}}>{item?.runsScored}</p>
                                                                        <p style={{minWidth: "8%"}}>{item?.bowlsPlayed}</p>
                                                                        <p style={{minWidth: "8%"}}>{item?.fours}</p>
                                                                        <p style={{minWidth: "8%"}}>{item?.sixes}</p>
                                                                    </div>
                                                                </div>
                                                            </>)
                                                        })
                                                    }
                                                </div>
                                                    </> : <>
                                                    </>
                                                }
                                                
                                            </div>
                                            </Accordion.Body>
                                    </Accordion.Item>
                                </>)
                            })
                        }

                    </Accordion>

                    {/* ALL MATCHES */}
                    <Accordion defaultActiveKey="-1" style={{margin: '0 3%', paddingBottom: '5%'}}>
                        <div style={{display: 'flex', flexDirection: 'row', width: '100%'}} className="ranking__header">
                        <p style={{marginTop: '3rem', fontSize:'1.4rem', textAlign: 'left', justifyContent: 'left', width: '2%', marginLeft: '6%', fontFamily: 'Poppins-SemiBold'}}>All <span style={{fontFamily:"Poppins-Bold", color: '#ddab6c', fontSize: '2rem', lineHeight: '1px'}}>Match</span></p>
                        </div>
                        {
                            matchDetails?.matches?.map((item, key) => {
                                return(<>
                                {
                                    item.matchResult ? <>
                                        <Accordion.Item eventKey={key}>
                                            <Accordion.Header>
                                            <div style={{display: "flex", flexDirection: "row", justifyContent: "left", alignItems: "center", width: "100%", padding: "0 1.5%", flexWrap: "wrap"}}>
                                                    {/* <div style={{textAlign: "center"}}>
                                                        <p>Date</p>
                                                        <p>{item?.matchDate}</p>
                                                    </div>
                                                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "auto"}}>
                                                    
                                                    <p style={{color: "#ddab6c", fontSize: "1.4rem"}}>{item.title}</p>
                                                        <div style={{display: 'flex', flexDirection: 'row',gap: "3rem", width: '100%', justifyContent:"center", alignItems:"center", height: "4rem"}}>
                                                            <div style={{display: "flex", gap: "1rem", alignItems: "center", justifyContent: "center"}}>
                                                                <div>
                                                                <p>{item.teams[0].teamName}</p>
                                                                <p style={{fontSize: "1.5rem", marginTop: "-13px"}}>196/4</p>
                                                                </div>
                                                                <img src='https://pratichakra-resources.s3.ap-south-1.amazonaws.com/users/bookings/IMG_20220529_121242.jpg.image/jpeg' style={{height: "4rem", width: "4rem", borderRadius: "50%", objectFit: "cover"}}/>
                                                            </div>
                        
                                                            <div style={{display: "flex", gap: "1rem", alignItems: "center", justifyContent: "center"}}>
                                                                <img src='https://pratichakra-resources.s3.ap-south-1.amazonaws.com/users/bookings/IMG-20201007-WA0011.jpg.image/jpeg' style={{height: "4rem", width: "4rem", borderRadius: "50%", objectFit: "cover"}}/>
                                                                <div>
                                                                <p>{item.teams[1].teamName}</p>
                                                                <p style={{fontSize: "1.5rem", marginTop: "-13px"}}>{item.teamScore}</p>
                                                                <p style={{fontSize: "1.2rem", marginTop: "-13px"}}>{item.oversTaken}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{marginTop: "1rem"}}>
                                                            <p>{item.matchStatus}</p>
                                                        </div>
                                                    </div>
                                                    <div style={{textAlign: "center"}}>
                                                        <p>Venue</p>
                                                        <p>{item?.eventVenue?.name}</p>
                                                    </div> */}

                                                <div style={{marginTop: "2rem", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%", flexWrap: "wrap"}}>
                                                    <div style={{display: "flex", gap: "0.5rem", alignItems: "center", }} className='header__container'>
                                                            <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "-3%"}}>
                                                                {
                                                                    item?.teams?.map((item2, index) => {
                                                                        return(<>
                                                                            <div style={{display: "flex", flexDirection: "column",width: "4rem", height: "4rem", alignItems: "center", justifyContent: "space-between"}}>
                                                                                <div>
                                                                                    {
                                                                                    item2.teamLogo === "" ? <><Avatar name={item2?.teamName} round={true} size="50" style={{height:"4rem", width: "3rem"}}/></> : <><img src={item2?.teamLogo} style={{height:"4rem", width: "4rem", borderRadius: "50%"}}/></>
                                                                                    }                                                                                                                  
                                                                                </div>
                                                                                <div style={{display: "flex", flexDirection: "column", alignItems: "center", marginTop: "5px"}}>
                                                                                    {
                                                                                        item?.matchStats[index]?.oversTaken === "0" ? <>
                                                                                            {/* <p style={{fontSize: "0.8rem"}}>TBP</p> */}
                                                                                        </> : <>
                                                                                            <p style={{fontSize: "0.8rem"}}>{item?.matchStats[index]?.totalScore}/{item?.matchStats[index]?.wicketsTaken}</p>
                                                                                            <p style={{fontSize: "0.8rem", marginTop: "-1rem"}}>({item?.matchStats[index]?.oversTaken})</p>
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </>)
                                                                    })
                                                                }
                                                            </div>
                                                    <div style={{display :"flex", flexDirection: "column",justifyContent: "left", width: "100%", alignItems: "left", paddingTop: "1rem"}}>
                                                        <p style={{fontSize: "1.2rem"}}>{item.title}</p>
                                                        <p style={{color: "#757575", marginTop: "-5px", fontSize: "0.8rem"}}>{item.matchDate}, {item.matchSlot}</p>
                                                        <p style={{color: "#757575", textTransform : "uppercase", marginTop: "-10px", fontSize: "0.8rem"}}>Venue: {item.eventVenue.name}</p>
                                                        <p style={{color: "#ddab6c", marginTop: "-5px", fontSize: "0.8rem"}}>{item.winningCriteria}</p>
                                                    </div>
                                                    </div>
                                                    <div style={{display: "flex", flexDirection: "column", justifyContent: "right", alignItems:"right"}}>
                                                            {/* <p style={{marginTop: "0em", fontSize: "1rem", textAlign: "center"}}>{item.winnerTeam}</p> */}
                                                            <p style={{marginTop: "0em", fontSize: "1rem", textAlign: "center", width: "100%", whiteSpace: "nowrap", fontWeight: "400"}}>{item.matchStatus}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", color: "white"}}>
                                                {
                                                    item.bannerImage === "" ? <></> : <>
                                                        <div className='header_banner2'>
                                                            <img src={item.bannerImage}/>
                                                            <Link to={`${window.location.pathname}/watch/${groupIdentifier}/${item.identifier}`} target="_blank" rel='noopener noreferrer'>
                                                                <div className='watch__now2'>
                                                                    <div style={{display:"flex", gap: "0.8em", alignItems: "center", justifyContent : "center"}}>
                                                                        <img src={play} className="play__icon"/>
                                                                        <p style={{marginTop: "14px", letterSpacing: "1.2px"}}>WATCH HIGHLIGHTS</p>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                            
                                                        </div>
                                                    </>
                                                }
                                        
                                                <div style={{width: "100%", padding: "5%"}}>
                                                    <div style={{textAlign: "left", color: "#3f3f3f", width: "100%"}}>
                                                        {item.description.map((item, index) =>{
                                                            return(
                                                                <p>{item}</p>
                                                            )
                                                        })}
                                                    </div>
                                                    <p style={{fontSize: "1.5rem", textAlign: "center"}}>Match <span style={{color: "#ddab6c"}}>Summary</span></p>
                                                    <div style={{width: "100%", display:"flex", flexDirection:"row", flexWrap: "wrap", justifyContent: "space-between", alignItems:"center"}}>
                                                        <div style={{width:" 40%", textAlign: "left"}}>
                                                            <p style={{fontSize: "1.3rem", textAlign: "center", color:"#ddab6c"}}>{item?.teams[0]?.teamName}</p>
                                                            <p style={{fontSize: "1rem", textAlign: "center", color:"#ddab6c"}}>{item?.matchStats[0]?.totalScore}/{item.matchStats[0]?.wicketsTaken}</p>
                                                            <p style={{fontSize: "0.8rem", textAlign: "center", color:"white", marginTop: "-1rem"}}>{item?.matchStats[0]?.oversTaken}</p>
                                                            <p style={{fontSize:"1.1rem", color:"#4f4f4f"}}>Batsman</p>
                                                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap"}}>
                                                                <p>{item?.matchStats[0]?.topPerformers[0]?.name}</p>  
                                                                <p>{item?.matchStats[0]?.topPerformers[0]?.runs}{item?.matchStats[0]?.topPerformers[0]?.playerStatus === "notOut" ? <sup>*</sup>: ""}({item?.matchStats[0]?.topPerformers[0]?.bowlsPlayed})</p>
                                                            </div>
                                                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap"}}>
                                                                <p>{item?.matchStats[0]?.topPerformers[1]?.name}</p>  
                                                                <p>{item?.matchStats[0]?.topPerformers[1]?.runs}{item?.matchStats[1]?.topPerformers[1]?.playerStatus === "notOut" ? <sup>*</sup>: ""}({item?.matchStats[1]?.topPerformers[1]?.bowlsPlayed})</p>
                                                            </div>
                                                            <hr/>
                                                            <p style={{fontSize:"1.1rem", color:"#4f4f4f"}}>Bowlers</p>
                                                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap"}}>
                                                                <p>{item?.matchStats[0]?.topPerformers[2]?.name}</p>  
                                                                <p>{item?.matchStats[0]?.topPerformers[2]?.wickets}</p>
                                                            </div>
                                                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap"}}>
                                                                <p>{item?.matchStats[0]?.topPerformers[3]?.name}</p>
                                                                <p>{item?.matchStats[0]?.topPerformers[3]?.wickets}</p>  
                                                            </div>
                                                        </div>
                                                        <div style={{width:" 40%", textAlign: "right"}}>
                                                            <p style={{fontSize: "1.3rem", textAlign: "center", color:"#ddab6c"}}>{item?.teams[1]?.teamName}</p>
                                                            <p style={{fontSize: "1rem", textAlign: "center", color:"#ddab6c"}}>{item?.matchStats[1]?.totalScore}/{item.matchStats[1]?.wicketsTaken}</p>
                                                            <p style={{fontSize: "0.8rem", textAlign: "center", color:"white", marginTop: "-1rem"}}>{item?.matchStats[1]?.oversTaken}</p>
                                                            <p style={{fontSize:"1.1rem", color:"#4f4f4f"}}>Batsman</p>
                                                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap"}}>
                                                                <p>{item?.matchStats[1]?.topPerformers[0]?.name}</p>  
                                                                <p>{item?.matchStats[1]?.topPerformers[0]?.runs}{item?.matchStats[0]?.topPerformers[0]?.playerStatus === "notOut" ? <sup>*</sup>: ""}({item?.matchStats[0]?.topPerformers[0]?.bowlsPlayed})</p>
                                                            </div>
                                                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap"}}>
                                                                <p>{item?.matchStats[1]?.topPerformers[1]?.name}</p>
                                                                <p>{item?.matchStats[1]?.topPerformers[1]?.runs}{item?.matchStats[1]?.topPerformers[1]?.playerStatus === "notOut" ? <sup>*</sup>: ""}({item?.matchStats[1]?.topPerformers[1]?.bowlsPlayed})</p>  
                                                            </div>
                                                            <hr/>
                                                            <p style={{fontSize:"1.1rem", color:"#4f4f4f"}}>Bowlers</p>
                                                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap"}}>
                                                                <p>{item?.matchStats[1]?.topPerformers[2]?.name}</p>  
                                                                <p>{item?.matchStats[1]?.topPerformers[2]?.wickets}</p>
                                                            </div>
                                                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap"}}>
                                                                <p>{item?.matchStats[1]?.topPerformers[3]?.name}</p>
                                                                <p>{item?.matchStats[1]?.topPerformers[3]?.wickets}</p>  
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{display: "flex", flexDirection: "row", gap: "2rem", marginBottom: "1rem", marginTop: "2rem"}}>
                                                    <button className={`teamBtn${teamStats === 0 ? "-active" : ""}`} onClick={()=>setTeamStats(0)} >{item.teams[0].teamName}</button>
                                                    <button className={`teamBtn${teamStats === 1 ? "-active" : ""}`} onClick={()=>setTeamStats(1)} >{item.teams[1].teamName}</button>
                                                </div>
                                                <div style={{width: "100%", padding: "2% 5%"}} className="stats__table">
                                                    <div style={{display: "flex",width: "100%", flexDirection: "row", justifyContent: "space-between"}}>
                                                        <p style={{minWidth: "44%", textAlign: "left"}}>Player</p>
                                                        <div className='team__stats_table'>
                                                            <p style={{minWidth: "8%"}}>SR</p>
                                                            <p style={{minWidth: "8%"}}>AVG</p>
                                                            <p style={{minWidth: "8%"}}>W</p>
                                                            <p style={{minWidth: "8%"}}>R</p>
                                                            <p style={{minWidth: "8%"}}>B</p>
                                                            <p style={{minWidth: "8%"}}>4s</p>
                                                            <p style={{minWidth: "8%"}}>6s</p>
                                                        </div>
                                                    </div>
                                                    <hr className='hr_table'/>
                                                    {
                                                        item.matchStats[teamStats]?.stats.map((item, key) => {
                                                            return(<>
                                                                <div  style={{display: "flex",width: "100%", flexDirection: "row", justifyContent: "space-between"}}>
                                                                    <div className="team__stats_name" >
                                                                        <p style={{minWidth: "22%", textAlign: "left"}}>{item.playerName}</p>
                                                                        <p style={{minWidth: "22%", textAlign: "left"}}>{item.outBy}</p>
                                                                    </div>
                                                                    <div className='team__stats_table'>
                                                                        <p style={{minWidth: "8%"}}>{item?.strikeRate}</p>
                                                                        <p style={{minWidth: "8%"}}>{item?.average}</p>
                                                                        <p style={{minWidth: "8%"}}>{item?.wicketsTaken}</p>
                                                                        <p style={{minWidth: "8%"}}>{item?.runsScored}</p>
                                                                        <p style={{minWidth: "8%"}}>{item?.bowlsPlayed}</p>
                                                                        <p style={{minWidth: "8%"}}>{item?.fours}</p>
                                                                        <p style={{minWidth: "8%"}}>{item?.sixes}</p>
                                                                    </div>
                                                                </div>
                                                            </>)
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </> : <>
                                    
                                        <Accordion.Item eventKey={key}>
                                                <Accordion.Header>
                                                <div style={{display: "flex", flexDirection: "row", justifyContent: "left", alignItems: "center", width: "100%", padding: "0 1.5%"}}>
                                                    {/* <div style={{textAlign: "center"}}>
                                                        <p>Date</p>
                                                        <p>{item?.matchDate}</p>
                                                    </div>
                                                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "auto"}}>
                                                    
                                                    <p style={{color: "#ddab6c", fontSize: "1.4rem"}}>{item.title}</p>
                                                        <div style={{display: 'flex', flexDirection: 'row',gap: "3rem", width: '100%', justifyContent:"center", alignItems:"center", height: "4rem"}}>
                                                            <div style={{display: "flex", gap: "1rem", alignItems: "center", justifyContent: "center"}}>
                                                                <div>
                                                                <p>{item.teams[0].teamName}</p>
                                                                <p style={{fontSize: "1.5rem", marginTop: "-13px"}}>196/4</p>
                                                                </div>
                                                                <img src='https://pratichakra-resources.s3.ap-south-1.amazonaws.com/users/bookings/IMG_20220529_121242.jpg.image/jpeg' style={{height: "4rem", width: "4rem", borderRadius: "50%", objectFit: "cover"}}/>
                                                            </div>
                        
                                                            <div style={{display: "flex", gap: "1rem", alignItems: "center", justifyContent: "center"}}>
                                                                <img src='https://pratichakra-resources.s3.ap-south-1.amazonaws.com/users/bookings/IMG-20201007-WA0011.jpg.image/jpeg' style={{height: "4rem", width: "4rem", borderRadius: "50%", objectFit: "cover"}}/>
                                                                <div>
                                                                <p>{item.teams[1].teamName}</p>
                                                                <p style={{fontSize: "1.5rem", marginTop: "-13px"}}>{item.teamScore}</p>
                                                                <p style={{fontSize: "1.2rem", marginTop: "-13px"}}>{item.oversTaken}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{marginTop: "1rem"}}>
                                                            <p>{item.matchStatus}</p>
                                                        </div>
                                                    </div>
                                                    <div style={{textAlign: "center"}}>
                                                        <p>Venue</p>
                                                        <p>{item?.eventVenue?.name}</p>
                                                    </div> */}

                                                <div style={{marginTop: "2rem", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%"}}>
                                                    <div style={{display: "flex", gap: "0.5rem", alignItems: "center", }} className='header__container'>
                                                            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                                                {
                                                                    item?.teams?.map((item, index) => {
                                                                        return(<>
                                                                            {
                                                                                item.teamLogo === "" ? <><Avatar name={item?.teamName} round={true} size="50" style={{height:"auto", width: "4rem"}}/></> : <><img src={item?.teamLogo} style={{height:"4rem", width: "4rem", borderRadius: "50%"}}/></>
                                                                            }                                                                                                                     
                                                                        </>)
                                                                    })
                                                                }
                                                            </div>
                                                        <div style={{justifyContent: "left", width: "100%", paddingTop: "1rem"}}>
                                                            <p style={{fontSize: "1.2rem"}}>{item?.title}</p>
                                                            <p style={{color: "#757575", marginTop: "-5px", fontSize: "0.8rem"}}>{item?.matchDate}, {item?.matchSlot}</p>
                                                            <p style={{color: "#757575", textTransform : "uppercase", marginTop: "-10px", fontSize: "0.8rem"}}>Venue: {item?.eventVenue?.name}</p>
                                                        </div>
                                                        <div style={{display: "flex", flexDirection: "column", justifyContent: "right", alignItems:"right"}}>
                                                                {/* <p style={{marginTop: "0em", fontSize: "1rem", textAlign: "center"}}>{item.winnerTeam}</p> */}
                                                                {/* <p style={{marginTop: "0em", fontSize: "1rem", textAlign: "center", width: "100%", whiteSpace: "nowrap", fontWeight: "400"}}>{item.matchStatus}</p> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                                                        {
                                                            item?.bannerImage === "" ? <></> : <>
                                                                <div className='header_banner' style={{margin: "1rem 2rem", position: "relative"}}>
                                                                    <img src={item?.bannerImage} style={{width:"87%"}}/>
                                                                </div>
                                                            </>
                                                        }
                                                
                                                <div style={{textAlign: "left", color: "#3f3f3f", width: "100%", padding: "0 5%"}}>
                                                        {item?.description?.map((item, index) =>{
                                                            return(
                                                                <p>{item}</p>
                                                            )
                                                        })}
                                                    </div>
                                                        <div style={{width: "100%", padding: "2% 5%"}}>
                                                            <p style={{fontSize: "1.5rem"}}>Match will Start at <span style={{color: "#ddab6c"}}>{item?.matchDate}, {item?.matchSlot}</span></p>
                                                        </div>
                                                        {/* <div style={{width: "100%", padding: "1% 5%"}}>
                                                            <p style={{fontSize: "1.5rem"}}>Match <span style={{color: "#ddab6c"}}>Summary</span></p>
                                                        </div>
                                                        <div style={{display: "flex", flexDirection: "row", gap: "2rem"}}>
                                                            <button className='teamBtn' onClick={()=>setTeamStats(0)} style={{color: teamStats === 0 ? "#ddab6c" : "#fff"}}>{item.teams[0].teamName}</button>
                                                            <button className='teamBtn' onClick={()=>setTeamStats(1)} style={{color: teamStats === 1 ? "#ddab6c" : "#fff"}}>{item.teams[1].teamName}</button>
                                                        </div>
                                                        <div style={{width: "100%", padding: "2% 5%"}}>
                                                            <div style={{display: "flex",width: "100%", flexDirection: "row", justifyContent: "space-between"}}>
                                                                <p style={{minWidth: "60%", textAlign: "left"}}>Player</p>
                                                                <p style={{minWidth: "10%"}}>R</p>
                                                                <p style={{minWidth: "10%"}}>B</p>
                                                                <p style={{minWidth: "10%"}}>4s</p>
                                                                <p style={{minWidth: "10%"}}>6s</p>
                                                            </div>
                                                            <hr/>
                                                            {
                                                                item.matchStats[teamStats]?.stats.map((item, key) => {
                                                                    return(<>
                                                                        <div style={{display: "flex",width: "100%", flexDirection: "row", justifyContent: "space-between"}}>
                                                                            <p style={{minWidth: "30%", textAlign: "left"}}>{item.playerName}</p>
                                                                            <p style={{minWidth: "30%", textAlign: "left"}}>{item.playerName}</p>
                                                                            <p style={{minWidth: "10%"}}>-</p>
                                                                            <p style={{minWidth: "10%"}}>-</p>
                                                                            <p style={{minWidth: "10%"}}>-</p>
                                                                            <p style={{minWidth: "10%"}}>-</p>
                                                                        </div>
                                                                    </>)
                                                                })
                                                            }
                                                        </div> */}
                                                    </div>
                                                </Accordion.Body>
                                        </Accordion.Item>
                                    </>
                                }
                                </>)
                            })
                        }

                    </Accordion>
                </>
            }

    </div>
  )
}

export default Statistics