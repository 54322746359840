import React, {useState, useEffect} from 'react'
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import socketIO from 'socket.io-client';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Accordion from 'react-bootstrap/Accordion';
import {FiShare2} from 'react-icons/fi'
import {BsBookmark} from 'react-icons/bs'
import {BsFillBookmarkFill} from 'react-icons/bs'
import './bookEvent.css'
import img from '../../images/concert1.png'
import { APIURL } from '../../constants';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import Masonry from 'react-masonry-css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import WinnerTab from './WinnerTab';

import {CopyToClipboard} from 'react-copy-to-clipboard'
import {Toaster, Toast, toast} from 'react-hot-toast'
import useDocumentTitle from '../useDocumentTitle.js';
import PointsTable from './PointsTable';
import Statistics from './Statistics';

  const Overview = ({name, content}) => {
    
    return(
      <div className='app__overview'>
        <p className='overview__title'>{name}</p>
        {content.map((item, index) => {
          return(
            <div key={index}>
              <p>{item}</p>
            </div>
          )
        })}
      </div>
    )
  }
  const Gallery = (photos) => {
    const [modalShow, setModalShow] = React.useState(false);
    const [imageData, setImageData] = useState()
    const breakpointColumnsObj = {
      default: 4,
      1100: 3,
      700: 2,
      500: 1
    };

    function MyVerticallyCenteredModal(props) {
      return (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <img src={imageData?.imageUrl} style={{width: "80%", height: "70vh", objectFit: "contain"}}/>
          </Modal.Body>
        </Modal>
      );
    }

    const handleImageClick = (item) => {
      setModalShow(true)
      setImageData(item)
    }

    console.log(photos)
    return(
      <div className='app__newsandupdates'>
        {/* <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {
          (photos) ? <>
             {
                photos?.photos?.map((item, index) => {
                  return(
                  <div>
                    <img src={item.imageUrl} style={{width: "full", maxHeight: "15rem", height: "full"}}/>
                  </div>)
                })
             }
          </> :<>
          
            <p>Currently not Available</p>
          </>
        }
        </Masonry> */}
        <div style={{display: "flex", gap: "2rem", flexWrap :"wrap", justifyContent: "center", alignItems: "center"}}>
        {
          !(photos.photos.length === 0) ? <>
             {
                photos?.photos?.map((item, index) => {
                  return(
                  <div onClick={() => handleImageClick(item)}>
                    <img src={item.imageUrl} style={{width: "full", maxHeight: "15rem", maxWidth: "100%", height: "full"}}/>
                  </div>)
                })
             }
          </> :<>
          
            <p>Currently not Available</p>
          </>
        }
        </div>

        <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        />
      </div>
    )
  }
  const NewsandUpdates = (updates) => {
    console.log(updates)
    return(
      <div className='app__newsandupdates'>
        {/* <p style={{color: '#ddab6c', fontFamily: 'Poppins-Medium'}}>TODAY</p> */}
        {
           !(updates.updates.length === 0) ? <>
            {updates?.updates?.map((item, index) => {
              return(
                <div className='news__view'>
                  <div className='news__block1'>
                    <img className='news__img' src={item?.eventUpdateImage}/>
                  </div>
                  <div className='news__block2'>
                    <p style={{color: '#9b9b9b', fontSize: '1rem', fontFamily:'Poppins-Medium', marginTop: '1%'}}>{item?.title}</p>
                    <p style={{marginTop: '-2%', fontSize: '0.9rem'}}>{item?.description}</p>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignSelf: 'flex-end', marginTop: '4%'}}>
                        <p>{moment(item?.updatedAt).startOf('hour').fromNow()}</p>
                        <p style={{fontFamily:'Poppins-Medium',color: '#9b9b9b', }}>{item.author}</p>
                    </div>
                  </div>
                </div>
              )
            })}
          </> :<>
          
            <p>Currently not Available</p>
          </>
        }
      </div>
    )
  }
  const Winner = ({List}) => {


    const handleUpvote = (item) =>{
      item.metaData.upvoteCount = (item.metaData.upvoteCount) ? item.metaData.upvoteCount + 1 : 1;
      axios.patch(`${APIURL}/booking/${item._id}/update`, {
                    metaData: item.metaData,
                    
              },{
                headers: {
                    authtoken: localStorage.getItem('userToken')
                }
              })
              .then(function (response) {
               
                localStorage.setItem(`${item._id}_Voting_Status`, true)
               
                
              })
              .catch(function (error) {
              
              });
    }



    return(
      <div className='app__winner'>
          <Accordion defaultActiveKey="-1">


          {/* {obj.sub_categories.map((item,index)=>{
                                  i=!i;
                                  return(<div className={i?"col-lg-6 subcatdesc":"col-lg-6 subcatdesc2"}>
                                              <p className='serviceswe' onClick={()=>{gotosubservice(item.slug,index)}}>{item.subservice_name}<br /><br /><span className={i?'view':'know'}>View now ⟶</span></p>
                                          </div>);
          })} */}

          {List?.map((item, key) =>{
            return(
              <div>
                <Accordion.Item eventKey={key}>
                  <Accordion.Header>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                      <div>
                        <img style={{height: '3rem', width: '3rem'}} src={""}/>
                      </div>
                      <div style={{display: 'flex', flexDirection: 'column', marginLeft: '1rem'}}>
                        <div className='winner__name'>
                          <p>{item.metaData.contestentName}</p>
                        </div>
                        <div className='winner__id'>
                        <p>{item.bookingId}</p>
                        </div>
                      </div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className='winner__details'>
                        <p style={{fontSize: '1.5rem'}}>Vote For  <span style={{color:'#ddab6c'}}> {item.metaData.contestentName}</span></p>

                        {!localStorage.getItem(`${item._id}_Voting_Status`)? <button className='btn__upvote' onClick={() => {handleUpvote(item)}}>UpVote</button> : <p style={{fontSize: '1.4rem'}}>Thank You for Voting</p>}
                        
                        <p style={{marginTop: '1rem', color: '#ddab6c'}}>Contestent Bio</p>
                        <p style={{marginTop: '0.5rem'}}>{item.metaData.bio}</p>
                        <div>
                          {item?.metaData?.images?.map((item,key) =>{
                            return(
                              <>
                            
                                <img key={key} style={{height: '10rem', width: '10rem', margin: '0 1.2rem'}} src={item.imageUrl} />
                            
                              </>
                            )
                          })}
                        </div>
                    </div>
                
                  </Accordion.Body>
                </Accordion.Item>
              </div>
            )
          })}
          </Accordion>
      </div>
    )
  }
let Event;
let Contestant_List;
let TotalUpVotes = 1;
let UserBooking;
function EventDetails() {
  window.scrollTo(0, 0);
  

  const [eventData, setEventData] = useState() 
  const [similarEvents, setSimilarEvents] = useState([])
  const [totalUpvotes, setTotalUpvotes] = useState(1)
  const [isLoaded, setisLoaded] = useState(false)
  const [searchTab, setSearchTab] = useState()
  const [eventSchedule, setEventSchedule] = useState();


  const [activeTab, setActiveTab] = useState("overview");


    const {eventId} = useParams();
    // console.log(eventId);
    // socket.on("message", data => {
    //     console.log(data.data);
    //     setEventSchedule(data.data);
    // });
 

    const queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);


 

    function useQuery(){
      return new URLSearchParams(useLocation().search)
    }

    // const handleOnTabClick = (tab) =>{
    //   // location.search = tab;
    //     urlParams.set("tab", tab)
    // }
    function handleOnTabClick(tab) {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set("tab", tab);
      const newUrl = window.location.pathname + "?" + urlParams.toString();
      window.history.pushState({ path: newUrl }, "", newUrl);
      // console.log(newUrl)
    }



    
    useEffect(() => {
    // setEventData(Event)
    const getSimilarEvents = async () => {
          await axios.get(`${APIURL}/event/getAll?category=${Event.category._id}`, {
          })
          . then(function (response) {
            response.data.data = response.data.data.filter(item => item._id !== Event._id)
            setSimilarEvents(response.data.data);
           
          })
          .catch(function (error) {
          
          });  
        }
    const getContestants = async () => {
      axios.get(`${APIURL}/booking/getAll?eventId=${Event?._id}&sortBy=metaData.upvoteCount&sortOrder=desc`, {
      })
      . then(function (response) {
    
       
        let rank = 1 ,upvoteCount = 0, total= 0;
        
        response.data.data.forEach((element, index) => {
          if(element.metaData.upvoteCount > upvoteCount || element.metaData.upvoteCount === upvoteCount){
            response.data.data[index].rank= rank;
            
          } else {
            rank++;
            response.data.data[index].rank= rank;
          }
          if(typeof element.metaData.upvoteCount == 'number'){
            upvoteCount = element.metaData.upvoteCount;
            
            total = total + upvoteCount;
            
          }

          if(element?.user?._id == localStorage.getItem("user_id")){
              UserBooking = element;
            
          }
        });
        TotalUpVotes = total;

        Contestant_List = response.data.data

     
        setisLoaded(true)
      })
      .catch(function (error) {
       
      });
    }
    const getEventData = async () =>{
  
       axios.get(`${APIURL}/event/${eventId}/getOne`, {
        // params: {
        //   eventId: `${Event._id}`
        // }
      })
      . then(function (response) {
        
        setEventData(response.data.data);
        Event = response.data.data;
        setEventSchedule(response.data.data.eventSchedule);
        // console.log(eventData)
        getSimilarEvents();
        getContestants();
  
      })
      .catch(function (error) {
     
      });  

    }
    const getSearchTerms = async () =>{

      const tab = urlParams.get("tab");
      const bookingId = urlParams.get("bookingId");
      const type = urlParams.get("type");

   

      setSearchTab(tab)

   }


    getSearchTerms();
    getEventData();


    
    }, []);

    const handleShareBtn =() =>{
      toast.success('Link is Copied to the Clipboard')
    }



    function calculateDifference(date) {
      const givenDate = new Date(date);
      const currentDate = new Date();
      const difference = givenDate - currentDate;
      const differenceInDays = Math.floor(difference / (1000 * 60 * 60 * 24));
    
      return `${differenceInDays}`;
    }
  

    useDocumentTitle(`${Event?.title}`)

  return (
    <div  className='app__liveevent'>

      <div className='background__event' style={{backgroundImage: `linear-gradient(to bottom, transparent, #141414), url(${Event?.bannerImage?.imageUrl})`}}></div>
      <div className='app__bookevent'>
        <div className='event__hero' style={{zIndex: '7'}}>
              <p className='liveEvent__tag'>{Event?.eventStatus?.status}</p>
              <p className='liveEvent__title'>{Event?.title}</p>
              <p className='liveEvent__category'>{Event?.category?.name}</p>
              <div className='liveEvent__details'>
                <p>{Event?.eventDate?.slice(0,10)}</p>
                <p style={{marginLeft: '2%'}}>{ Event?.city}</p>
              </div>
              <div style={{display: 'flex'}}>
                <p className='liveEvents__subtitle'>{Event?.description}</p>
                
                  
               
              </div>
              <div className='book__buttons'>

                      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', }}>
                                  <span style={{display: 'flex', flexDirection:'row', justifyContent: 'center', alignItems: 'center', textAlign: 'center', fontSize: '2rem', color: '#ddab6c'}}><p style={{marginTop: '1rem'}}>{Event?.ticketPrice ?  `₹${Event.ticketPrice}` : 'Free'}</p></span>
                            {
                              calculateDifference(Event?.eventDate) < 15? <>
                                    <Link to={{pathname: `/explore/${Event?.eventId}/${Event?.identifier}/user/booking`}}><button className='booknow_btn' style={{marginLeft: '1.5rem'}}>Book Now</button></Link>                 
                              </> : 
                              <>
                              <button className='booknow_btn2' style={{marginLeft: '1.5rem'}}>Coming Soon</button>       
                              </>
                            }
                              
                              <CopyToClipboard text={window.location.href} ><button className='btnnnn' onClick={handleShareBtn}><FiShare2 style={{marginRight: '0.5rem', scale: '1.4'}}/>Share</button></CopyToClipboard>
                              {/* <button className='btnnnn'><BsBookmark  style={{marginRight: '0.5rem', scale: '1.4'}}/>Save</button>
                              */}
                      </div>
           

                      {
                           Event?.sponsorLogo?.imageUrl ? <>
                            <div className='sponsers__new__class sponsers__responsive'>
                                  <div >
                                              <div style={{display:'flex', flexDirection: 'row'}}>
                                                  <img style={{height: '5rem',width:'10rem', objectFit: 'contain'}} src={Event?.sponsorLogo?.imageUrl}/>
                                              </div>
                                                  <p style={{fontSize: '1rem', textAlign: 'center', marginTop: '-0.5rem'}}>Sponsored By</p>
                                  </div>
                            </div>
                           </> : <>

                           </>
                      }
                      
              </div>
              
        </div>
        <div style={{right: '9%', fontFamily: "Poppins-Regular", textAlign: 'center', top: '27.8%', position: 'absolute'}} className="event__matrix">
                    
                    <div style={{marginBottom: '2rem'}}>
                      <h2 style={{fontFamily: "Poppins-SemiBold", color: 'white'}}>10</h2>
                      <p style={{marginTop: '-0.5rem'}}>Cities</p>
                    </div>
                    <div style={{marginBottom: '2rem'}}>
                      <h2 style={{fontFamily: "Poppins-SemiBold", color: 'white'}}>{Event?.bookingCount || "-"}</h2>
                      <p style={{marginTop: '-0.5rem'}}>{Event?.eventType?.name === "social"? "Participants" : "Contestants"}</p>
                    </div>
                    {
                      Event?.category?.name === "Sports" ? <></> : <>
                        <div style={{marginBottom: '8rem'}}>
                          <h2 style={{fontFamily: "Poppins-SemiBold", color: 'white'}}>{TotalUpVotes}</h2>
                          <p style={{marginTop: '-0.5rem'}}>Upvoting</p>
                        </div>
                      </>
                    }
                    <div className='' style={{marginTop: '15%'}}>
                          {
                            Event?.sponsorLogo?.imageUrl ? <>
                            <div >
                                        <div style={{display:'flex', flexDirection: 'row'}}>
                                            <img style={{height: '5rem',width:'10rem', objectFit: 'contain'}} src={Event?.sponsorLogo?.imageUrl}/>
                                        </div>
                                            <p style={{fontSize: '1rem', textAlign: 'center', marginTop: '-0.5rem'}}>Sponsored By</p>

                            </div>
                            </> : <>
                                
                            </>
                          }
                      </div>
      </div>
      <div className='Sponsers__app'>
        </div>




      </div>
      <div className='app__bookevent' style={{marginTop:'-18%', zIndex: '1'}}>
      <div className='tabs__section'>
            <Tabs
              defaultActiveKey={urlParams.get("tab") !== null ? urlParams.get("tab") : "overview" }
              id="fill-tab-example"
              className="event__tabs"
              fill
              onSelect={handleOnTabClick}
            >
              <Tab eventKey="overview" title="Overview" className='event__tab'>
                  <Overview name={Event !== undefined? Event.title: ''} content={Event !== undefined? Event.summary: []}/>
              </Tab>
              <Tab eventKey="gallery" title="Gallery" className='event__tab'>
                  <Gallery photos={Event !== undefined? Event.eventImages: []} />
              </Tab>
              <Tab eventKey="news-updates" title="News & Updates" className='event__tab' >
                <NewsandUpdates updates={Event !== undefined? Event?.eventUpdates: [] }/>
              </Tab>
              {
                Event?.metaData?.winnerTab === true ?
                  <Tab eventKey="winner" title="Winner" className='event__tab'>
                    {/* <Winner List= {Contestant_List}/> */}
                    {isLoaded && 
                      <WinnerTab List= {Contestant_List} UserBooking={UserBooking} eventId={Event._id} EventData={Event} />
                    }
                  </Tab>
                 : <>
                    
                </>
              }
              {
                Event?.metaData?.pointsTable === true ? <Tab eventKey="pointsTable" title="Points Table" className='event__tab'>
                    {isLoaded && 
                      <PointsTable matchInfo={Event?.eventSchedule}/>
                    }
                </Tab> : <></>
              }
              {
                Event?.metaData?.statsTab === true ? <Tab eventKey="matchFixtures" title="Match Fixtures" className='event__tab'>
                    {isLoaded && 
                      <Statistics matchInfo={eventSchedule}/>
                    }
                </Tab> : <></>
              }
            
            </Tabs>
        </div>

        {
          similarEvents.length > 0  ? <>
            <div className='event__Cards'>
              <p>You might like this also</p>
              <div className='event__card__image'>
                  {similarEvents.map((item, key)=> {
                    return(
                      <>
                        <div className='event_card' key={key}>
                          <Link exact to={'/event-details/' + item.eventId +  '/' + item.identifier + '?overview'}target="_blank" rel='noopener noreferrer' ><img className='events__img' src={item?.thumbnailImage?.imageUrl} /></Link>
                          <p>{item.title}</p>
                        </div>
                      </>
                    );
                  })}
              </div>
          </div>
          </> : <>
          
          </>
        }

      </div>
      <Toaster       
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          success: {
            iconTheme: {
              primary: '#141414',
              secondary: 'white',
            },
          },
        }}
        />
    </div>
  )
}

export default EventDetails
