import React,{useState, useEffect} from 'react'
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { motion } from "framer-motion"
import {FiShare2} from 'react-icons/fi'
import {BsBookmark} from 'react-icons/bs'
import {BsFillBookmarkFill} from 'react-icons/bs'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {Toaster, Toast, toast} from 'react-hot-toast'
import './bookEvent.css'
import concert1 from '../../images/concert1.png'
import axios from 'axios';
import { APIURL } from '../../constants';
import useDocumentTitle from '../useDocumentTitle.js';


function LiveEvents() {
 
  const [events, setEvents] = useState([])
  const [liveEvents, setLiveEvents] = useState([]) 

  const [pastEvents, setPastEvents] = useState([])
  const [upcomingEvents, setUpcomingEvents] = useState([])
  const [ongoingEvents, setOngoingEvents] = useState([])

  const [bannerIndex, setBannerIndex] = useState(0)

  // const queryString = window.location.search;
  // const urlParams = new URLSearchParams(queryString);
  // const keywords = urlParams.get("keywords");
  // console.log(keywords)

  const {eventCategory} = useParams();
  console.log(eventCategory)
  useDocumentTitle(`${liveEvents[bannerIndex]?.category?.name} Events`)


  
  const getOngoingEvents = async () =>{
    
    await axios.get(`${APIURL}/event/getAll?status=ongoing`, {
      headers: {
        authtoken: localStorage.getItem('userToken')
      },
      params: {
        keywords:eventCategory
      }
    })
    . then(function (response) {
      
      setLiveEvents(response.data.data);
      setOngoingEvents(response.data.data);

  
      getUpcomingEvents();

    })
    .catch(function (error) {
      
    });  
  }
  const getPastEvents = async () =>{
    
    await axios.get(`${APIURL}/event/getAll?status=past`, {
      headers: {
        authtoken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzYzkyMzgzMzc2YWQzOWFjZjFlOTcwOCIsImlhdCI6MTY3NDEzMzk1NH0.ZUR5fwVkuuV4hw9It08j_8dviYX4UMQ92K_klqe9938'
      },
      params: {
        keywords:eventCategory
      }
    })
    . then(function (response) {
    
      setPastEvents(response.data.data);

    

    })
    .catch(function (error) {
     
    });  
  }
  const getUpcomingEvents = async () =>{
    
    await axios.get(`${APIURL}/event/getAll?status=upcoming`, {
      headers: {
        authtoken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzYzkyMzgzMzc2YWQzOWFjZjFlOTcwOCIsImlhdCI6MTY3NDEzMzk1NH0.ZUR5fwVkuuV4hw9It08j_8dviYX4UMQ92K_klqe9938'
      },
      params: {
        keywords:eventCategory
      }
    })
    . then(function (response) {
    
      setUpcomingEvents(response.data.data);

      if (liveEvents.length < 1) {
        setLiveEvents(response.data.data);
      }


    })
    .catch(function (error) {
    
    });  
  }

  const handleShareBtn =() =>{
    toast.success('Link is Copied to the Clipboard')
  }

  useEffect(() => {
    getOngoingEvents();
    // CreateEvent();
   
    getPastEvents();

    setInterval(() => {
      
      if (bannerIndex === liveEvents.length-1 && liveEvents.length > 1) {
        setBannerIndex(0)
        
      }else if(liveEvents.length > 1){
        setBannerIndex(bannerIndex+1)
      }
    }, 5000, setBannerIndex);
  
  }, []);
  // useEffect(() => {
  //   getOngoingEvents();
  //   // CreateEvent();
  //   getUpcomingEvents();
  //   getPastEvents();



    
  // }, []);


  // console.log(liveEvents[0].title)

  function calculateDifference(date) {
    const givenDate = new Date(date);
    const currentDate = new Date();
    const difference = givenDate - currentDate;
    const differenceInDays = Math.floor(difference / (1000 * 60 * 60 * 24));
  
    return `${differenceInDays}`;
  }




  return (

    <>
    
    <div className='app__liveevent'>
      <div className='background__event' style={{backgroundImage: `linear-gradient(to bottom, transparent, #141414), url(${liveEvents[bannerIndex] !== undefined? liveEvents[bannerIndex].bannerImage.imageUrl: ''})`}}></div>
      <div className='app__bookevent'>

        <div className='event__hero'>
              <p className='liveEvent__tag'>{liveEvents[bannerIndex]?.eventStatus?.status}</p>
              <p className='liveEvent__title'>{liveEvents[bannerIndex]?.title}</p>
              <p className='liveEvent__category'>{liveEvents[bannerIndex]?.category?.name}</p>
              <div className='liveEvent__details'>
                <p>{liveEvents[bannerIndex]?.eventDate?.slice(0,10)}</p>
                <p style={{marginLeft: '2%'}}>{liveEvents[bannerIndex]?.city}</p>
              </div>
              <p className='liveEvents__subtitle'>{liveEvents[bannerIndex]?.description}</p>
              {/* <p style={{marginLeft: '0%', color: '#ddab6c',fontSize:'2rem'}}>Rs-{liveEvents[0] !== undefined?liveEvents[0].ticketPrice: ''}</p> */}
              <div className='book__buttons'>

                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', }}>
                      <span style={{display: 'flex', flexDirection:'row', justifyContent
                      : 'center', alignItems: 'center', textAlign: 'center', fontSize: '2rem', color: '#ddab6c'}}><p style={{marginTop: '1rem'}}>{liveEvents[bannerIndex]?.ticketPrice ?  `₹${liveEvents[bannerIndex].ticketPrice}` : 'Free'}</p></span>
                      {/* <Link to={{pathname:'/live-events'}}><button className='booknow_btn'>Coming Soon</button></Link> */}
                      {
                      calculateDifference(liveEvents[bannerIndex]?.eventDate) < 15? <>
                            <Link to={{pathname: `/explore/${liveEvents[bannerIndex]?.eventId}/${liveEvents[bannerIndex]?.identifier}/user/booking`}}><button className='booknow_btn' style={{marginLeft: '1.5rem'}}>Book Now</button></Link>                 
                      </> : 
                      <>
                      <button className='booknow_btn2' style={{marginLeft: '1.5rem'}}>Coming Soon</button>       
                      </>
                    }
                      <CopyToClipboard text={window.location.href}><button className='btnnnn' onClick={handleShareBtn}><FiShare2 style={{marginRight: '0.5rem', scale: '1.4'}}/>Share</button></CopyToClipboard>
                      {/* <button className='btnnnn' onClick={handleSaveClick}>  <BsBookmark  style={{marginRight: '0.5rem', scale: '1.4'}}/>  Save</button> */}
                </div>

                    <div className='sponsers__new__class'>
                          {
                            liveEvents[bannerIndex]?.sponsorLogo?.imageUrl ? <>
                            <div >
                                        <div style={{display:'flex', flexDirection: 'row'}}>
                                            <img style={{height: '5rem',width:'10rem', objectFit: 'contain'}} src={liveEvents[bannerIndex]?.sponsorLogo?.imageUrl}/>
                                        </div>
                                            <p style={{fontSize: '1rem', textAlign: 'center', marginTop: '0.5rem'}}>Sponsored By</p>

                            </div>
                            </> : <>
                                
                            </>
                          }
                    </div>
              </div>

              
        </div>

        <div className='event__Cards event__Cards2'>
          {
            ongoingEvents[0]? <>
              <p>Ongoing Events</p>
              <div className='event__card__image'>
                  {ongoingEvents.map((item)=> {
                    return(
                      <>
                        <div className='event_card'>
                          <Link to={{pathname: '/event-details/' + item.eventId +  '/' + item.identifier}} state={item}><img className='events__img' src={item?.thumbnailImage?.imageUrl} /></Link>
                          <p>{item.title}</p>
                        </div>
                      </>
                    );
                  })}
              </div>
            </> : 
            <>
            
            </>
          }

        </div>
        <div className='event__Cards'>
          {
            upcomingEvents[0]? <>
              <p>Upcoming Events</p>
              <div className='event__card__image'>
                  {upcomingEvents.map((item)=> {
                    return(
                      <>
                        <div className='event_card'>
                          <Link to={{pathname: '/event-details/' + item.eventId +  '/' + item.identifier}} state={item}><img className='events__img' src={item?.thumbnailImage?.imageUrl} /></Link>
                          <p>{item.title}</p>
                        </div>
                      </>
                    );
                  })}
              </div>
            </> : 
            <>
            
            </>
          }

        </div>
        <div className='event__Cards'>
          {
            pastEvents[0]? <>
              <p>Past Events</p>
              <div className='event__card__image'>
                  {pastEvents.map((item)=> {
                    return(
                      <>
                        <div className='event_card'>
                          <Link to={{pathname: '/event-details/' + item.eventId +  '/' + item.identifier}} state={item}><img className='events__img' src={item?.thumbnailImage?.imageUrl} /></Link>
                          <p>{item.title}</p>
                        </div>
                      </>
                    );
                  })}
              </div>
            </> : 
            <>

            </>
          }

        </div>
        {/* <div className='event__Cards'>
            <p>Upcoming Events <span>SEE ALL</span></p>
            <div className='event__card__image'>
                {upcomingEvents.map((item)=> {
                  return(
                    <>
                      <div className='event_card'>
                        <Link to={{pathname: '/event-details/'+ item.title + '/' + item._id}} state={item}><img className='events__img' src={item.img} /></Link>
                      </div>
                    </>                  
                    );
                })}
            </div>
        </div>
        <div className='event__Cards'>

                  {pastEvents[0]? <>
                    <div>
                      <p>Past Events</p>
                      <div className='event__card__image'>
                          {pastEvents?.map((item)=> {
                            return(
                              <>
                                <div className='event_card'>
                                <Link to={{pathname: '/event-details/' + item.eventId +  '/' + item.identifier}} state={item}><img className='events__img' src={item.thumbnailImage.imageUrl} /></Link>
                                </div>
                              </> 
                            );
                          })}
                      </div>
                     </div>
                  </> : <>
                  
                  </> } 


        </div> */}
      </div>
      <Toaster       
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          success: {
            iconTheme: {
              primary: '#141414',
              secondary: 'white',
            },
          },
        }}
        />
    </div>

    </>

  )
}

export default LiveEvents
