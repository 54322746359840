import React,{ useState, useEffect} from 'react'
import { Link, matchPath, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios'
import './blog.css'
import { APIURL } from '../../constants'
import useDocumentTitle from '../useDocumentTitle.js';
import { GrResources } from 'react-icons/gr';
const queryString = window.location.search;


const Articles = [
  {
    title: "Card1 title",
    date: '13 Feb',
    data: "Card1 Data",
    desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do e",
    tumbnailImg: "https://pratichakra-resources.s3.ap-south-1.amazonaws.com/events/images/banners/Thumbnail%20for%20cutest%20baby%20competition%20for%20kids.jpeg"

  }
]


const Blog = () => {
  // window.scrollTo(0, 0);
  const [active, setActive] = useState(0)
  const [articles, setArticles] = useState([])
  const [topArticles, setTopArticles] = useState([])
  const [categories, setCategories] = useState([])
  const [activeCategory, setActiveCategory] = useState("All")
  let Articles__all;

  useDocumentTitle("Resources");


  const handleClickPrev = () => {
    if (active !== 0) {
      setActive(active-1)
    }
    
  }
  const handleClickNext = () => {
    if (active !== topArticles.length-1) {
      setActive(active+1)
    }
  }
  const handleCategoryClick = (categoryName) => {
      setActiveCategory(categoryName);
      handleOnTabClick(categoryName);
  }
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const getSearchTerms = async () =>{

    const category = urlParams.get("category");
    
    setActiveCategory(category);

 }
 function handleOnTabClick(category) {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.set("category", category);
  const newUrl = window.location.pathname + "?" + urlParams.toString();
  window.history.pushState({ path: newUrl }, "", newUrl);
  // console.log(newUrl)
}
    

  useEffect(() => {

    const getAllArticles =  () => {
       axios.get(`${APIURL}/article/getAll`, {
        headers: {
          authtoken: localStorage.getItem('userToken')
        },
        params: {
          status: "active"
        }
      })
      . then(function (response) {
        setArticles(response.data.data);
        Articles__all = response.data.data;
        setTopArticles(response.data.data.slice(0,3));
       
        return response.data.data;
  
      })
      .catch(function (error) {
        
      });  

      getSearchTerms();
    }

    const getAllCategories = () => {
      axios.get(`${APIURL}/category/getAll?status=active`, {
        headers: {
          authtoken: localStorage.getItem('userToken')
        },
        params: {
          status: "active"
        }
      })
      . then(function (response) {
        setCategories(response.data.data);

        return response.data.data;
  
      })
      .catch(function (error) {
        
      });  
    }
    setArticles(getAllArticles());
 //   console.log("art")
 //   console.log(articles)
    setCategories(getAllCategories());
 //   console.log(getAllArticles())
    
   
  }, [])
  console.log(articles)
  console.log(categories)
  console.log("topArticles");
  console.log(topArticles);
  
  return (

    <>
    
    <div className='app__blogpage'>
        <p className='blog__bg_text'>ARTICLES</p>
        <div className="blog__cards_container">
          {/* {Articles.map((item, key) =>{
            return(
              <div key={key} className={`blog__card`}>
                <div className='blog__card__img' style={{backgroundImage: `linear-gradient(to bottom, transparent, #141414), url(${item.tumbnailImg})`}}>
                  <div style={{color: 'white'}}>
                  {item.title}
                  </div>
                </div>
                {item.data}
              </div>
            )
          })} */}
          {topArticles?.map((item, key) =>{
            return(
      
                // <article class="card" style={{backgroundImage: `linear-gradient(to bottom, transparent, #141414), url(${item.tumbnailImg})`}}>
                //   <header class="card-header">
                //     <p>Dec 11th 2022</p>
                //     <h2>{item.title}</h2>
                //   </header>

                //   <div class="card-author">

                //     <div class="author-name">
                //       <div class="author-name-prefix">Author</div>
                //         Author Name
                //     </div>
                //   </div>
                // </article>
                <>

                      <div className='article__card__container' onMouseEnter={()=> {setActive(key)}} key={key}>
                        <div className='article__card' style={{backgroundImage: `linear-gradient(to bottom, transparent, #141414), url(${item?.bannerImage?.imageUrl})`, width: active === key ? '30rem': ''}}>
                          <div style={{color: 'white'}}>
                        
                            {active === key ? <>
                              <Link to={`/resources/article/${item?.articleId}/${item?.identifier}`} key={key} className="article__title">
                              <div className="event__matrix2">
                                    <div style={{marginBottom: '1rem'}}>
                                      <h4 style={{fontFamily: "Poppins-SemiBold", color: 'white'}}>{item?.shares ? item?.shares : "0"}</h4>
                                      <p style={{marginTop: '-0.5rem'}}>Shares</p>
                                    </div>
                                    <div style={{marginBottom: '1rem'}}>
                                      <h4 style={{fontFamily: "Poppins-SemiBold", color: 'white'}}>{item?.likes ? item?.likes : "0"}</h4>
                                      <p style={{marginTop: '-0.5rem'}}>Likes</p>
                                    </div>
                                    <div style={{marginBottom: '2rem'}}>
                                      <h4 style={{fontFamily: "Poppins-SemiBold", color: 'white'}}>{item?.comments?.length ? item?.comments?.length : "0"}</h4>
                                      <p style={{marginTop: '-0.5rem'}}>Comments</p>
                                    </div>
                                </div>
                                
                                  <div className='article__title'>
                                    {item.title}
                                  </div>
                                </Link>
                          
                          </> : <>
                           
                          </>}
                          <div className='card__no'>
                            {
                              active !== key ?   key+1 : ''
                            }
                              
                          </div>
                        </div>
                      </div>
                        
                    </div>
       
                </>
                            
        
            )
          })}

        </div>

        {articles !== undefined ? <>
          <div className='blog__details'>
            <div style={{ display: 'inline-flex', textAlign: 'left', alignItems: 'center'}}>
              <p className='details__no' >{active+1}</p>
              <p style={{marginLeft: '1rem' , fontSize: '2rem', fontFamily: 'Poppins-Bold', textAlign:'left', width: '75%'}}>{articles[active]?.title}</p>
            </div>
              <div className='blog__desc'>
                <p style={{marginLeft: '0rem'}}>{articles[active]?.description}</p>
              </div>

            {
              articles.length === 1 ? <>
              </> : <div className='article__nav__btn'>
                <div style={{display: 'flex', padding: '2rem 2rem'}}>
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',  height:'3rem', width: '10rem', border: `2px solid ${active !== 0? "" : "#c1c1c1"}`, color: active !== 0 ? '' : "#b1b1b1", fontFamily: "Poppins-SemiBold", cursor: "pointer", marginLeft: "2rem"}} onClick={handleClickPrev}>
                    PREV
                  </div>
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height:'3rem', width: '10rem', border: `2px solid ${active !== topArticles.length-1? "" : "#c1c1c1"}`, color: active !== topArticles.length-1 ? '' : "#b1b1b1", fontFamily: "Poppins-SemiBold", cursor: "pointer", marginLeft: "1rem"}} onClick={handleClickNext}>
                    NEXT
                  </div>
              </div>
              </div>
            }
  
           </div>
        </> : <>
        
        </>}

    </div>

    <div className='articles__category'> 
      <p className='articles__header' style={{fontSize: '2.5rem',marginTop: "2", textAlign: "center"}}>Explore</p>
      <div className='categories_article'>
                  <div className='category_chips' style={{backgroundColor:activeCategory === "All" ? "#141414": "", border: activeCategory === "All" ? "2px solid #141414": "", color: activeCategory === "All" ? "white": ""}} onClick={() => {handleCategoryClick("All")}}>
                    <p style={{marginTop: '0.8rem', fontFamily: "Poppins-Medium"}}>All</p>
                  </div>
              {categories?.map((item, key) => {
                return(
                  <>
                  <div className='category_chips' style={{backgroundColor:activeCategory === item.identifier ? "#141414": "", border: activeCategory === item.identifier ? "2px solid #141414": "", color: activeCategory === item.identifier ? "white": ""}} onClick={() => {handleCategoryClick(item.identifier)}}>
                    <p style={{marginTop: '0.8rem', fontFamily: "Poppins-Medium"}}>{item.name}</p>
                  </div>
                  </>
                )
              })}
      </div>

      <div>
      <div className='event__card__image'>
                      {articles?.filter((val) => {
                
                if(activeCategory == "All"){
                    return val;
                } else if (val.category.identifier.toLowerCase().includes(activeCategory?.toLowerCase())) {
                    return val
                }
            }).map((item, key)=> {
                        return(
                            <>
                            <div className='event_card' key={key}>
                                <Link exact to={'/resources/article/' + item.articleId +  '/' + item.identifier }target="_blank" rel='noopener noreferrer' ><img className='events__img' src={item?.bannerImage?.imageUrl} /></Link>
                                <p style={{marginTop: "1rem"}}>{item.title}</p>
                            </div>
                            </>
                        );
                        })}
                    </div>
      </div>
    </div>

    </>
  )
}

export default Blog