import React,{useState} from 'react'
import {HiLocationMarker} from 'react-icons/hi'
import {IoMdCall} from 'react-icons/io'
import {MdEmail} from 'react-icons/md'
import {BsFillPersonFill} from 'react-icons/bs'
import GoogleMapReact from 'google-map-react';
import { Form } from 'react-router-dom'
import light from './MapStyles'
import axios from 'axios'
import useDocumentTitle from './useDocumentTitle'



function ContactUs() {
   window.scrollTo(0, 0);
   useDocumentTitle("Contact Us")
    const [name, setName] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [email_newsletter, setEmail_newsletter] = useState("")
    const [message, setMessage] = useState("")

    const [deliverd, setDeliverd] = useState(false)
    const [deliverd_newsletter, setDeliverd_newsletter] = useState(false)

    const [name__empty, setName__empty] = useState(false)
    const [firstName__empty, setFirstName__empty] = useState(false)
    const [lastName__empty, setLastName__empty] = useState(false)
    const [phone__empty, setPhone__empty] = useState(false)
    const [email__empty, setEmail__empty] = useState(false)
    const [email__newsLetter__empty, setEmail__newsLetter__empty] = useState(false)
    const [message__empty, setMessage__empty] = useState(false)


   

    const handleContactUs_newsletter = (event) => {
      event.preventDefault();
      axios.post('https://www.pratichakra.com/api/auth/user/create', {
        firstName: name,
        email: email_newsletter,
        userType: "user",
        status: "active",
        interests: ["user Registered for News Letter"]
      },{
        headers: {
            authtoken: localStorage.getItem('userToken')
        }
      })
      .then(function (response) {
        
        setDeliverd_newsletter(true)
      })
      .catch(function (error) {
 
        setDeliverd_newsletter(true)
      });


    }



    const handleContactUs = (event) => {
      event.preventDefault();
      axios.post('https://www.pratichakra.com/api/auth/user/create', {
        firstName: firstName,
        lastName: lastName,
        email: email,
        mobileNumber: phone,
        userType: "user",
        status: "active",
        interests: [message]
      },{
        headers: {
            authtoken: localStorage.getItem('userToken')
        }
      })
      .then(function (response) {
      
        setDeliverd(true)
      })
      .catch(function (error) {

        setDeliverd(true)
      });

   
    }

    const CheckValidation = (event) => {
      event.preventDefault();
      
      if (firstName === "") {
        setFirstName__empty(true)
        
      } else if (lastName === "") {
        setLastName__empty(true)
       
      } else if (phone === "") {
        setPhone__empty(true)
        
      } else if (email === "") {
        setEmail__empty(true)
       
      } else if (message === "") {
        setMessage__empty(true)
        
      } else {
        setFirstName__empty(false)
        setLastName__empty(false)
        setPhone__empty(false)
        setEmail__empty(false)
        setMessage__empty(false)
        handleContactUs()
      }
    }

    const AnyReactComponent = ({ text }) => <div><HiLocationMarker style={{height: '2rem', width: '2rem'}}/>{text}</div>;
    const defaultProps = {
        center: {
          lat: 28.637490,
          lng: 77.434640
        },
        zoom: 14
      };
  return (
    <div className='app__contactus'>
      <div className='contactus__1'>
        <div className='app__map'>
            <div className='map__layout'>
          <GoogleMapReact
            bootstrapURLKeys={{ key: "AIzaSyCMANwkNsfj8j63O73NupJkQdWOhgVseQE" }}
            options={{styles: [
              {
                elementType: "geometry",
                stylers: [{ color: "#f5f5f5" }],
              },
              {
                elementType: "labels.icon",
                stylers: [{ visibility: "off" }],
              },
              {
                elementType: "labels.text.fill",
                stylers: [{ color: "#616161" }],
              },
              {
                elementType: "labels.text.stroke",
                stylers: [{ color: "#f5f5f5" }],
              },
              {
                featureType: "administrative.land_parcel",
                elementType: "labels.text.fill",
                stylers: [{ color: "#bdbdbd" }],
              },
              {
                featureType: "poi",
                elementType: "geometry",
                stylers: [{ color: "#eeeeee" }],
              },
              {
                featureType: "poi",
                elementType: "labels.text.fill",
                stylers: [{ color: "#757575" }],
              },
              {
                featureType: "poi.park",
                elementType: "geometry",
                stylers: [{ color: "#e5e5e5" }],
              },
              {
                featureType: "poi.park",
                elementType: "labels.text.fill",
                stylers: [{ color: "#9e9e9e" }],
              },
              {
                featureType: "road",
                elementType: "geometry",
                stylers: [{ color: "#ffffff" }],
              },
              {
                featureType: "road.arterial",
                elementType: "labels.text.fill",
                stylers: [{ color: "#757575" }],
              },
              {
                featureType: "road.highway",
                elementType: "geometry",
                stylers: [{ color: "#dadada" }],
              },
              {
                featureType: "road.highway",
                elementType: "labels.text.fill",
                stylers: [{ color: "#616161" }],
              },
              {
                featureType: "road.local",
                elementType: "labels.text.fill",
                stylers: [{ color: "#9e9e9e" }],
              },
              {
                featureType: "transit.line",
                elementType: "geometry",
                stylers: [{ color: "#e5e5e5" }],
              },
              {
                featureType: "transit.station",
                elementType: "geometry",
                stylers: [{ color: "#eeeeee" }],
              },
              {
                featureType: "water",
                elementType: "geometry",
                stylers: [{ color: "#c9c9c9" }],
              },
              {
                featureType: "water",
                elementType: "labels.text.fill",
                stylers: [{ color: "#9e9e9e" }],
              },
            ]}}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
          >
            <AnyReactComponent
              lat={28.637490}
              lng={77.434640}
              text="Pratichakra"
            />
          </GoogleMapReact>
          </div>
        </div>
        <div className='app__message'>
            <p className='contact__header'>Contact.</p>
            <p className='contact__header' style={{marginTop: '-4%'}}>Get In Touch,</p>
            <p className='contact__header' style={{marginTop: '-3%', fontSize: '1rem', textTransform: 'uppercase'}}>leave us a message</p>
            {
              !deliverd? <>
                  <form onSubmit={handleContactUs}>
             
                    <div style={{marginTop: '2rem'}} className="form__block">
                        <div className='contact__item'>            
                            <p>First Name</p>
                            <div className='item__input'>
                                {/* <BsFillPersonFill style={{height: '2rem', width: '2rem', color: '#717171', marginTop: '-0.8rem', marginRight: '0.6rem'}}/> */}
                                <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
                            </div>
                        </div>
                        <div className='contact__item margin__left_3'>            
                            <p>Last Name</p>
                            <div className='item__input'>
                                {/* <MdEmail style={{height: '2rem', width: '2rem', color: '#717171', marginTop: '-0.8rem', marginRight: '0.6rem'}}/> */}
                              <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)}/>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: '2rem'}} className="form__block">
                        <div className='contact__item'>            
                            <p>Phone</p>
                            <div className='item__input'>
                                {/* <BsFillPersonFill style={{height: '2rem', width: '2rem', color: '#717171', marginTop: '-0.8rem', marginRight: '0.6rem'}}/> */}
                                <input type="tel" value={phone} onChange={(e) => setPhone(e.target.value)}/>
                            </div>
                        </div>
                        <div className='contact__item margin__left_3'>            
                            <p>Email</p>
                            <div className='item__input'>
                                {/* <MdEmail style={{height: '2rem', width: '2rem', color: '#717171', marginTop: '-0.8rem', marginRight: '0.6rem'}}/> */}
                              <input type="text" value={email} onChange={(e) => setEmail(e.target.value)}/>
                            </div>
                        </div>
                    </div>
                        <div className='contact__item' style={{marginTop: '2rem'}}>
                            <p>Message</p>
                            <textarea type="text" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                        </div>
                        <div className='submit__btn margin__left__5_btn'>
                            <input type="submit" value='Proceed'/>
                        </div>
                  </form>
              </> 
              :
              <>
              <div style={{textAlign: 'center', marginTop: '9rem'}}>
                 <p style={{fontSize: '2rem', color: '#7D7D7D',fontFamily: 'Poppins-Medium'}}> We succesfully received</p>
                 <p style={{fontSize: '2rem', color: '#7D7D7D',fontFamily: 'Poppins-Medium'}}> your Message</p>
              </div>
                 
              </>
            }
            

        </div>
      </div>

      <div className='contactus__2'>
        <div style={{margin: '0% 15%', padding: '5% 0'}}>
            <p className='contact__header'>Quick.</p>
            <p className='contact__header'  style={{marginTop: '-4%'}}>Support</p>
            <p className='contact__header' style={{marginTop: '-2%', fontSize: '1rem', textTransform: 'uppercase'}}>You can get all the contact information</p>
        </div>
        <div className='app__info'>
          <div style={{textAlign: 'center'}}>
            <HiLocationMarker style={{height: '2rem', width: '2rem'}}/>
            <p style={{fontFamily: 'Poppins-Medium', marginTop: '1rem'}}>Visit Us</p>
            <p style={{fontFamily: 'Poppins-Regular', color:'#717171', width: '250px'}}>303, Balaji Enclave, Sector-04, Greater Noida (West), Gautam Buddha Nagar, Uttar Pradesh, India - 201009</p>
          </div>
          <div style={{textAlign: 'center'}}>
            <IoMdCall  style={{height: '2rem', width: '2rem'}}/>
            <p style={{fontFamily: 'Poppins-Medium', marginTop: '1rem'}}>Call Us</p>
            <p style={{fontFamily: 'Poppins-Regular', color:'#717171', width: '250px'}}> <p><a href='tel:+91 (9818523999)' className='p-text'>&nbsp;+91-9818523999</a></p></p>
          </div>
          <div style={{textAlign: 'center'}}>
            <MdEmail  style={{height: '2rem', width: '2rem'}}/>
            <p style={{fontFamily: 'Poppins-Medium', marginTop: '1rem'}}>Email Us</p>
            <p style={{fontFamily: 'Poppins-Regular', color:'#717171', width: '250px'}}>  <a href='mailto:social@pratichakra.com' className='p-text'>social@pratichakra.com</a></p>
          </div>
        </div>
      </div>


      <div className='contactus__3'>
          <div className='app__map__mobile'>
            <div className='map__layout'>
          <GoogleMapReact
            bootstrapURLKeys={{ key: "AIzaSyCMANwkNsfj8j63O73NupJkQdWOhgVseQE" }}
            options={{styles: [
              {
                elementType: "geometry",
                stylers: [{ color: "#f5f5f5" }],
              },
              {
                elementType: "labels.icon",
                stylers: [{ visibility: "off" }],
              },
              {
                elementType: "labels.text.fill",
                stylers: [{ color: "#616161" }],
              },
              {
                elementType: "labels.text.stroke",
                stylers: [{ color: "#f5f5f5" }],
              },
              {
                featureType: "administrative.land_parcel",
                elementType: "labels.text.fill",
                stylers: [{ color: "#bdbdbd" }],
              },
              {
                featureType: "poi",
                elementType: "geometry",
                stylers: [{ color: "#eeeeee" }],
              },
              {
                featureType: "poi",
                elementType: "labels.text.fill",
                stylers: [{ color: "#757575" }],
              },
              {
                featureType: "poi.park",
                elementType: "geometry",
                stylers: [{ color: "#e5e5e5" }],
              },
              {
                featureType: "poi.park",
                elementType: "labels.text.fill",
                stylers: [{ color: "#9e9e9e" }],
              },
              {
                featureType: "road",
                elementType: "geometry",
                stylers: [{ color: "#ffffff" }],
              },
              {
                featureType: "road.arterial",
                elementType: "labels.text.fill",
                stylers: [{ color: "#757575" }],
              },
              {
                featureType: "road.highway",
                elementType: "geometry",
                stylers: [{ color: "#dadada" }],
              },
              {
                featureType: "road.highway",
                elementType: "labels.text.fill",
                stylers: [{ color: "#616161" }],
              },
              {
                featureType: "road.local",
                elementType: "labels.text.fill",
                stylers: [{ color: "#9e9e9e" }],
              },
              {
                featureType: "transit.line",
                elementType: "geometry",
                stylers: [{ color: "#e5e5e5" }],
              },
              {
                featureType: "transit.station",
                elementType: "geometry",
                stylers: [{ color: "#eeeeee" }],
              },
              {
                featureType: "water",
                elementType: "geometry",
                stylers: [{ color: "#c9c9c9" }],
              },
              {
                featureType: "water",
                elementType: "labels.text.fill",
                stylers: [{ color: "#9e9e9e" }],
              },
            ]}}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
          >
            <AnyReactComponent
              lat={28.637490}
              lng={77.434640}
              text="Pratichakra"
            />
          </GoogleMapReact>
          </div>
        </div>
          <div >
            <p style={{fontFamily:'Poppins-SemiBold', fontSize: '3rem', marginTop: '25%'}}>Subscribe</p>
            <p style={{fontFamily:'Poppins-Medium', fontSize: '1rem', marginTop: '-1.5rem'}}>To our newsletter</p>
          </div>
          <div className='margin__left_10'>

          {
              !deliverd_newsletter? <>
                  <form onSubmit={handleContactUs_newsletter}>
                            <div  className='app__newsletter'>
                                <div className='contact__item'>            
                                    <p>Name</p>
                                    <input type="text" value={name} onChange={(e) => setName(e.target.value)}/>
                                </div>
                                <div className='contact__item margin__left_3'>            
                                    <p>Email</p>
                                    <input type="text" value={email_newsletter} onChange={(e) => setEmail_newsletter(e.target.value)}/>
                                </div>
                            </div>
                                <div className='submit__btn submit__btn__margin'>
                                    <input type="submit" value='Submit'/>
                            </div>
                  
                    </form>
              </> 
              :
              <>
              <div style={{textAlign: 'center', marginTop: '2rem'}}>
                 <p style={{fontSize: '2rem', color: '#7D7D7D',fontFamily: 'Poppins-Medium'}}> We succesfully Subscribe</p>
                 <p style={{fontSize: '2rem', color: '#7D7D7D',fontFamily: 'Poppins-Medium'}}> for News Letter</p>
              </div>
                 
              </>
            }

          </div>
      </div>
    </div>
  )
}

export default ContactUs
