import React, {useState, useEffect, useRef} from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Accordion from 'react-bootstrap/Accordion';
import {Toaster, Toast, toast} from 'react-hot-toast'

import {BiUpvote} from 'react-icons/bi'
import {MdDone} from 'react-icons/md'

import Dropdown from 'react-bootstrap/Dropdown';

import { APIURL } from '../../constants';
import './bookEvent.css'

const Data = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    otp: '',
}


function WinnerTab(props) {
    
 const [index, setIndex] = useState(0)
 const [selectedCity, setSelectedCity] = useState("All")
 const [search, setSearch] = useState("")

 const [isVoted, setIsVoted] = useState(false)
 const [isLoggedIn, setIsLoggedIn] = useState(false)
 const [isVotingStarted, setIsVotingStarted] = useState(false)



 const [list, setlist] = useState(props.List)
 const [userBooking, setUserBooking] = useState();

  const scollToRef = useRef();
  const {eventId} = useParams();


  function calculateDifference(date) {
    const givenDate = new Date(date);
    const currentDate = new Date();
    const difference = givenDate - currentDate;
    const differenceInDays = Math.floor(difference / (1000 * 60 * 60 * 24));
  
    return `${differenceInDays}`;
  }



  const handleUpvote = (item) =>{
    item.metaData.upvoteCount = (item.metaData.upvoteCount) ? item.metaData.upvoteCount + 1 : 1;
    if (!item.metaData.upvotedBy) {
        item.metaData.upvotedBy = [];
    }
    item.metaData.upvotedBy.push(localStorage.getItem('user_id'))
    // item.metaData.Voters =>(...item.metaData.Voters, localStorage.getItem('user_id')
    axios.patch(`${APIURL}/booking/${item._id}/update`, {
                  metaData: item.metaData,
                  
            },
            {
            headers: {
                authtoken: localStorage.getItem('userToken')
            }
            })
            .then(function (response) {
              
              localStorage.setItem(`${item._id}_Voting_Status`, true)
              localStorage.setItem(`${localStorage.getItem('userToken')}__isUserVoted__${eventId}`, true)
             
              setIsVoted(true)
            })
            .catch(function (error) {
             
            });
  }

  const addVoterToBooking = () =>{

  }


  const getContestants = async (city) => {
    axios.get(`${APIURL}/booking/getAll?eventId=${props.eventId}&sortBy=metaData.upvoteCount&sortOrder=desc&metaData.city=${city}`, {
    })
    . then(function (response) {
  
    
      let rank = 1 ,upvoteCount = 0, total= 0;
      
      response.data.data.forEach((element, index) => {
        if(element.metaData.upvoteCount > upvoteCount || element.metaData.upvoteCount === upvoteCount){
          response.data.data[index].rank= rank;
          
        } else {
          rank++;
          response.data.data[index].rank= rank;
        }
        // if(typeof element.metaData.upvoteCount == 'number'){
        //   upvoteCount = element.metaData.upvoteCount;
          
        //   total = total + upvoteCount;
        ////   console.log("target" , total , upvoteCount);
        // }
        // if(element?.user?._id == localStorage.getItem("user_id")){
        //     UserBooking = element;
        //  //   console.log(UserBooking)
        // }
      });
      setlist(0);
      setlist(response.data.data);
      TopFive = 0;
      RestContestent = 0;
      TopFive = list?.slice(0,5);
    
      RestContestent = list?.slice(5);
    })
    .catch(function (error) {
     
    });
  }
  const getAllContestants = async () => {
    axios.get(`${APIURL}/booking/getAll?eventId=${props.eventId}&sortBy=metaData.upvoteCount&sortOrder=desc`, {
    })
    . then(function (response) {
    
       
        let rank = 1 ,upvoteCount = 0, total= 0;
        
        response.data.data.forEach((element, index) => {
          if(element.metaData.upvoteCount > upvoteCount || element.metaData.upvoteCount === upvoteCount){
            response.data.data[index].rank= rank;
            
          } else {
            rank++;
            response.data.data[index].rank= rank;
          }
          if(typeof element.metaData.upvoteCount == 'number'){
            upvoteCount = element.metaData.upvoteCount;
            
            total = total + upvoteCount;
            
          }

          if(element?.user?._id == localStorage.getItem("user_id")){
            setUserBooking(element);
            
          }
        });

        setlist(response.data.data)

      })
      .catch(function (error) {
       
      });
  }
  useEffect(() => {
    
    if (localStorage.getItem(`${localStorage.getItem('userToken')}__isUserVoted__${eventId}`) === true) {
        setIsVoted(true);
    }
    if (localStorage.getItem('userToken')) {
        setIsLoggedIn(true);
    }
    if(calculateDifference(props.EventData?.eventDate) <= 0) {
        setIsVotingStarted(true)
       
    }


  });
  





  const TopFive = list?.slice(0,5);
  const RestContestent = list?.slice(5);


  const handleFilterChange = (city) =>{
    setSelectedCity(city);
    if(city === "All"){
        getAllContestants();
    }else {
        getContestants(city);
    }
    
  }


  const CreateUser = ()=>{


    axios.patch(`${APIURL}/user/otp/saveUserAndSendOtpToMobileNumber`, {
        firstName: Data.firstName,
        lastName: Data.lastName,
        email: Data.email,
        mobileNumber: Data.phone,
        userType: "user",
        status: "active",
      },{
        headers: {
            // authtoken: localStorage.getItem('userToken')
            authtoken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzYzkyMzgzMzc2YWQzOWFjZjFlOTcwOCIsImlhdCI6MTY3NDEzMzk1NH0.ZUR5fwVkuuV4hw9It08j_8dviYX4UMQ92K_klqe9938'
        }
      })
      .then(function (response) {
      
        localStorage.setItem('user_id', response.data.data.userId);
        
      })
      .catch(function (error) {
        
      });

    
    setIndex(1);

}
  const VerifyOtp =() => {

    
    axios.patch(`${APIURL}/user/otp/verifyOtpToMobileNumber`, {
        mobileNumber:Data.phone,
        otp:Data.otp
    },{
        headers: {
            authtoken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzYzkyMzgzMzc2YWQzOWFjZjFlOTcwOCIsImlhdCI6MTY3NDEzMzk1NH0.ZUR5fwVkuuV4hw9It08j_8dviYX4UMQ92K_klqe9938'
        }
      })
      .then(function (response) {
       
        localStorage.setItem('userToken', response.data.data)
        
        setIsLoggedIn(true)
      })
      .catch(function (error) {
      
        toast.error("Wrong OTP");
      });

}
  const Verification = () => {
    const [otp, setOtp] = useState("");
    const handleSubmit = (event) => {
    event.preventDefault();
        Data.otp = Number(otp) ;
        VerifyOtp();
    }
    
    return(
    <div className='app__form'>

            <div className='form__header'>
                <p className='step__count'><span style={{color: '#ddab6c'}}>2</span>/2</p>
                <p className='form__name'>OTP Sent</p> 
            </div>
        <form onSubmit={handleSubmit}>
            <div className='form__item'>            
                <p>Enter Otp Sent on  {Data.phone}</p>
                <input type="text" value={otp} onChange={(e) => setOtp(e.target.value)}/>
            </div>

            <div className='form__submit'>
                <input type="submit" value='Verify' />
            </div>
    
        </form>
    </div>
    )
  }
  const BasicDetails = () =>{
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");

    const handleSubmit = (event) => {
    event.preventDefault();

    if (firstName === "") {
        toast.error("Enter first name")
    } else if (lastName === "") {
        toast.error("Enter Last Name")
    } else if(phone > 10000000000 || phone < 999999999) {
        toast.error("Enter Valid Mobile no")
    }else if(email === ""){
        toast.error("Enter email")
    }else{
        Data.firstName = firstName;
        Data.lastName = lastName;
        Data.email = email;
        Data.phone = Number(phone) ;

        CreateUser()
    }
    
    }
    return(
        
        <div className='app__form'>

            <div className='form__header'>
                <p className='step__count'><span style={{color: '#ddab6c'}}>1</span>/2</p>
                <p className='form__name'>Create Account</p> 
            </div>
            
            <form onSubmit={handleSubmit}>
                <div className='form__item'>            
                    <p>First Name</p>
                    <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
                </div>
                <div className='form__item'>            
                    <p>Last Name</p>
                    <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)}/>
                </div>
                <div className='form__item'>            
                    <p>Email</p>
                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                </div>
                <div className='form__item'>            
                    <p>Phone</p>
                    <input type="tel" value={phone} onChange={(e) => setPhone(e.target.value)}/>
                </div>


                <div className='form__submit'>
                    <input type="submit" value='Create Account'/>
                </div>
        
            </form>
        </div>
    )
  }

  var Steps = [<BasicDetails/>, <Verification/>]


  return (


  <>
    {localStorage.getItem('userToken') && isLoggedIn? <>
        <div className='app__winner'>
        {/* Top Five Contestant*/ }
            <div style={{marginTop: '5%'}}>
                {
                !(calculateDifference(props.EventData?.eventDate) < 0)? <>
                    <p style={{}}>Votings Will be Open on <span style={{color:'#ddab6c'}}> {props.EventData?.eventDate?.slice(0,10)}</span></p>
                </> :
                <>
                </>
                }
            </div>
                
        
            <Dropdown >
                 <p style={{}}>Please Select Your City To View Leader Board</p>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {selectedCity === ""? "Select City": selectedCity}
                    
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() =>handleFilterChange("All") }>All</Dropdown.Item>
                    {props.EventData.metaData.cities.map((item , key) => {
                        return(
                            <Dropdown.Item onClick={() =>handleFilterChange(item) }>{item}</Dropdown.Item>
                        )
                    })} 
                </Dropdown.Menu>
            </Dropdown>
            <div style={{width: '100%'}}>
               <p style={{marginTop: '2rem', fontSize:'1.4rem', textAlign: 'left', justifyContent: 'left', width: '2%', marginLeft: '6%', fontFamily: 'Poppins-SemiBold'}}>Top <span style={{fontFamily:"Poppins-Bold", color: '#ddab6c', fontSize: '2rem', lineHeight: '1px'}}>Performers</span></p>
            </div>

            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center'}}>
                <div>

                </div>
                {TopFive?.map((item, key) =>{
                return(
                    <div style={{margin: '3rem 1rem'}} key={key}>
                    <div style={{backgroundImage: `linear-gradient(0deg, #00000099 30%, #ffffff44 100%), url(${item?.metaData?.images?.[0]?.imageUrl})`}} className="winner__top">
                        
                        
                        {/* <div className='card__block1'>
                            <div className='card__block'></div>
                            <p>{item.metaData.contestentName}</p>
                        </div>
                        <div className='card__block2'></div> */}
                        <div className='card__name'>
                            <p style={{marginTop:'17rem'}}>{item.metaData.contestentName}</p>
                        </div>
                        <div className='card__boxs'>
                            <div className='card__box'>
                                <p style={{marginTop: 0}}>{item.metaData.upvoteCount === undefined ? "0" : item.metaData.upvoteCount}</p>
                                <p style={{marginTop:'3rem', fontSize: '0.9rem'}}>Votes</p>
                            </div>
                            <div className='card__box' style={{marginTop: '0rem'}}>
                                <p style={{marginTop: 0}}>{item.rank}</p>
                                <p style={{marginTop:'3rem', fontSize: '0.9rem'}}>Rank</p>
                            </div>
                            <div className='card__box' style={{height: '10rem', justifyContent: 'flex-end', bottom: '5%'}}>
                                {
                                    (calculateDifference(props.EventData?.eventDate) < 0)? <>
                                        {!localStorage.getItem(`${localStorage.getItem('userToken')}__isUserVoted__${eventId}`) && !isVoted ? <button className='btn__upvote2' onClick={() => {handleUpvote(item)}}>Upvote</button> : <>{localStorage.getItem(`${item._id}_Voting_Status`)? <><span style={{display: 'flex', alignItems: 'center', marginRight: '20%', marginBottom: '18%'}}><MdDone style={{height: '2rem', width: '2rem'}}/>Upvoted</span></> : ''}</>}
                                    </> : 
                                    <>
                                    
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    </div>
                )
                })}
            </div>

            {userBooking ? <>
            <Accordion defaultActiveKey="-1" style={{margin: '0 3%', paddingBottom: '5%'}}>
                <div style={{display: 'flex', flexDirection: 'row', width: '100%'}} className="ranking__header">
                <p style={{marginTop: '3rem', fontSize:'1.4rem', textAlign: 'left', justifyContent: 'left', width: '2%', marginLeft: '6%', fontFamily: 'Poppins-SemiBold'}}>Your <span style={{fontFamily:"Poppins-Bold", color: '#ddab6c', fontSize: '2rem', lineHeight: '1px'}}>Ranking</span></p>
                </div>
                
                <Accordion.Item eventKey={"key"}>
                    <Accordion.Header>
                    <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                        <div style={{marginLeft: '1rem'}}>
                        <img style={{height: '5rem', width: '5rem', objectFit: 'cover'}} src={userBooking?.metaData?.images?.[0]?.imageUrl}/>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', marginLeft: '1rem', justifyContent: 'center'}}>
                        <div className='winner__name'>
                            <p>{userBooking?.metaData?.contestentName}</p>
                        </div>
                        <div className='winner__id'>
                        <p style={{color: '#ddab6c'}}>#{userBooking?.bookingId}</p>
                        </div>
                        </div>

                        <div className='header__boxs'>
                            <div className='header__box'>
                                <p style={{marginTop: 0}}>{userBooking?.metaData.upvoteCount === undefined ? "0" : userBooking.metaData.upvoteCount}</p>
                                <p style={{marginTop:'-0.5rem', fontSize: '0.9rem'}}>Votes</p>
                            </div>
                            <div className='header__box'>
                                {/* <p>1</p> */}
                                
                                <p style={{marginTop: 0}}>{userBooking?.metaData?.city}</p>
                                <p style={{marginTop:'-0.5rem', fontSize: '0.9rem'}}>City</p>
                            </div>
                            <div className='header__box'>

                                <p style={{marginTop: 0}}>{userBooking?.rank}</p>
                                <p style={{marginTop:'-0.5rem', fontSize: '0.9rem'}}>Rank</p>
                            </div>
                            {/* <div style={{zIndex: '5'}}>
                              {!localStorage.getItem(`${localStorage.getItem('userToken')}__isUserVoted__${eventId}`)? <button className='btn__upvote' onClick={() => {handleUpvote(item)}}>Upvote</button> : <>{localStorage.getItem(`${item._id}_Voting_Status`)? <><button className='btn__upvote'>upvoted</button></> : ''}</>}                           
                             </div> */}
                        </div>
                    </div>
                    </Accordion.Header>
                    <Accordion.Body>
                    <div className='winner__details'>
                        <p style={{fontSize: '1.5rem'}}>Vote For  <span style={{color:'#ddab6c'}}> {userBooking?.metaData?.contestentName}</span></p>

                        {
                            (calculateDifference(props.EventData?.eventDate) < 0)? <>
                                {!localStorage.getItem(`${localStorage.getItem('userToken')}__isUserVoted__${eventId}`) && !isVoted ? <button className='btn__upvote' onClick={() => {handleUpvote(userBooking)}}>Upvote</button> : <>{localStorage.getItem(`${userBooking._id}_Voting_Status`)? <><button className='btn__upvote'>upvoted</button></> : ''}</>}                        
                            </> : 
                            <>
                            
                            </>
                        }
                        <p style={{marginTop: '1rem', color: '#ddab6c'}}>Contestent Bio</p>
                        <p style={{marginTop: '0.5rem'}}>{userBooking?.metaData?.bio}</p>
                        <div>
                            {userBooking?.metaData?.images?.map((item,key) =>{
                            return(
                                <>
                                <img key={key} style={{height: '10rem', width: '10rem', margin: '1rem 1.2rem',objectFit: 'cover'}} src={item.imageUrl} />
                                </>
                            )
                            })}
                        </div>
                    </div>
                
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            </> : <>


            </>}
            
      


            <Accordion defaultActiveKey="-1" style={{margin: '0 3%', paddingBottom: '5%'}}>
                <div className="ranking__header">
                <p style={{marginTop: '3rem', fontSize:'1.4rem', textAlign: 'left', justifyContent: 'left', width: '2%', marginLeft: '6%', fontFamily: 'Poppins-SemiBold'}}>All <span style={{fontFamily:"Poppins-Bold", color: '#ddab6c', fontSize: '2rem', lineHeight: '1px'}}>Rankings</span></p>
                    <div className='form__item form__item__mobile' style={{justifyContent: 'flex-end', width: '100%', textAlign: 'right'}}>            
                        <input className='searchBar' placeholder='Search by name' type="text" value={search} onChange={(e) => setSearch(e.target.value)}/>
                    </div>
                </div>
                
            {list?.filter((val) => {
                if(search == "" && selectedCity == 'All'){
                    return val;
                } else if (val.metaData.contestentName.toLowerCase().includes(search.toLowerCase()) && val.metaData.city.toLowerCase().includes(selectedCity.toLowerCase())) {
                    return val
                }
                // if(selectedCity == ""){
                //     return val;
                // } else if (val.metaData.city.toLowerCase().includes(selectedCity.toLowerCase())) {
                //     return val
                // }
            }).map((item, key) =>{
            return(
                <div ref={scollToRef}>
                <Accordion.Item eventKey={key}>
                    <Accordion.Header>
                    <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                        <div style={{marginLeft: '1rem'}}>
                        <img style={{height: '5rem', width: '5rem', objectFit: 'cover'}} src={item.metaData.images?.[0]?.imageUrl}/>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', marginLeft: '1rem', justifyContent: 'center'}}>
                        <div className='winner__name'>
                            <p>{item.metaData.contestentName}</p>
                        </div>
                        <div className='winner__id'>
                        <p style={{color: '#ddab6c'}}>#{item.bookingId}</p>
                        </div>
                        </div>

                        <div className='header__boxs'>
                            <div className='header__box'>
                                <p style={{marginTop: 0}}>{item.metaData.upvoteCount === undefined ? "0" : item.metaData.upvoteCount}</p>
                                <p style={{marginTop:'-0.5rem', fontSize: '0.9rem'}}>Votes</p>
                            </div>
                            <div className='header__box'>
                                {/* <p>1</p> */}
                                
                                <p style={{marginTop: 0}}>{item.metaData.city}</p>
                                <p style={{marginTop:'-0.5rem', fontSize: '0.9rem'}}>City</p>
                            </div>
                            <div className='header__box'>

                                <p style={{marginTop: 0}}>{item.rank}</p>
                                <p style={{marginTop:'-0.5rem', fontSize: '0.9rem'}}>Rank</p>
                            </div>
                            {/* <div style={{zIndex: '5'}}>
                              {!localStorage.getItem(`${localStorage.getItem('userToken')}__isUserVoted__${eventId}`)? <button className='btn__upvote' onClick={() => {handleUpvote(item)}}>Upvote</button> : <>{localStorage.getItem(`${item._id}_Voting_Status`)? <><button className='btn__upvote'>upvoted</button></> : ''}</>}                           
                             </div> */}
                        </div>
                    </div>
                    </Accordion.Header>
                    <Accordion.Body>
                    <div className='winner__details'>
                        <p style={{fontSize: '1.5rem'}}>Vote For  <span style={{color:'#ddab6c'}}> {item.metaData.contestentName}</span></p>

                        {
                            (calculateDifference(props.EventData?.eventDate) < 0)? <>
                                 {!localStorage.getItem(`${localStorage.getItem('userToken')}__isUserVoted__${eventId}`) && !isVoted ? <button className='btn__upvote' onClick={() => {handleUpvote(item)}}>Upvote</button> : <>{localStorage.getItem(`${item._id}_Voting_Status`)? <><button className='btn__upvote'>upvoted</button></> : ''}</>}                        
                            </> : 
                            <>
                            
                            </>
                        }
                        <p style={{marginTop: '1rem', color: '#ddab6c'}}>Contestent Bio</p>
                        <p style={{marginTop: '0.5rem'}}>{item.metaData.bio}</p>
                        <div>
                            {item?.metaData?.images?.map((item,key) =>{
                            return(
                                <>
                                <img key={key} style={{height: '10rem', width: '10rem', margin: '1rem 1.2rem',objectFit: 'cover'}} src={item.imageUrl} />
                                </>
                            )
                            })}
                        </div>
                    </div>
                
                    </Accordion.Body>
                </Accordion.Item>
                </div>
            )
            })}
            </Accordion>
        </div>
    </> : <>

    <div style={{display:'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <p style={{textAlign: 'center', paddingTop: '5%', fontSize: '1.6rem', fontFamily: 'Poppins-SemiBold'}}>You have to <span style={{color: '#ddab6c'}}>Login</span>  first to</p>
        <p style={{textAlign: 'center',  fontSize: '1.6rem', fontFamily: 'Poppins-SemiBold', marginTop: '-1.5%'}}>View The <span style={{color: '#ddab6c'}}>LeaderBoard</span>  and Vote</p>
        <div className='booknow__block11' style={{paddingTop: "10%"}}>
           {Steps[index]}
        </div>
    </div>
    
    </>}

    

    <Toaster       
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          success: {
            iconTheme: {
              primary: '#141414',
              secondary: 'white',
            },
          },
        }}
        />
  </>
  )
}

export default WinnerTab
