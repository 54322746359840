import React, { useEffect, useState } from "react";
import axios from "axios";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Header from "../navbar";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import Aos from "aos";
import arrow from "../../images/arrow.svg";
import useDocumentTitle from "../useDocumentTitle";
import { APIURL } from '../../constants';

import "./services.css";

function Services() {
  useDocumentTitle("Our Services");
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchCategories();
  }, []);

  

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        `${APIURL}/category/getAll`
      );
      setCategories(response.data.data); 
      console.log(categories);
    } catch (error) {
      console.error("Failed to fetch categories:", error);
    }
  };
  console.log(categories);

  const gotoContact = (identifier,indx) => {
    navigate("/our-services/" + identifier+"/"+indx);
  };

  return (
    <section className="app__services">
      <div className="row">
        <div className="col-lg-6 col-12 app__services">
          <p className="ourservices">
            our
            <br />
            <span className="services">services</span>
          </p>
          <br />
          <p className="desc5">
            We work with the best companies and vetted <br />
            vendors to provide you a hassle-free event.
            <br /> You are only assigned two vendors that <br />
            present you with the lowest prices and highest
            <br /> quality. All hassle-free!
          </p>
          <img align="left" src="images/Frame1.png" className="spacetop" />
          <img
            data-aos="fade-up"
            className="bob"
            src="images/bob.svg"
            alt="Bob"
          />
          <p className="bolding2">
            OUR SPECIALIZED EVENTS{" "}
            <img src={arrow} className="ellipse" alt="Arrow" />
          </p>
        </div>
        <div className="col-lg-6 col-12 bgcurves app__serv">
          <div className="positioning">
            {categories.map((category, index) => (
              <motion.div
                key={index}
                className={index % 2 === 0 ? "graybox" : "blackbox" }
                onClick={() => {
                  gotoContact(category.identifier,index)
                }}
                whileHover={{ x: 35 }}
              >
                <h1 className="servicesheader">
                  {category.name}{" "}
                  <img
                    className="building"
                    src={`./images/${category.iconImage}`}
                  />
                </h1>
                <p className="servicesdesc">{category.desc}</p>
                <p className="rightarrow">⟶</p>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
