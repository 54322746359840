import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import HomeScreen from "./components/Home/homescreen";
import {BrowserRouter,Route,Routes,Switch,Link, Router} from 'react-router-dom';
import Aboutus from "./components/AboutUs/aboutus";
import Header from "./components/navbar";
import Services from "./components/Services/services";
import Seminar_And_Conferences from "./components/Services/seminarandconferences";
import Corporate from "./components/Services/corporate";
import ServicePage from "./components/Services/corporate";
import LiveEvents from "./components/LiveEvent/LiveEvents";
import EventDetails from "./components/LiveEvent/EventDetails";
import Footer from "./components/Footer";
import Privacy from "./components/Home/Privacy";
import TermsAndconditions from './components/Home/TermsAndconditions'
import RefundPolicy from "./components/Home/RefundPolicy";
import Booknow from "./components/LiveEvent/Booknow";
import ContactUs from "./components/ContactUs";
import Blog from "./components/Blog/Blog";
import CategorySelector from "./components/LiveEvent/CategorySelector";
import BlogDetails from "./components/Blog/BlogDetails";
import LiveStream from "./components/LiveEvent/LiveStream";
import BookingTool from "./components/BookingTool/BookingTool";
import Courses from "./components/Courses/Courses";
import CourseDetails from "./components/Courses/CourseDetails/CourseDetails";

function App() {
  return (
    <>
    
    <BrowserRouter>
        <Header></Header>
         <Routes>
          <Route path='/' element={<HomeScreen></HomeScreen>} />
          <Route path='/about' element={<Aboutus></Aboutus> } />
          <Route path='/our-services' element={<Services></Services>} />
          <Route path='/explore' element={<CategorySelector></CategorySelector>} />
          <Route path='/booking/tool' element={<BookingTool></BookingTool>} />
          <Route path='/courses' element={<Courses></Courses>} />
          <Route path='/course/:courseId/:courseIdentifier' element={<CourseDetails></CourseDetails>} />
          <Route path='/explore/:eventCategory' element={<LiveEvents></LiveEvents>} />
          <Route path='/event-details/:eventId/:eventname' element={<EventDetails></EventDetails>} />
          <Route path='/event-details/:eventId/:eventname/watch/:group/:match' element={<LiveStream></LiveStream>} />
          <Route path='/our-services/:serviceId/:index' element={<Corporate></Corporate>} />
          <Route path='/our-services/:serviceId/sub-service/:subServiceId' element={<Seminar_And_Conferences></Seminar_And_Conferences>} />
          <Route path='/privacy-policy' element={<Privacy></Privacy> } />
          <Route path='/terms&conditions' element={<TermsAndconditions></TermsAndconditions> } />
          <Route path='/cancellation&refund-policy' element={<RefundPolicy></RefundPolicy> } />
          <Route path='/contact-us' element={<ContactUs></ContactUs>} />
          <Route path='/resources' element={<Blog></Blog>} />
          <Route path='/resources/article/:articleId/:identifier' element={<BlogDetails></BlogDetails>} />
        </Routes> 
        <Footer></Footer>
    </BrowserRouter>
                          
    {/* <BrowserRouter>
    <Header></Header>
      <HomeScreen/>
      <Aboutus/>
      <Services/>

    </BrowserRouter> */}


    {/* <Router>
    <Switch>
          <Route path="/">
            <Header/>
            <HomeScreen />
            <Aboutus/>
            <Services/>
            <ServicePage/>
            <Seminar_And_Conferences/>
          </Route>
        </Switch>
    </Router> */}
    </>
  );
}

export default App;
