import React, { useState, useEffect } from "react";
import arrow from "../../images/arrow.svg";
import axios from "axios";
import eventCreated from "../../images/eventCreated.gif";
import "./BookingTool.css";
import { APIURL } from "../../constants";
import illustration_create from "../../images/illustration_create_event.svg";
import illustration_created from "../../images/illustration_event_created.svg";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Toaster, Toast, toast } from "react-hot-toast";
import useDocumentTitle from "../useDocumentTitle.js";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import sprial from "../../images/spiral.svg";
import circles from "../../images/circles.svg";
import { useLocation, useParams } from "react-router-dom";

const Data = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  otp: "",
};

const BookingTool = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [categories, setCategories] = useState();
  const [creatdEventId, setCreatdEventId] = useState();

  const location = useLocation();
  const { state } = location;
  
  useEffect(() => {
    if (state && state.Category && state.SubCategory) {
      setData({
        ...data,
        category: state.Category,
        subCategory: state.SubCategory,
      });
      setCurrentSlide(3);
    }
  }, [state]);


  const [data, setData] = useState({
    category: "",
    subCategory: "",
    programType: "",
    crowd: "",
    venueType: "",
    budget: "",
    date: "",
    time: "",
    location: "",
  });

  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    otp: "",
  });

  const createBookingRequest = () => {
    axios
      .post(
        `${APIURL}/bookingRequest/create`,
        {
          category: data.category,
          subCategory: data.subCategory,
          programList: data.programType,
          gatheringSize: data.crowd,
          venueType: data.venueType,
          clientBudget: data.budget,
          eventDate: data.date,
          eventTime: data.time,
        },
        {
          headers: {
            authtoken: localStorage.getItem("userToken"),
          },
          params: {
            status: "active",
          },
        }
      )
      .then(function (response) {
        console.log(response.data.data);
        setCreatdEventId(response.data?.data?.bookingRequestId);
      })
      .catch(function (error) {});
  };
  useEffect(() => {
    // createBookingRequest()
  }, []);

  const getAllCategories = () => {
    axios
      .get(`${APIURL}/category/getAll`, {
        headers: {
          authtoken: localStorage.getItem("userToken"),
        },
        params: {
          status: "active",
        },
      })
      .then(function (response) {
        setCategories(response.data.data);
        return response.data.data;
      })
      .catch(function (error) {});
  };

  // const getAllSubCategories = () => {
  //   axios.get(`${APIURL}/subCategory/getAll?category=${data.category}`, {
  //     headers: {
  //       authtoken: localStorage.getItem('userToken')
  //     },
  //     params: {
  //       status: "active"
  //     }
  //   })
  //   . then(function (response) {
  //     setCategories(response.data.data);
  //     return response.data.data;

  //   })
  //   .catch(function (error) {

  //   });
  // }
  useEffect(() => {
    getAllCategories();
  }, []);

  // console.log("categories",categories)


  const CreateUser = () => {
    console.log(userData);

    axios
      .patch(
        `${APIURL}/user/otp/saveUserAndSendOtpToMobileNumber`,
        {
          firstName: Data.firstName,
          lastName: Data.lastName,
          email: Data.email,
          mobileNumber: Data.phone,
          userType: "user",
          status: "active",
        },
        {
          headers: {
            authtoken:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzYzkyMzgzMzc2YWQzOWFjZjFlOTcwOCIsImlhdCI6MTY3NDEzMzk1NH0.ZUR5fwVkuuV4hw9It08j_8dviYX4UMQ92K_klqe9938",
          },
        }
      )
      .then(function (response) {
        localStorage.setItem("user_id", response.data.data.userId);
        setCurrentSlide(currentSlide + 1);
      })
      .catch(function (error) {
        toast.error("Something went Wrong");
      });
  };

  const VerifyOtp = () => {
    axios
      .patch(
        `${APIURL}/user/otp/verifyOtpToMobileNumber`,
        {
          mobileNumber: Data.phone,
          otp: Data.otp,
        },
        {
          headers: {
            authtoken:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzYzkyMzgzMzc2YWQzOWFjZjFlOTcwOCIsImlhdCI6MTY3NDEzMzk1NH0.ZUR5fwVkuuV4hw9It08j_8dviYX4UMQ92K_klqe9938",
          },
        }
      )
      .then(function (response) {
        localStorage.setItem("userToken", response.data.data);

        createBookingRequest();
      })
      .catch(function (error) {
        toast.error("Wrong OTP");
      });
  };

  const Welcome = () => {
    const handleArrowClick = () => {
      console.log(currentSlide);
      setCurrentSlide(currentSlide+1);
      console.log(currentSlide);
    };
    return (
      <div className="tool_welcome">
        <img className="welcome_illustation" src={illustration_create} />
        <h1 style={{ color: "#DDAB6C" }}>
          Let's Create <span style={{ color: "#A07146" }}>an Event</span>
        </h1>
        <h1>Of Your Choice</h1>
        <p>
          Plan and start an event with us and invite your guests without any
          hassle
        </p>
        <div className="arrow_btn" onClick={() => handleArrowClick()}>
          <img src={arrow} />
        </div>
      </div>
    );
  };

  const SelectCategory = () => {
    const [selectedCategory, setSelectedCategory] = useState("");

    const handleClickCategory = (category) => {
      if (selectedCategory === category) {
        setSelectedCategory("");
      } else {
        setSelectedCategory(category);
      }
    };
    const handleArrowClick = () => {
      console.log(currentSlide);
      console.log(selectedCategory);
      if (selectedCategory !== "") {
        setData({
          ...data,
          category: selectedCategory,
        });
        console.log(data);
        setCurrentSlide(currentSlide + 1);
      }
      console.log(currentSlide);
    };

      return (
      <div className="">
        <p className="step__count step__count2">
          <span style={{ color: "#ddab6c", whiteSpace: "nowrap" }}>STEP 1</span>
          -{slide?.length - 1}
        </p>
        <div className="dashed_line">
          <p
            style={{
              whiteSpace: "nowrap",
              textAlign: "center",
              border: "1.56872px dashed #DDAB6C",
              width: "30%",
            }}
          ></p>
        </div>
        <h3 className="step_question">
          What type of event{" "}
          <span style={{ color: "#ffffff" }}>are you planning?</span>
        </h3>

        <div className="options_container">
          {categories?.map((item, key) => {
            return (
              <div
                className="option_border"
                style={{
                  background:
                    selectedCategory === item._id
                      ? "linear-gradient(to bottom, #dda144, #141414)"
                      : "",
                }}
                onClick={() => handleClickCategory(item._id)}
              >
                <div className="option">
                  <div>
                    <img />
                  </div>
                  <p>{item.name}</p>
                </div>
              </div>
            );
          })}
        </div>
        <div className="arrow_btn" onClick={() => handleArrowClick()}>
          <img src={arrow} />
        </div>
      </div>
    );
  };

  const SelectSubCategory = () => {
    const [selectedSubCategory, setSelectedSubCategory] = useState("");
    const handleClickSubCategory = (subCategory) => {
      if (selectedSubCategory === subCategory) {
        setSelectedSubCategory("");
      } else {
        setSelectedSubCategory(subCategory);
      }
    };
    const handleArrowClick = () => {
      if (selectedSubCategory !== "") {
        setData({
          ...data,
          subCategory: selectedSubCategory,
        });
        console.log(data);
        console.log("sub", selectedSubCategory);
        setCurrentSlide(currentSlide + 1);
      }
    };
    
    return (
      <>
        <p className="step__count step__count2 step__count step__count22">
          <span style={{ color: "#ddab6c", whiteSpace: "nowrap" }}>STEP 2</span>
          -{slide?.length - 1}
        </p>
        <div className="dashed_line">
          <p
            style={{
              whiteSpace: "nowrap",
              textAlign: "center",
              border: "1.56872px dashed #DDAB6C",
              width: "30%",
            }}
          ></p>
        </div>
        <h3 className="step_question">
          What type of{" "}
          {categories
            .filter((val) => {
              if (val._id.includes(data?.category)) {
                return val;
              }
            })
            .map((item, key) => {
              return <>{item?.name?.toLowerCase()}</>;
            })}{" "}
          <span style={{ color: "#ffffff" }}>events you are interested?</span>
        </h3>
        <div className="options_container">
          {categories
            ?.filter((val) => {
              if (val._id.includes(data?.category)) {
                return val;
              }
            })
            ?.map((item, key) => {
              return (
                <>
                  {item?.subCategories?.map((sub, key) => {
                    return (
                      <>
                        <div
                          className="option_border"
                          style={{
                            background:
                              selectedSubCategory === sub?._id
                                ? "linear-gradient(to bottom, #dda144, #141414)"
                                : "",
                          }}
                          onClick={() => handleClickSubCategory(sub?._id)}
                        >
                          <div className="option">
                            <div>
                              <img />
                            </div>
                            <p>{sub?.name}</p>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </>
              );
            })}
        </div>
        <div className="arrow_btn" onClick={() => handleArrowClick()}>
          <img src={arrow} />
        </div>
      </>
    );
  };

  const ProgramList = () => {
    const [selectedProgram, setSelectedProgramory] = useState([]);
    const allServices = [];
    const handleClickProgramCategory = (program) => {
      console.log("programClicked");
      if (program === "All") {
        setSelectedProgramory([]);
        categories?.filter((val) => {
          if (val._id.includes(data?.category)) {
            console.log(val?.eventServices?.serviceList);
            val?.eventServices?.serviceList.map((service, key) => {
              return allServices.push(service.serviceName);
            });
          }
        });
        console.log(allServices);
        setSelectedProgramory(allServices);
        console.log(selectedProgram);
      } else if (selectedProgram.includes(program)) {
        setSelectedProgramory((current) =>
          current.filter(
            (selectedProgram) => !selectedProgram.includes(program)
          )
        );
        console.log(selectedProgram);
      } else {
        setSelectedProgramory([...selectedProgram, program]);
        console.log(selectedProgram);
      }
    };
    const handleArrowClick = () => {
      if (selectedProgram !== "") {
        setData({
          ...data,
          programType: selectedProgram,
        });
        console.log(data);
        setCurrentSlide(currentSlide + 1);
      }
    };
    return (
      <>
        <p className="step__count step__count2">
          <span style={{ color: "#ddab6c", whiteSpace: "nowrap" }}>STEP 3</span>
          -{slide?.length - 1}
        </p>
        <div className="dashed_line">
          <p
            style={{
              whiteSpace: "nowrap",
              textAlign: "center",
              border: "1.56872px dashed #DDAB6C",
              width: "30%",
            }}
          ></p>
        </div>
        <h3 className="step_question">
          Which event services{" "}
          <span style={{ color: "#ffffff" }}>do you want?</span>
        </h3>
        <div className="options_container">
          <div
            className="option_border"
            style={{
              background: selectedProgram.includes("")
                ? "linear-gradient(to bottom, #dda144, #141414)"
                : "",
            }}
            onClick={() => handleClickProgramCategory("All")}
          >
            <div className="option">
              <p>All</p>
            </div>
          </div>
          {categories
            ?.filter((val) => {
              if (val._id.includes(data?.category)) {
                return val;
              }
            })
            ?.map((item, key) => {
              return (
                <>
                  {item?.eventServices?.serviceList?.map((ser, key) => {
                    return (
                      <>
                        <div
                          className="option_border"
                          style={{
                            background: selectedProgram.includes(
                              ser?.serviceName
                            )
                              ? "linear-gradient(to bottom, #dda144, #141414)"
                              : "",
                          }}
                          onClick={() =>
                            handleClickProgramCategory(ser.serviceName)
                          }
                        >
                          <div className="option">
                            <div>
                              <img />
                            </div>
                            <p>{ser?.serviceName}</p>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </>
              );
            })}
        </div>
        <div className="arrow_btn" onClick={() => handleArrowClick()}>
          <img src={arrow} />
        </div>
      </>
    );
  };

  const CrowdAndEventType = () => {
    const [selectedCrowd, setSelectedCrowd] = useState("");
    const [selectedEventType, setSelectedEventType] = useState("");
    const handleCrowdClick = (crowd) => {
      if (selectedCrowd === crowd) {
        setSelectedCrowd("");
      } else {
        setSelectedCrowd(crowd);
      }
    };
    const handleEventTypeClick = (eventType) => {
      if (selectedEventType === eventType) {
        setSelectedEventType("");
      } else {
        setSelectedEventType(eventType);
      }
    };
    const handleArrowClick = () => {
      console.log(selectedCrowd);
      if (selectedCrowd !== "" || selectedEventType !== "") {
        setData({
          ...data,
          crowd: selectedCrowd,
          venueType: selectedEventType,
        });
        console.log(data);
        setCurrentSlide(currentSlide + 1);
      }
    };
    return (
      <>
        <p className="step__count step__count2">
          <span style={{ color: "#ddab6c", whiteSpace: "nowrap" }}>STEP 4</span>
          -{slide?.length - 1}
        </p>
        <div className="dashed_line">
          <p
            style={{
              whiteSpace: "nowrap",
              textAlign: "center",
              border: "1.56872px dashed #DDAB6C",
              width: "30%",
            }}
          ></p>
        </div>
        <h3 className="step_question">
          How many people{" "}
          <span style={{ color: "#ffffff" }}>are expected?</span>
        </h3>
        <div className="options_container" style={{ margin: "1rem, 0" }}>
          <div
            className="option_border"
            style={{
              background:
                selectedCrowd === "1-50"
                  ? "linear-gradient(to bottom, #dda144, #141414)"
                  : "",
            }}
            onClick={() => handleCrowdClick("1-50")}
          >
            <div className="option">
              <div>
                <img />
              </div>
              <p>1-50</p>
            </div>
          </div>
          <div
            className="option_border"
            style={{
              background:
                selectedCrowd === "50-100"
                  ? "linear-gradient(to bottom, #dda144, #141414)"
                  : "",
            }}
            onClick={() => handleCrowdClick("50-100")}
          >
            <div className="option">
              <div>
                <img />
              </div>
              <p>50-100</p>
            </div>
          </div>
          <div
            className="option_border"
            style={{
              background:
                selectedCrowd === "100-200"
                  ? "linear-gradient(to bottom, #dda144, #141414)"
                  : "",
            }}
            onClick={() => handleCrowdClick("100-200")}
          >
            <div className="option">
              <div>
                <img />
              </div>
              <p>100-200</p>
            </div>
          </div>
          <div
            className="option_border"
            style={{
              background:
                selectedCrowd === "200-500"
                  ? "linear-gradient(to bottom, #dda144, #141414)"
                  : "",
            }}
            onClick={() => handleCrowdClick("200-500")}
          >
            <div className="option">
              <div>
                <img />
              </div>
              <p>200-500</p>
            </div>
          </div>
          <div
            className="option_border"
            style={{
              background:
                selectedCrowd === "500-1000"
                  ? "linear-gradient(to bottom, #dda144, #141414)"
                  : "",
            }}
            onClick={() => handleCrowdClick("500-1000")}
          >
            <div className="option">
              <div>
                <img />
              </div>
              <p>500-1000</p>
            </div>
          </div>
          <div
            className="option_border"
            style={{
              background:
                selectedCrowd === "Above 1000"
                  ? "linear-gradient(to bottom, #dda144, #141414)"
                  : "",
            }}
            onClick={() => handleCrowdClick("Above 1000")}
          >
            <div className="option">
              <div>
                <img />
              </div>
              <p>Above 1000</p>
            </div>
          </div>
        </div>
        <h3 className="step_question">
          What’s the type{" "}
          <span style={{ color: "#ffffff" }}>of event venue?</span>
        </h3>
        <div className="options_container">
          <div
            className="option_border"
            style={{
              background:
                selectedEventType === "Open Ground"
                  ? "linear-gradient(to bottom, #dda144, #141414)"
                  : "",
            }}
            onClick={() => handleEventTypeClick("Open Ground")}
          >
            <div className="option">
              <div>
                <img />
              </div>
              <p>Open Ground</p>
            </div>
          </div>
          <div
            className="option_border"
            style={{
              background:
                selectedEventType === "Auditorium"
                  ? "linear-gradient(to bottom, #dda144, #141414)"
                  : "",
            }}
            onClick={() => handleEventTypeClick("Auditorium")}
          >
            <div className="option">
              <div>
                <img />
              </div>
              <p>Auditorium</p>
            </div>
          </div>
        </div>
        <div className="arrow_btn" onClick={() => handleArrowClick()}>
          <img src={arrow} />
        </div>
      </>
    );
  };

  const BudgetAndTime = () => {
    const [selectedBudget, setSelectedBudget] = useState("");
    const [selectedDate, setSelectedDate] = useState("");
    const [selectedTime, setSelectedTime] = useState("");
    const handleBudgetClick = (crowd) => {
      if (selectedBudget === crowd) {
        setSelectedBudget("");
      } else {
        setSelectedBudget(crowd);
      }
    };
    const handleDateClick = (e) => {
      setSelectedDate(e.target.value);
    };
    const handleTimeClick = (e) => {
      setSelectedTime(e.target.value);
    };
    const handleArrowClick = () => {
      // if(selectedBudget !== ""){

      //   setCurrentSlide(currentSlide+1)
      // }
      if (selectedTime !== "" && selectedDate !== "" && selectedBudget !== "") {
        setData({
          ...data,
          date: selectedDate.$d,
          time: selectedTime.$d,
          budget: selectedBudget,
        });
        console.log(data);
        setCurrentSlide(currentSlide + 1);
      }
    };
    return (
      <>
        <p className="step__count step__count2">
          <span style={{ color: "#ddab6c", whiteSpace: "nowrap" }}>STEP 5</span>
          -{slide?.length - 1}
        </p>
        <div className="dashed_line">
          <p
            style={{
              whiteSpace: "nowrap",
              textAlign: "center",
              border: "1.56872px dashed #DDAB6C",
              width: "30%",
            }}
          ></p>
        </div>
        <h3 className="step_question">
          How many people{" "}
          <span style={{ color: "#ffffff" }}>are expected?</span>
        </h3>
        <div className="options_container" style={{ margin: "1rem, 0" }}>
          <div
            className="option_border"
            style={{
              background:
                selectedBudget === "Corporate"
                  ? "linear-gradient(to bottom, #dda144, #141414)"
                  : "",
            }}
            onClick={() => handleBudgetClick("Corporate")}
          >
            <div className="option">
              <div>
                <img />
              </div>
              <p>25,000 INR</p>
            </div>
          </div>
          <div
            className="option_border"
            style={{
              background:
                selectedBudget === "50,000 INR"
                  ? "linear-gradient(to bottom, #dda144, #141414)"
                  : "",
            }}
            onClick={() => handleBudgetClick("50,000 INR")}
          >
            <div className="option">
              <div>
                <img />
              </div>
              <p>50,000 INR</p>
            </div>
          </div>
          <div
            className="option_border"
            style={{
              background:
                selectedBudget === "75,000 INR"
                  ? "linear-gradient(to bottom, #dda144, #141414)"
                  : "",
            }}
            onClick={() => handleBudgetClick("75,000 INR")}
          >
            <div className="option">
              <div>
                <img />
              </div>
              <p>75,000 INR</p>
            </div>
          </div>
          <div
            className="option_border"
            style={{
              background:
                selectedBudget === "1,00,000 INR"
                  ? "linear-gradient(to bottom, #dda144, #141414)"
                  : "",
            }}
            onClick={() => handleBudgetClick("1,00,000 INR")}
          >
            <div className="option">
              <div>
                <img />
              </div>
              <p>1,00,000 INR</p>
            </div>
          </div>
          <div
            className="option_border"
            style={{
              background:
                selectedBudget === "1,50,000 INR"
                  ? "linear-gradient(to bottom, #dda144, #141414)"
                  : "",
            }}
            onClick={() => handleBudgetClick("1,50,000 INR")}
          >
            <div className="option">
              <div>
                <img />
              </div>
              <p>1,50,000 INR</p>
            </div>
          </div>
          <div
            className="option_border"
            style={{
              background:
                selectedBudget === "Above 1.5L"
                  ? "linear-gradient(to bottom, #dda144, #141414)"
                  : "",
            }}
            onClick={() => handleBudgetClick("Above 1.5L")}
          >
            <div className="option">
              <div>
                <img />
              </div>
              <p>Above 1.5L</p>
            </div>
          </div>
        </div>
        <h3 className="step_question">
          What’s the date & time{" "}
          <span style={{ color: "#ffffff" }}>of your event?</span>
        </h3>
        <div className="options_container" style={{ margin: "2rem, 0" }}>
          <div className="option_border3">
            <div className="option3">
              <div>
                <img />
              </div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Event Date"
                  style={{ color: "white" }}
                  onChange={(newvalue) => setSelectedDate(newvalue)}
                />
              </LocalizationProvider>

              {/* <input type='date' name='eventDate' className='eventDateSelect' value={selectedDate} onChange={handleDateClick}/> */}
            </div>
          </div>
          <div className="option_border3">
            <div className="option3">
              <div>
                <img />
              </div>
              {/* <input type='time' name='eventTime' className='eventTimeSelect' value={selectedDate} onChange={handleTimeClick} /> */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="Event Time"
                  style={{ color: "white" }}
                  onChange={(newvalue) => setSelectedTime(newvalue)}
                />
              </LocalizationProvider>{" "}
            </div>
          </div>
        </div>
        <div className="arrow_btn" onClick={() => handleArrowClick()}>
          <img src={arrow} />
        </div>
      </>
    );
  };

  const Location = () => {
    const [selectedLocation, setSelectedLocation] = useState("");
    const handleLocationClick = (program) => {
      if (selectedLocation === program) {
        setSelectedLocation("");
      } else {
        setSelectedLocation(program);
      }
    };
    const handleArrowClick = () => {
      if (selectedLocation !== "") {
        setData({
          ...data,
          location: selectedLocation,
        });
        console.log(data);
        if (localStorage.getItem("userToken")) {
          createBookingRequest();
        } else {
          setCurrentSlide(currentSlide + 1);
        }
      }
    };
    return (
      <>
        <p className="step__count step__count2">
          <span style={{ color: "#ddab6c", whiteSpace: "nowrap" }}>STEP 6</span>
          -{slide?.length - 1}
        </p>
        <div className="dashed_line">
          <p
            style={{
              whiteSpace: "nowrap",
              textAlign: "center",
              border: "1.56872px dashed #DDAB6C",
              width: "30%",
            }}
          ></p>
        </div>
        <h3 className="step_question">
          What’s the location{" "}
          <span style={{ color: "#ffffff" }}>you desire?</span>
        </h3>
        <div className="options_container">
          <div
            className="option_border"
            style={{
              background:
                selectedLocation === "Delhi NCR"
                  ? "linear-gradient(to bottom, #dda144, #141414)"
                  : "",
            }}
            onClick={() => handleLocationClick("Delhi NCR")}
          >
            <div className="option">
              <div>
                <img />
              </div>
              <p>Delhi NCR</p>
            </div>
          </div>
          <div
            className="option_border"
            style={{
              background:
                selectedLocation === "Gurugram"
                  ? "linear-gradient(to bottom, #dda144, #141414)"
                  : "",
            }}
            onClick={() => handleLocationClick("Gurugram")}
          >
            <div className="option">
              <div>
                <img />
              </div>
              <p>Gurugram</p>
            </div>
          </div>
          <div
            className="option_border"
            style={{
              background:
                selectedLocation === "Cultural"
                  ? "linear-gradient(to bottom, #dda144, #141414)"
                  : "",
            }}
            onClick={() => handleLocationClick("Faridabad")}
          >
            <div className="option">
              <div>
                <img />
              </div>
              <p>Faridabad</p>
            </div>
          </div>
          <div
            className="option_border"
            style={{
              background:
                selectedLocation === "Family-and-Friends"
                  ? "linear-gradient(to bottom, #dda144, #141414)"
                  : "",
            }}
            onClick={() => handleLocationClick("Bangalore")}
          >
            <div className="option">
              <div>
                <img />
              </div>
              <p>Bangalore</p>
            </div>
          </div>
          <div
            className="option_border"
            style={{
              background:
                selectedLocation === "Jaipur"
                  ? "linear-gradient(to bottom, #dda144, #141414)"
                  : "",
            }}
            onClick={() => handleLocationClick("Jaipur")}
          >
            <div className="option">
              <div>
                <img />
              </div>
              <p>Jaipur</p>
            </div>
          </div>
          <div
            className="option_border"
            style={{
              background:
                selectedLocation === "Pune"
                  ? "linear-gradient(to bottom, #dda144, #141414)"
                  : "",
            }}
            onClick={() => handleLocationClick("Pune")}
          >
            <div className="option">
              <div>
                <img />
              </div>
              <p>Pune</p>
            </div>
          </div>
          <div
            className="option_border"
            style={{
              background:
                selectedLocation === "Amritsar"
                  ? "linear-gradient(to bottom, #dda144, #141414)"
                  : "",
            }}
            onClick={() => handleLocationClick("Amritsar")}
          >
            <div className="option">
              <div>
                <img />
              </div>
              <p>Amritsar</p>
            </div>
          </div>
          <div
            className="option_border"
            style={{
              background:
                selectedLocation === "Varanasi"
                  ? "linear-gradient(to bottom, #dda144, #141414)"
                  : "",
            }}
            onClick={() => handleLocationClick("Varanasi")}
          >
            <div className="option">
              <div>
                <img />
              </div>
              <p>Varanasi</p>
            </div>
          </div>
          <div
            className="option_border"
            style={{
              background:
                selectedLocation === "Ahemdabad"
                  ? "linear-gradient(to bottom, #dda144, #141414)"
                  : "",
            }}
            onClick={() => handleLocationClick("Ahemdabad")}
          >
            <div className="option">
              <div>
                <img />
              </div>
              <p>Ahemdabad</p>
            </div>
          </div>
          <div
            className="option_border"
            style={{
              background:
                selectedLocation === "Chennai"
                  ? "linear-gradient(to bottom, #dda144, #141414)"
                  : "",
            }}
            onClick={() => handleLocationClick("Chennai")}
          >
            <div className="option">
              <div>
                <img />
              </div>
              <p>Chennai</p>
            </div>
          </div>
          <div
            className="option_border"
            style={{
              background:
                selectedLocation === "Others"
                  ? "linear-gradient(to bottom, #dda144, #141414)"
                  : "",
            }}
            onClick={() => handleLocationClick("Others")}
          >
            <div className="option">
              <div>
                <img />
              </div>
              <p>Others</p>
            </div>
          </div>
        </div>
        <div className="arrow_btn" onClick={() => handleArrowClick()}>
          <img src={arrow} />
        </div>
      </>
    );
  };

  const Verification = () => {
    const [otp, setOtp] = useState("");
    const handleSubmit = (event) => {
      event.preventDefault();
      Data.otp = Number(otp);
      VerifyOtp();
    };

    return (
      <div className="app__form">
        <p className="step__count step__count2">
          <span style={{ color: "#ddab6c", whiteSpace: "nowrap" }}>STEP 8</span>
          -{slide?.length - 1}
        </p>
        <div className="dashed_line">
          <p
            style={{
              whiteSpace: "nowrap",
              textAlign: "center",
              border: "1.56872px dashed #DDAB6C",
              width: "30%",
            }}
          ></p>
        </div>

        <div className="form__header">
          <p className="form__name">OTP Sent</p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form__item">
            <p>Enter OTP Sent on +{Data.phone}</p>
            <input
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
          </div>

          <div className="form__submit">
            <input type="submit" value="Verify" />
          </div>
        </form>
      </div>
    );
  };
  const BasicDetails = () => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const handleClick = () => {
      setIsChecked(!isChecked);
      //   console.log(isChecked)
    };

    const handleSubmit = (event) => {
      event.preventDefault();

      if (firstName === "") {
        toast.error("Enter first Name");
      } else if (lastName === "") {
        toast.error("Enter Last Name");
      } else if (phone > 1000000000000 || phone < 99999999999) {
        //   console.log(phone)
        toast.error("Enter Valid Mobile no");
      } else if (email === "") {
        toast.error("Enter email");
      } else if (!isChecked) {
        toast.error("Accept Tearms and Conditions");
      } else {
        Data.firstName = firstName;
        Data.lastName = lastName;
        Data.email = email;
        Data.phone = Number(phone);

        CreateUser();
      }
    };
    useEffect(() => {
      //   console.log(phone)
    }, [phone]);

    return (
      <div className="app__form">
        <p className="step__count step__count2">
          <span style={{ color: "#ddab6c", whiteSpace: "nowrap" }}>STEP 7</span>
          -{slide?.length - 1}
        </p>
        <div className="dashed_line">
          <p
            style={{
              whiteSpace: "nowrap",
              textAlign: "center",
              border: "1.56872px dashed #DDAB6C",
              width: "30%",
            }}
          ></p>
        </div>

        <div className="form__header">
          <p
            className="form__name"
            style={{ whiteSpace: "nowrap", width: "100%", marginLeft: "0" }}
          >
            Create Account{" "}
          </p>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="form__item">
            <p>First Name</p>
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className="form__item">
            <p>Last Name</p>
            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <div className="form__item">
            <p>Email</p>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form__item">
            <p>Phone</p>
            {/* <input type="tel" value={phone} onChange={(e) => setPhone(e.target.value)}/> */}
            <PhoneInput country={"in"} value={phone} onChange={setPhone} />
          </div>

          <div
            style={{
              display: "flex",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "5%",
            }}
          >
            <input
              type="checkbox"
              style={{ marginTop: "1%" }}
              onChange={handleClick}
              checked={isChecked}
            />
            <span style={{ marginLeft: "1rem" }}>
              I accept all the{" "}
              <a
                href="/terms&conditions"
                style={{ color: "white", textDecoration: "underline" }}
              >
                Terms and Conditions
              </a>
            </span>
          </div>

          <div className="form__submit">
            <input type="submit" value="Create Account" />
          </div>
        </form>
      </div>
    );
  };

  const EventCreated = () => {
    const [createdEventId, setCreatedEventId] = useState();

    console.log("Eventcrtatedddsd");
    console.log(currentSlide);
    return (
      <div className="slide__eventCreated">
        <img
          src={illustration_created}
          style={{ height: "16rem", marginBottom: "3rem" }}
        />
        <h2>Event Created</h2>
        <p className="event_created_msg">
          Our Event Manager will reach out to you in next 15 minutes. Kindly,
          save the Booking Id for reference.
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div className="option_border2">
            <div className="option">
              <p
                style={{
                  color: "#DDABC",
                  fontSize: "2rem",
                  whiteSpace: "nowrap",
                }}
              >
                {creatdEventId}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  useEffect(() => {}, []);

  console.log(data);
  const slide = [
    <Welcome />,
    <SelectCategory />,
    <SelectSubCategory />,
    <ProgramList />,
    <CrowdAndEventType />,
    <BudgetAndTime />,
    <Location />,
  ];

  {
    !localStorage.getItem("userToken") && slide.splice(7, 0, <BasicDetails />);
  }
  {
    !localStorage.getItem("userToken") && slide.splice(8, 0, <Verification />);
  }
  console.log(slide);

  console.log(currentSlide, slide.length);

  return (
    <div className="app__bookingTool">
      <div className="app_background">
        <img src={sprial} />
      </div>
      <div className="app_background2">
        <img src={circles} />
      </div>
      <div className="app__options">
        {creatdEventId ? (
          <>
            <EventCreated />
          </>
        ) : (
          <>{slide[currentSlide]}</>
        )}
      </div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          success: {
            iconTheme: {
              primary: "#141414",
              secondary: "white",
            },
          },
        }}
      />
    </div>
  );
};

export default BookingTool;
