import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../navbar";
import axios from "axios";
import useDocumentTitle from "../useDocumentTitle.js";
import { APIURL } from '../../constants';

import "./corporate.css";

function ServicePage() {
  const navigate = useNavigate();
  const { serviceId,index } = useParams();
  const Index = parseInt(index);
  console.log("service id",serviceId);
  
  const [category, setCategory] = useState(null);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [allcat, setAllcat] = useState([]);
  const [nextCategory, setNextCategory] = useState([]);
  const [goNext, setGoNext] = useState(true);
  var obj = {};

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const response = await axios.get(`${APIURL}/category/getAll`);
        const cat = response.data.data;
        if (Index < cat.length - 1) {
          setNextCategory(cat[`${Index + 1}`]);
          console.log("working");
        } else {
          setNextCategory(cat[0]);
          setGoNext(false);
          
          console.log(goNext)

          console.log("nxt" + nextCategory);
        }

        console.log("cat", cat[`${Index + 1}`]);
        const foundCategory = cat.find(
          (category) => category.identifier === serviceId
        );
        setCategory(foundCategory);
        
        setSubCategoryList(foundCategory.subCategories);
        setAllcat(cat);
      } catch (error) {
        console.error("Failed to fetch category:", error);
      }
    };

    fetchCategory();
  }, [serviceId,Index]);

  useDocumentTitle(category?.name)

  if (!category) {
    return <div className="loading">Loading...</div>;
  }

  obj = category;
  var i = 0;
  var half1 = "";
  var half2 = "";
  var size = obj.name.length;
  half1 = obj.name.substring(0, size / 2);
  half2 = obj.name.substring(size / 2);

  const gotosubservice = (subserviceId) => {
    navigate(`/our-services/${serviceId}/sub-service/${subserviceId}`);
  };

  console.log(category);

  const gotoNext = () => {
    console.log(category);
    const categoryIds = allcat.map((cat) => cat.identifier);
    const currentIndex = categoryIds.indexOf(serviceId);
    const nextIndex = (currentIndex + 1) % categoryIds.length;
    const nextCategoryIdentifier = categoryIds[nextIndex];
    // navigate(`/services/${nextCategoryIdentifier}/${Index +1}`);
    if (nextIndex === 0 && currentIndex === categoryIds.length - 1) {
      // When the nextIndex is 0 and currentIndex is the last index, it means we are wrapping around to the first element
      navigate(`/our-services/${nextCategoryIdentifier}/0`);
    } else {
      navigate(`/our-services/${nextCategoryIdentifier}/${nextIndex}`);
    }
  };

  if (!category) {
    return <div>Loading...</div>;
  }

  // const next = `https://pratichakra-resources.s3.ap-south-1.amazonaws.com/pratichakra-images/images/cultural.svg`;
  // const url = `https://pratichakra-resources.s3.ap-south-1.amazonaws.com/pratichakra-images/images/${obj.carousel_slide}`;
  const dots =
    "https://pratichakra-resources.s3.ap-south-1.amazonaws.com/pratichakra-images/images/Frame (1).png";
  const backgroundImage = category.bannerImage.imageUrl;
  const nextBackground = nextCategory.bannerImage.imageUrl;

  console.log(nextBackground);

  return (
    <section className="frameimages app__corporate" >
      <div className="row">
        <div
          className="col-lg-6 col-12 width43 app__cor_car1"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
          }}
        >
          <div>
            <div
              id="carouselExampleCaptions"
              className="carousel slide carousel-fade"
              data-bs-ride="carousel"
            >
              <div class="carousel-indicators carousel-indicators2">
                <button
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  data-bs-slide-to="0"
                  className="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  data-bs-slide-to="2"
                  aria-label="Slide 3"
                ></button>
              </div>
              {/* <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={url} className="d-block w-100 " alt="..." />
                  <div className="carousel-caption d-md-block">
                    <p className="caption">{obj.desc}</p>
                  </div>
                </div>
                <div className="carousel-item">
                  <img src={url} className="d-block w-100 " alt="..." />
                  <div className="carousel-caption d-md-block">
                    <p className="caption">{obj.desc}</p>
                  </div>
                </div>
                <div className="carousel-item">
                  <img src={url} className="d-block w-100 " alt="..." />
                  <div className="carousel-caption d-md-block">
                    <p className="caption">{obj.desc}</p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-12 width57 app__cor_car2">
          <div onClick={gotoNext} className="app__next">
            <img src={dots} className="dots9" />
            <img className="next" src={nextBackground} />
            <p className="nexttext">NEXT&gt;</p>
          </div>
          <div className="row">
            <div className="col-lg-12 col-12">
              <p className="servicename">
                <span className="corp">{half1}</span>
                {half2}
              </p>
            </div>
            <div className="col-lg-6 col-12">
              <img align="right" src={dots} className="dots6" />
            </div>
          </div>
          <div
            className="subcatbox"
            style={{
              maxHeight: "400px",
              overflowY: "auto",
              paddingRight: "5px",
            }}
          >
            {subCategoryList.map((item, index) => {
              const i = index % 2 === 0;
              return (
                <div
                  className={i ? "col-lg-6 subcatdesc" : "col-lg-6 subcatdesc2"}
                  key={item._id}
                >
                  <p
                    className="serviceswe"
                    onClick={() => {
                      gotosubservice(item.identifier);
                    }}
                  >
                    {item.name}
                    <br />
                    <br />
                    <span className={i ? "view" : "know"}>View now ⟶</span>
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

export default ServicePage;
