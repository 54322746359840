import React, {useState,  useEffect} from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import {BsPlayCircle} from 'react-icons/bs'
import {BiLockAlt} from 'react-icons/bi'
import {BsBookmark} from 'react-icons/bs'
import {BsFillShareFill} from 'react-icons/bs'
import {BsBookmarkFill} from 'react-icons/bs'
import './CourseDetails.css'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {Toaster, Toast, toast} from 'react-hot-toast'
import { APIURL, WEBURL } from '../../../constants';
import useDocumentTitle from '../../useDocumentTitle.js';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const Data = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  otp: '',
}

const CourseDetails = () => {

  const [activeTab, setActiveTab] = useState("about");
  const [activeContentTab, setActiveContentTab] = useState(0)
  const [activeSettingsTab, setActiveSettingsTab] = useState("profile")
  const [courseDetails, setCourseDetails] = useState();
  const [isCourseSaved, setIsCourseSaved] = useState(false)
  const [show, setShow] = useState(false);
  const [formsIndex, setFormsIndex] = useState(0)
  const [modalShow, setModalShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleOnTabClick = (tab) => {
    setActiveTab(tab)
  }
  const handleOnContentsClick = (tab) => {
    setActiveContentTab(tab)
  }
  const {courseId, courseIdentifier} = useParams();

  console.log(courseId,courseIdentifier)

  const getCourseDetail = () => {
    axios.get(`${APIURL}/course/${courseId}/getOne`, {
      headers: {
      authtoken: localStorage.getItem('userToken')
      }
  })
  . then(function (response) {

      console.log(response.data.data)
      setCourseDetails(response.data.data);
  })
  .catch(function (error) {
      
  });  
  }

  const CreateUser = ()=>{

    axios.patch(`${APIURL}/user/otp/saveUserAndSendOtpToMobileNumber`, {
        firstName: Data.firstName,
        lastName: Data.lastName,
        email: Data.email,
        mobileNumber: Data.phone,
        userType: "user",
        status: "active",
      },{
        headers: {
            authtoken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzYzkyMzgzMzc2YWQzOWFjZjFlOTcwOCIsImlhdCI6MTY3NDEzMzk1NH0.ZUR5fwVkuuV4hw9It08j_8dviYX4UMQ92K_klqe9938'
        }
      })
      .then(function (response) {
     
        localStorage.setItem('user_id', response.data.data.userId);
        setFormsIndex(1);
      })
      .catch(function (error) {

        toast.error("Something went Wrong")
      });




}
  const VerifyOtp =() => {
      
      axios.patch(`${APIURL}/user/otp/verifyOtpToMobileNumber`, {
          mobileNumber:Data.phone,
          otp:Data.otp
      },{
          headers: {
              authtoken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzYzkyMzgzMzc2YWQzOWFjZjFlOTcwOCIsImlhdCI6MTY3NDEzMzk1NH0.ZUR5fwVkuuV4hw9It08j_8dviYX4UMQ92K_klqe9938'
          }
        })
        .then(function (response) {
    
          localStorage.setItem('userToken', response.data.data)
          localStorage.setItem(`is_enrolled_for_${courseDetails?.identifier}`, true)
          setFormsIndex(formsIndex+1)
        })
        .catch(function (error) {
      
          toast.error("Wrong OTP")
        });

  }

  const saveCourse = () =>{
    if(localStorage.getItem(`is_${courseDetails?.identifier}_saved`)) {
      localStorage.removeItem(`is_${courseDetails?.identifier}_saved`)
      setIsCourseSaved(false)
      toast.success("Course Unsaved !")
    } else {
      localStorage.setItem(`is_${courseDetails?.identifier}_saved`, true)
      setIsCourseSaved(true)
      toast.success("Course Saved")

    }
  }
  // const handleMakePayment = async() => {
  //   await axios.get(`${APIURL}/booking/${localStorage.getItem(`UserBookingId__id${eventId}`)}/getOne`, {
  //             headers: {
  //                 authtoken: localStorage.getItem('userToken')
  //             }
  //         })
  //         .then(response => {
  //                 setTransactionId(`${response.data?.data?.paymentTransaction?._id}`)
  //                 trasaction__Id = response.data?.data?.paymentTransaction?._id;
  //                 order__Id = response.data?.data?.paymentTransaction?.orderId;
                  
  //                 setOrderId(response.data?.data?.paymentTransaction?.orderId)
               

  //                 handlePayment();
  //         })
  //         .catch(function (error) {
  //                 toast.error("Some Error Occured");
  //         });

    
  // }
  // useEffect(() => {
      
  // }, [handleMakePayment]) 

  // const handlePayment = async (params) => {
  //     // const order = await createOrder(params); //  Create order on your backend
    
  //     const options = {
  //       key: "rzp_live_CuArE7lBVknjjR", // Enter the Key ID generated from the Dashboard
  //       amount: `${EventData.ticketPrice }`, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
  //       currency: "INR",
  //       name: "PratiChakra - Think and Do It.",
  //       description: `Booking for ${EventData?.title}`,
  //       image: {logo},
  //       order_id: order__Id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
  //       handler: function (response) {
  //         // alert(response.razorpay_payment_id);
  //         // alert(response.razorpay_order_id);
  //         // alert(response.razorpay_signature);
  //         setPaymentStatus(true);
  //         localStorage.setItem(`paymentStatus__${localStorage.getItem(`UserBookingId_${eventId}`)}`, true)
  //         setTransationStatus("completed")
  //         setIsFree(true)
  //       },
  //       prefill: {
  //         name: `${Data?.firstName}`,
  //         email: `${Data?.email}`,
  //         contact: `${Data?.phone}`,
  //       },
  //       notes: {
  //         address: "Sudarshan Chakra Pratispardha (OPC) Private Limited",
  //       },
  //       theme: {
  //         color: "#141414",
  //       },
  //     };
    
  //     const rzp1 = new Razorpay(options);
    
  //     rzp1.on("payment.failed", function (response) {
         
  //       alert(response.error.code);
  //       alert(response.error.description);
  //       alert(response.error.source);
  //       alert(response.error.step);
  //       alert(response.error.reason);
  //       alert(response.error.metadata.order_id);
  //       alert(response.error.metadata.payment_id);
  //       localStorage.setItem(`paymentStatus__${localStorage.getItem(`UserBookingId_${eventId}`)}`, false)
  //       setTransationStatus("failure")
  //     });
    
  //     rzp1.open();
  //   };

  useEffect(() => {
    getCourseDetail()
    if(localStorage.getItem(`is_${courseDetails?.identifier}_saved`)) {
      setIsCourseSaved(true)
    } else {
      setIsCourseSaved(false)
    }
  }, [])


  const Form1 = (props) => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const handleClick = () => {
        setIsChecked(!isChecked)
     //   console.log(isChecked)
    }

    const handleSubmit = (event) => {
    event.preventDefault();

    if (firstName === "") {
        toast.error("Enter first Name");
    } else if (lastName === "") {
        toast.error("Enter Last Name")
    } else if(phone > 1000000000000 || phone < 99999999999) {
     //   console.log(phone)
        toast.error("Enter Valid Mobile no")
    }else if(email === ""){
        toast.error("Enter email")
    } else if(!isChecked) {
         toast.error("Accept Tearms and Conditions")
    }else{
        Data.firstName = firstName;
        Data.lastName = lastName;
        Data.email = email;
        Data.phone = Number(phone) ;
    
        CreateUser()
    }
    
    }
    useEffect(() => {
   //   console.log(phone)
    }, [phone])
    
    return(<>
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  Create Account
                </Modal.Title>
              </Modal.Header>
                  <Modal.Body>
                  <form onSubmit={handleSubmit}>
                      <div className='form__item'>            
                          <p>First Name</p>
                          <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} required/>
                      </div>
                      <div className='form__item'>            
                          <p>Last Name</p>
                          <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} required/>
                      </div>
                      <div className='form__item'>            
                          <p>Email</p>
                          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required/>
                      </div>
                      <div className='form__item'>            
                          <p>Phone</p>
                          {/* <input type="tel" value={phone} onChange={(e) => setPhone(e.target.value)}/> */}
                          <PhoneInput
                              country={'in'}
                              value={phone}
                              onChange={setPhone}
                              required
                          />
                      </div>
                      <div style={{display:'flex',textAlign: 'center', alignItems: 'center', justifyContent: 'center', marginTop: '5%'}}>
                              <input type="checkbox" style={{marginTop: '1%'}} onChange={handleClick} checked={isChecked}/>
                              <span style={{marginLeft: '1rem'}}>I accept all the <a href='/terms&conditions' style={{color: '#141414', textDecoration: 'underline'}}>Terms and Conditions</a></span>
                      </div>
                      <div className='form__submit'>
                          <input type="submit" value='Create Account'/>
                      </div>
              
                  </form>
                  </Modal.Body>
                  {/* <Modal.Footer>
                    <Button>Close</Button>
                  </Modal.Footer> */}

    </>)
  }
  const Form2 = () => {
    const [otp, setOtp] = useState("");
    const handleSubmit = (event) => {
    event.preventDefault();
        Data.otp = Number(otp) ;
        VerifyOtp();
    }
    return(<>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Enter the OTP
        </Modal.Title>
      </Modal.Header>
          <Modal.Body>
              <form onSubmit={handleSubmit}>
                <div className='form__item'>            
                    <p>Enter OTP Sent on  +{Data.phone}</p>
                    <input type="text" value={otp} onChange={(e) => setOtp(e.target.value)}/>
                </div>

                <div className='form__submit'>
                    <input type="submit" value='Verify' />
                </div>
        
              </form>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button>Close</Button>
          </Modal.Footer> */}

</>)
  }

//   const Payment = () => {
        
//     return(
//         <div>
//             {!localStorage.getItem(`paymentStatus__${localStorage.getItem(`UserBookingId_${eventId}`)}`) && !isFree? <>
//                 <div className='form__header'>
//                     <p className='step__count'><span style={{color: '#ddab6c'}}>4</span>/4</p>
//                     <p className='form__name'>Payment Needed</p> 
//                 </div>
//                 <p style={{fontSize: '1.5rem'}}>Please Complete the payment of</p>
//                 <p style={{fontSize: '1.5rem', marginTop: '-5%', color: '#ddab6c'}}>₹{EventData?.ticketPrice}</p>
//                 <div style={{display: 'felx',flexDirection: 'row', justifyContent:'center', alignContent: 'center'}}>
//                     <button className='button__payment' onClick={handleMakePayment}>Make Payment</button>
//                 </div>
//             </>
//             :
//             <>
//                 <div style={{display: 'flex', flexDirection: 'column',alignItems: 'center', fontFamily: 'Poppins-Medium' }}>
//                     <p style={{fontSize: '2rem', color: '#ddab6c'}}>Booking succesfull</p>

//                     <CopyToClipboard text={`${WEBURL}/event-details/${EventData?.eventId}/${EventData?.identifier}?tab=winner&bookingId=${localStorage.getItem(`UserBookingId_${eventId}`)}&type=sharing`}>
//                         <div style={{height: '4rem', width: '12rem', backgroundColor: '#141414', textAlign:'center', borderRadius: '25px', cursor: 'pointer'}} onClick={() => {toast.success("Booking Id Copied to ClipBoard")}}>
//                             <p style={{alignItems: 'center', marginTop: '0.7rem', fontSize: '1.7rem'}}>{localStorage.getItem(`UserBookingId_${eventId}`)}  <RiFileCopyFill style={{marginLeft: '5px', color: '#ddab6c'}}/></p>
//                         </div>
//                     </CopyToClipboard>

//                     <p style={{marginTop:'1rem'}}>Copy Your Booking Id</p>
//                     <p>Share it With Friends to vote for Contestent</p>
//                     <div>
//                         <Link to={{pathname: '/event-details/' + EventData?.eventId +  '/' + EventData?.identifier + '?tab=winner' +"&" + `bookingId=${localStorage.getItem(`UserBookingId_${eventId}`)}`+ "&" +`type=sharing`}} state={EventData}><button style={{height :'3rem', width: '12rem', borderRadius: '25px', marginTop: '1rem'}}>EventDetails</button></Link>
//                     </div>
//                 </div>
//             </>}

//         </div>
//     )
// }
  const Form3 = () => {

    return(<>
          <Modal.Header closeButton>
      </Modal.Header>
          <Modal.Body>
              <h2>Congratulations</h2>
              <h3>You have successfully Enrolled for the Course</h3>
          </Modal.Body>
    </>)  
  }
  const forms = [<Form1/>, <Form2/>, <Form3/>]
  function MyVerticallyCenteredModal(props) {
    return (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className='course_model'
        >
          {forms[formsIndex]}
        </Modal>
    );
  }
  


  
  return (
    <div className='app__courseDetails'>
        {/* <div className='detail_block1'>
            <BiHomeAlt className='block1_icon'/>
            <BiMessageDetail className='block1_icon'/>
            <FiSettings className='block1_icon'/>
            <TbBuildingCommunity className='block1_icon'/>
        </div> */}
        <div className='detail_block2'>
            <div className='block2_contains'>
                <div className='course_header'>
                      <div>
                          <h1>{courseDetails?.courseName}</h1>
                          <p>{courseDetails?.certificationProvider}</p>
                      </div>
                      <div>
                            {
                              isCourseSaved ? <BsBookmarkFill className='header_icons' onClick={saveCourse}/> : <BsBookmark className='header_icons' onClick={saveCourse} />
                            }
                            <CopyToClipboard text={window.location.href} onCopy={() => toast.success("successfully Copied to Clipboard")}>
                                <BsFillShareFill className='header_icons'/>
                            </CopyToClipboard>
                      </div>
                </div> 
                <div className='course_contains_container'>
                    <div className='course_contain_block1'>
                        <div className='course_video_section'>
                            <video width="100%" height="100%" controls >
                              <source src="/video1.mp4" type="video/mp4"/>
                            </video>
                        </div>
                        <div className='course_enroll_btn'>
                          <button  onClick={() => setModalShow(true)}>Enroll Now</button>
                        </div>
                        <div className='course_tabs_section'>
                            <div className='tabs_header'>
                                <div>
                                  <p style={{borderBottom: activeTab === "about" ? "2px solid #000000" : ""}} onClick={() => handleOnTabClick("about")} >About</p>
                                  <p  style={{borderBottom: activeTab === "review" ? "2px solid #000000" : ""}} onClick={() => handleOnTabClick("review")} >Review</p>
                                  <p  style={{borderBottom: activeTab === "discussion" ? "2px solid #000000" : ""}} onClick={() => handleOnTabClick("discussion")} >Discussion</p>
                                  <p  style={{borderBottom: activeTab === "resources" ? "2px solid #000000" : ""}} onClick={() => handleOnTabClick("resources")} >Resources</p>
                                </div>
                                  <hr/>
                            </div>
                        </div>  
                        <div>
                            {
                              activeTab === "about"  && <>
                                {
                                  courseDetails?.about?.map((item, key) => {
                                    return(<p>{item}</p>)
                                  })
                                }
                              </>
                            }
                        </div>            
                    </div>
                    <div className='course_contain_block2'>
                          <h1>What you'll learn</h1>
                          <p>By the end of this course, you will be armed with everything you need to dominate business development.</p>

                          <hr/>

                          <h3>Contents</h3>

                          <div className='contents_container'>
                              {
                                courseDetails?.Contents?.map((item, index) => {
                                  return(<>

                                      {
                                        item.contentType === "free" ? <>
                                          <div className='active_content_card' onClick={() => handleOnContentsClick(index)} style={{color: activeContentTab === index ? "#fff" : "" , backgroundColor: activeContentTab === index ? "#141414" : ""}}>
                                                <BsPlayCircle className='content_icon'/>
                                                <h3 style={{color: activeContentTab === index ? "#fff" : "" }}>{item?.contentTitle}</h3>
                                                <p>{item?.contentDuration}</p>
                                            </div> 
                                        </> : <div className='content_card'>
                                                <BiLockAlt className='content_icon'/>
                                                <h3>{item?.contentTitle}</h3>
                                                <p>{item?.contentDuration}</p>
                                        </div> 
                                      }

                                  </>)
                                })
                              }
                          </div>
                    </div>

                </div>

            </div>
        </div>
        <div className='detail_block3'>
                <div className='course_tabs_section'>
                      <div className='tabs_header'>
                          <div>
                            <p style={{borderBottom: activeSettingsTab === "profile" ? "2px solid #000000" : ""}} onClick={() => setActiveSettingsTab("profile")} >Profile</p>
                            <p  style={{borderBottom: activeSettingsTab === "account" ? "2px solid #000000" : ""}} onClick={() => setActiveSettingsTab("account")} >Account</p>
                            <p  style={{borderBottom: activeSettingsTab === "payment" ? "2px solid #000000" : ""}} onClick={() => setActiveSettingsTab("payment")} >Payment</p>
                            <p  style={{borderBottom: activeSettingsTab === "settings" ? "2px solid #000000" : ""}} onClick={() => setActiveSettingsTab("settings")} >Settings</p>
                          </div>
                            <hr/>
                      </div>
                </div> 
        </div>

        {/* <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Enroll For the Course</Modal.Title>
          </Modal.Header>
          <Modal.Body>


            <Form>
                {forms[formsIndex]}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
                  Next
            </Button>
          </Modal.Footer>
        </Modal> */}

        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
        <Toaster       
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          success: {
            iconTheme: {
              primary: '#141414',
              secondary: 'white',
            },
          },
        }}
        />
    </div>
  )
}

export default CourseDetails