import React from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useDocumentTitle from "../useDocumentTitle";
import { APIURL } from '../../constants';

import "./corporate.css";

function Seminar_And_Conferences() {
  const { serviceId, subServiceId } = useParams();
  console.log("sub", subServiceId);
  
  console.log("ser", serviceId);
  const [category, setCategory] = useState(null);
  const [serviceList, setServiceList] = useState([]);
  const [eventservice, setEventservice] = useState(null);
  const [subCategory, setSubCategory] = useState([]);
  const[Foundcat,setFoundcat]=useState([])

  // const handleConnect = () => {
  //   navigate('/booking/tool');
  // };

 
  const handleConnect = () => {
    navigate('/booking/tool', {
      state: {
        Category: Foundcat._id,
        SubCategory: subCategory._id,
        programType: "",
      }
    });
  };


  console.log(Foundcat._id)
  console.log(subCategory._id)


  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const response = await axios.get(`${APIURL}/category/getAll`);
        const cat = response.data.data;
        const foundCategory = cat.find(
          (category) => category.identifier === serviceId
        );
        const foundSubCat = foundCategory.subCategories.find(
          (subcategory) => subcategory.identifier === subServiceId
        );
        setFoundcat(foundCategory)

        console.log(foundSubCat);
  
        setSubCategory(foundSubCat);
        setCategory(foundCategory);
        setServiceList(foundCategory.eventServices.serviceList);
        setEventservice(foundCategory.eventServices);
        console.log("found", foundCategory);
        console.log("sub Cat", foundSubCat);
      } catch (error) {
        console.error("Failed to fetch category:", error);
      }
    };

    fetchCategory();
  }, [serviceId]);

  
  useDocumentTitle(subCategory.name)

  var half1 = "";
  var half2 = "";
  const arr = subServiceId.split("-");
  const halfportion = Math.floor(arr.length / 2);
  //   console.log(halfportion)
  for (var i = 0; i < halfportion; i++) {
    half1 += capitalizeFirstLetter(arr[i]) + " ";
  }

  for (var j = halfportion; j < arr.length; j++) {
    half2 += capitalizeFirstLetter(arr[j]) + " ";
  }

  function capitalizeFirstLetter(word) {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }
  const navigate = useNavigate();
  const gotoService = () => {
    navigate("/our-services");
  };

  console.log("event", eventservice);


  
  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll:2,
  };

  const dots =
    "https://pratichakra-resources.s3.ap-south-1.amazonaws.com/pratichakra-images/images/Frame (1).png";

  return (
    <section>
      <div className="row seminarrow">
        <div className="col-lg-2 col-12 bgcurves">
          <img align="left" className="dots4" src={dots}></img>
          <div
            className="discoverservices"
            onClick={() => {
              gotoService();
            }}
          >
            <p>
              DISCOVER <span className="bolding">SERVICES</span>
              <br />
              <span className="downarrow">˅</span>
            </p>
          </div>
        </div>
        <div className="col-lg-5 col-12 app__seminar">
          <p className="seminars">
            <span className="bolding">
              {half1}
              <br />
            </span>
            {half2}
          </p>
          <p className="desc7">{eventservice && eventservice.description}</p>
          <button className="btn2" onClick={handleConnect}>
            Book Now
          </button>
        </div>

        <div className="col-lg-5 col-12 halfportion">
          <div className="col-lg-12 col-12 weprovide">
            <div className="row">
              <p>
                We <span className="bolding">Provide</span>
              </p>
            </div>
            <p></p>
            <div className="row">
              <div className="col-lg-1 col-12">
                <p className="line3"></p>
              </div>
              <div className="col-lg-5 col-12">
                <ul
                  className="venueconsult"
                  style={{ height: "200px", overflowY: "scroll" }}
                >
                  {serviceList.map((item, index) => {
                    const i = index % 2 === 0;
                    return <li key={item._id}>{item.serviceName}</li>;
                  })}
                </ul>
                <style>{`
          .venueconsult::-webkit-scrollbar {
            width: 0;
            background-color: transparent;
          }
        `}</style>
              </div>
              <div className="col-lg-5 col-12 app__BookNow">
                <button className="btn3" onClick={handleConnect}>
                  Book Now
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-12 app__seminar_img">
            <Slider style={{width:"92%",left:"17px"}} {...carouselSettings}>
              {subCategory.images?.map((item, index) => (
                <div key={index}>
                  <img
                    className="rectangle3538"
                    src={item}
                    alt={`Image ${index}`}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Seminar_And_Conferences;
